import Icon from "components/Icon"
import { Row } from "components/layout"
import { forwardRef } from "react"

import styles from "./MicroTag.module.scss"

export interface MicroTagProps {
  tooltip?: string
  icon: string
  text: string
  style?: any
}

export const MicroTag = forwardRef<HTMLDivElement, MicroTagProps>(
  ({ tooltip, icon, text, style }, ref) => (
    <div className={styles.tag} style={style} ref={ref}>
      <Row distance={2}>
        <Icon name={icon} bold size={10} />
        <div className={styles.text}>{text}</div>
      </Row>
    </div>
  )
)
