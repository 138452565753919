import { FormInput } from "components/Input"
import { FormStack } from "components/layout"
import {
  cancelButtonSpec,
  confirmButtonSpec,
  ModalProps,
  useModal,
  Dialog,
  Element,
  Footer,
  Title
} from "components/Modal"
import { Form, Formik } from "formik"
import toast from "react-hot-toast"
import { useInviteMemberMutation } from "graphql/types"
import { useIDs } from "routes/Editor/Editor/EditorStore"

export interface InviteMemberForm {}

const initialValues = {
  email: ""
}

export const InviteMember = (props: ModalProps) => {
  const modal = useModal()

  const { projectId } = useIDs()
  const [invite] = useInviteMemberMutation()

  return (
    <Dialog {...props}>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          try {
            await invite({
              variables: {
                projectId,
                ...values
              }
            })
          } catch (error) {
            console.error(error)

            const message = error?.[0].message ?? "Oops! Something went wrong!"
            toast(message)
          }

          modal.hide()
        }}
      >
        <Form autoComplete="off">
          <Title message="New team member" />
          <Element>
            <FormStack>
              <FormInput
                type="email"
                label="Email address"
                autoComplete="off"
                placeholder="Email address"
                name="email"
              />
            </FormStack>
          </Element>
          <Footer
            buttons={[
              cancelButtonSpec(() => modal.hide()),
              confirmButtonSpec()
            ]}
          />
        </Form>
      </Formik>
    </Dialog>
  )
}
