import Icon from "components/Icon"
import { Row } from "components/layout"

import styles from "./QuestionTitle.module.scss"

export interface QuestionTitleProps {
  message: string
}

export const QuestionTitle = ({ message }: QuestionTitleProps) => {
  return (
    <div className={styles.component}>
      <Row>
        <div className={styles.title}>{message}</div>
        <div>
          <Icon color="var(--placeholder-color)" name="ri-question-line" />
        </div>
      </Row>
    </div>
  )
}
