import Icon from "components/Icon"
import styles from "./Anchor.module.scss"

export interface AnchorProps {
  url: string
  title: string
  description: string | null
  hostname: string
}

export const Anchor = ({ url, title, description, hostname }: AnchorProps) => (
  <a
    className={styles.anchor}
    target="_blank"
    rel="noreferrer"
    href={url}
    title={title}
  >
    <div className={styles.title}>{title}</div>
    {description && <div className={styles.description}>{description}</div>}
    <div className={styles.host}>
      Open {hostname} <Icon size={10} inline name="ri-external-link-line" />
    </div>
  </a>
)
