export type ButtonSpec = {
  style?: "secondary" | "primary" | "green" | "amber" | "red"
  label: string
  type?: "button" | "submit"
  action?: Function
}

export type ButtonSpecFn = (action?: Function) => ButtonSpec

export interface ConfirmButtonSpec extends ButtonSpec {
  style: "primary"
  label: "Confirm"
  type: "submit"
}

export const confirmButtonSpec = (): ConfirmButtonSpec => {
  return {
    style: "primary",
    label: "Confirm",
    type: "submit"
  }
}

export interface CancelButtonSpec extends ButtonSpec {
  style: "secondary"
  type: "submit"
  label: "Cancel"
  action: Function
}

export const cancelButtonSpec = (action: Function): CancelButtonSpec => {
  return {
    style: "secondary",
    type: "submit",
    label: "Cancel",
    action
  }
}

export const submitButtonSpec = (label: string = "confirm"): ButtonSpec => {
  return {
    style: "primary",
    type: "submit",
    label
  }
}
