import cn from "classnames"
import { ReactNode } from "react"

import { getStringComplexity } from "./utils"
import { HTMLAttributes, useEffect } from "react"

import styles from "./StringComplexity.module.scss"

interface IndicatorProps {
  enabled: boolean
  children: ReactNode
}

const Indicator = ({ enabled, children }: IndicatorProps) => (
  <span className={cn(styles.indicator, enabled && styles.green)}>
    {children}
  </span>
)

export interface StringComplexityProps
  extends Pick<HTMLAttributes<HTMLSpanElement>, "className" | "style"> {
  value: string
  onUpdate: (complex: boolean) => void
}

export const StringComplexity = ({
  style,
  className,
  value,
  onUpdate
}: StringComplexityProps) => {
  const { length, lowercase, uppercase, symbol, number, complex } =
    getStringComplexity(value)

  useEffect(() => {
    onUpdate(complex)
  }, [complex, onUpdate])

  return (
    <span className={cn(styles.component, className)} style={style}>
      <Indicator enabled={length}>At least 8 characters</Indicator>, including{" "}
      <Indicator enabled={uppercase}>upper</Indicator>, and{" "}
      <Indicator enabled={lowercase}>lowercase</Indicator> letters, a{" "}
      <Indicator enabled={number}>number</Indicator>, and a{" "}
      <Indicator enabled={symbol}>symbol</Indicator>
    </span>
  )
}
