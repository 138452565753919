import { useEditor } from "@tiptap/react"
import Document from "@tiptap/extension-document"
import Text from "@tiptap/extension-text"
import Dropcursor from "@tiptap/extension-dropcursor"
import Heading from "@tiptap/extension-heading"
import History from "@tiptap/extension-history"
import Bold from "@tiptap/extension-bold"
import Italic from "@tiptap/extension-italic"
import Underline from "@tiptap/extension-underline"
import Focus from "@tiptap/extension-focus"
import Link from "@tiptap/extension-link"
import TextAlign from "@tiptap/extension-text-align"
import Gapcursor from "@tiptap/extension-gapcursor"
import BulletList from "@tiptap/extension-bullet-list"
import OrderedList from "@tiptap/extension-ordered-list"
import ListItem from "@tiptap/extension-list-item"
import HorizontalRule from "@tiptap/extension-horizontal-rule"
import Paragraph from "@tiptap/extension-paragraph"

import { File } from "./extensions/File"
import { Image } from "./extensions/Image"
import { TrailingNode } from "./extensions/TrailingNode"
import { Question } from "./extensions/Question"
import { Metrics } from "./extensions/Metrics"
import { Indexer } from "./extensions/Indexer"
import { Embed } from "./extensions/Embed"
import { Selected } from "./extensions/Selected"
import { Title } from "./extensions/Title/Title"
import { CommandsMenu } from "./extensions/Suggestions/CommandsMenu"

import "./styles.scss"

const CustomDocument = Document.extend({
  content: "title block+"
})

export const useTextEditor = () => {
  const editor = useEditor({
    extensions: [
      CustomDocument,
      Title,
      Heading.configure({
        levels: [1, 2]
      }),
      Paragraph,
      Text,
      Bold,
      Italic,
      Underline,
      HorizontalRule,
      Link.configure({
        HTMLAttributes: {
          class: "editor-simple-link"
        },
        openOnClick: false
      }),
      TextAlign.configure({
        types: ["paragraph"]
      }),
      Image,
      History,
      Dropcursor,
      Gapcursor,
      Selected,
      Focus.configure({
        className: "has-focus",
        mode: "deepest"
      }),
      File,
      BulletList,
      OrderedList,
      ListItem,
      TrailingNode,
      Question,
      Embed,
      Metrics,
      CommandsMenu,
      Indexer.configure({
        field: "id",
        types: ["paragraph", "file", "image", "question", "metric", "embed"]
      })
    ],

    content: null
  })

  return editor
}
