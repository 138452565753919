import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  Mixed: any;
};

export type Query = {
  __typename?: 'Query';
  assets: Array<Asset>;
  comments: CommentsGroup;
  discussions: Array<Discussion>;
  invites: Array<Maybe<Invite>>;
  measure: Measure;
  membership?: Maybe<Member>;
  metric: Metric;
  metrics: Array<Metric>;
  milestone: Milestone;
  milestones: Array<Milestone>;
  peopleWhoSelectedOption: Array<Maybe<Scalars['String']>>;
  post: Post;
  project: Project;
  projects: Array<Project>;
  question: Question;
  reactionGroup: ReactionGroup;
  reactionPeople: ReactionPeople;
  reactions: Array<Maybe<Reaction>>;
  resources: Array<Resource>;
  role: Role;
  self?: Maybe<User>;
  teammate: Teammate;
  teammates: Array<Teammate>;
  views: ViewsGroup;
};


export type QueryAssetsArgs = {
  projectId: Scalars['ID'];
};


export type QueryCommentsArgs = {
  postId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type QueryDiscussionsArgs = {
  postId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type QueryInvitesArgs = {
  project: Scalars['ID'];
};


export type QueryMeasureArgs = {
  id: Scalars['ID'];
  metricId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type QueryMembershipArgs = {
  projectId: Scalars['ID'];
};


export type QueryMetricArgs = {
  metricId?: Maybe<Scalars['ID']>;
  projectId: Scalars['ID'];
  reference?: Maybe<Scalars['String']>;
};


export type QueryMetricsArgs = {
  projectId: Scalars['ID'];
};


export type QueryMilestoneArgs = {
  id: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type QueryMilestonesArgs = {
  projectId: Scalars['ID'];
};


export type QueryPeopleWhoSelectedOptionArgs = {
  optionId: Scalars['ID'];
  projectId: Scalars['ID'];
  questionId: Scalars['ID'];
};


export type QueryPostArgs = {
  id: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type QueryProjectArgs = {
  id: Scalars['ID'];
};


export type QueryQuestionArgs = {
  id: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type QueryReactionGroupArgs = {
  postId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type QueryReactionPeopleArgs = {
  name: Scalars['String'];
  postId: Scalars['ID'];
  projectId: Scalars['ID'];
  reference: Scalars['String'];
};


export type QueryReactionsArgs = {
  postId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type QueryResourcesArgs = {
  projectId: Scalars['ID'];
};


export type QueryRoleArgs = {
  projectId: Scalars['ID'];
};


export type QueryTeammateArgs = {
  projectId: Scalars['ID'];
  teammateId: Scalars['ID'];
};


export type QueryTeammatesArgs = {
  projectId: Scalars['ID'];
};


export type QueryViewsArgs = {
  postId: Scalars['ID'];
  projectId: Scalars['ID'];
};

export type Asset = {
  __typename?: 'Asset';
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['Date']>;
  extension?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  size?: Maybe<Scalars['Int']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type CommentsGroup = {
  __typename?: 'CommentsGroup';
  id: Scalars['ID'];
  list: Array<Comment>;
};

export type Comment = {
  __typename?: 'Comment';
  client?: Maybe<Client>;
  clientId?: Maybe<Scalars['ID']>;
  content: Scalars['String'];
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  memberId?: Maybe<Scalars['ID']>;
  parentId?: Maybe<Scalars['ID']>;
  postId: Scalars['ID'];
  projectId: Scalars['ID'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
};

export type Client = {
  __typename?: 'Client';
  _id: Scalars['ID'];
  email: Scalars['String'];
  id: Scalars['ID'];
  user?: Maybe<User>;
};

export type User = {
  __typename?: 'User';
  _id: Scalars['ID'];
  email: Scalars['String'];
  name: Scalars['String'];
  photo: Scalars['String'];
};

export type Discussion = {
  __typename?: 'Discussion';
  _id: Scalars['ID'];
  createdAt: Scalars['Date'];
  post: Scalars['ID'];
  project: Scalars['ID'];
  reference?: Maybe<Scalars['String']>;
  thread: Array<ThreadItem>;
  updatedAt: Scalars['Date'];
};

export type ThreadItem = {
  __typename?: 'ThreadItem';
  _id: Scalars['ID'];
  client?: Maybe<Client>;
  createdAt: Scalars['Date'];
  member?: Maybe<Member>;
  message: Scalars['String'];
  updatedAt: Scalars['Date'];
  user?: Maybe<User>;
};

export type Member = {
  __typename?: 'Member';
  _id: Scalars['ID'];
  role: Scalars['String'];
  user: User;
};

export type Invite = {
  __typename?: 'Invite';
  _id: Scalars['ID'];
  email: Scalars['String'];
  expiresAt: Scalars['Date'];
};

export type Measure = {
  __typename?: 'Measure';
  _id: Scalars['ID'];
  createdAt: Scalars['Date'];
  date?: Maybe<Scalars['Date']>;
  metric: Scalars['ID'];
  reference: Scalars['String'];
  updatedAt: Scalars['Date'];
  value: Scalars['Float'];
};

export type Metric = {
  __typename?: 'Metric';
  _id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  measures?: Maybe<Array<Measure>>;
  name: Scalars['String'];
  project?: Maybe<Scalars['ID']>;
};

export type Milestone = {
  __typename?: 'Milestone';
  _id: Scalars['ID'];
  createdAt: Scalars['Date'];
  createdBy: User;
  date: Scalars['Date'];
  description: Scalars['String'];
  emoji: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type Post = {
  __typename?: 'Post';
  _id: Scalars['ID'];
  clients: Array<Client>;
  content?: Maybe<Scalars['Mixed']>;
  createdAt: Scalars['Date'];
  createdBy: User;
  date: Scalars['Date'];
  draft: Scalars['Boolean'];
  empty: Scalars['Boolean'];
  metadata?: Maybe<Scalars['Mixed']>;
  project: Scalars['ID'];
  publishedAt?: Maybe<Scalars['Date']>;
  resources: Array<Resource>;
  summary: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['Date'];
  viewers: Array<PostViewer>;
  views: Scalars['Int'];
};

export type Resource = {
  __typename?: 'Resource';
  _id: Scalars['ID'];
  asset: Asset;
  name?: Maybe<Scalars['String']>;
  pinned: Scalars['Boolean'];
};

export type PostViewer = {
  __typename?: 'PostViewer';
  _id: Scalars['ID'];
  email: Scalars['String'];
};

export type Project = {
  __typename?: 'Project';
  _id: Scalars['ID'];
  archivedAt?: Maybe<Scalars['Date']>;
  audience: Scalars['String'];
  clients: Array<Client>;
  createdAt: Scalars['Date'];
  createdBy: User;
  description?: Maybe<Scalars['String']>;
  invites: Array<Invite>;
  logo?: Maybe<Scalars['String']>;
  members: Array<Member>;
  milestones: Array<Milestone>;
  name: Scalars['String'];
  posts: Array<Post>;
  role: Scalars['String'];
  themeColor?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
};

export type Question = {
  __typename?: 'Question';
  _id: Scalars['String'];
  answer?: Maybe<Answer>;
  answers: Array<Answer>;
  createdAt: Scalars['Date'];
  message: Scalars['String'];
  metrics: Scalars['Mixed'];
  options: Array<AnswerOption>;
  post: Scalars['ID'];
  project: Scalars['ID'];
  reference: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
};

export type Answer = {
  __typename?: 'Answer';
  _id: Scalars['ID'];
  client?: Maybe<Client>;
  createdAt: Scalars['Date'];
  member?: Maybe<Member>;
  updatedAt: Scalars['Date'];
  user?: Maybe<User>;
  value: Scalars['String'];
};

export type AnswerOption = {
  __typename?: 'AnswerOption';
  _id: Scalars['ID'];
  message: Scalars['String'];
};

export type ReactionGroup = {
  __typename?: 'ReactionGroup';
  _id: Scalars['ID'];
  reactions: Array<Maybe<Reaction>>;
};

export type Reaction = {
  __typename?: 'Reaction';
  _id: Scalars['ID'];
  client?: Maybe<Scalars['ID']>;
  createdAt: Scalars['Date'];
  post: Scalars['ID'];
  project: Scalars['ID'];
  reference: Scalars['String'];
  self?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
  user?: Maybe<Scalars['ID']>;
  value: Scalars['String'];
};

export type ReactionPeople = {
  __typename?: 'ReactionPeople';
  _id: Scalars['ID'];
  people: Array<Scalars['String']>;
};

export type Role = {
  __typename?: 'Role';
  client?: Maybe<Client>;
  id: Scalars['ID'];
  member?: Maybe<Member>;
  type: RoleType;
};

export enum RoleType {
  Client = 'client',
  Member = 'member'
}

export type Teammate = {
  __typename?: 'Teammate';
  _id: Scalars['ID'];
  description: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  linkedIn?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  position: Scalars['String'];
  project: Scalars['ID'];
  team: Scalars['ID'];
};

export type ViewsGroup = {
  __typename?: 'ViewsGroup';
  id: Scalars['ID'];
  list: Array<View>;
};

export type View = {
  __typename?: 'View';
  client: Client;
  clientId: Scalars['ID'];
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  postId: Scalars['ID'];
  projectId: Scalars['ID'];
  updatedAt: Scalars['Date'];
};

export type Mutation = {
  __typename?: 'Mutation';
  authenticate: Scalars['String'];
  authenticateGoogle: Scalars['String'];
  createAnswer: Answer;
  createClient: Project;
  createComment: Comment;
  createDiscussion: Discussion;
  createMeasure: Measure;
  createMetric: Metric;
  createMilestone: Milestone;
  createPost: Post;
  createProject: Project;
  createQuestion: Question;
  createReaction: Reaction;
  createTeammate: Teammate;
  inviteMember: Project;
  publishPost: Post;
  removeAnswer: Answer;
  removeClient: Client;
  removeComment: Comment;
  removeInvite: Invite;
  removeMember: Project;
  removeMilestone: Milestone;
  removePost?: Maybe<Scalars['Boolean']>;
  removeProject?: Maybe<Project>;
  removeReaction: Reaction;
  removeTeammate: Teammate;
  requestResetPassword: Scalars['String'];
  resendVerificationEmail: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  updateMilestone: Milestone;
  updatePost: Post;
  updateProject: Project;
  updateQuestion: Question;
  updateSelf: User;
  updateTeammate: Teammate;
};


export type MutationAuthenticateArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationAuthenticateGoogleArgs = {
  token: Scalars['String'];
};


export type MutationCreateAnswerArgs = {
  input: AnswerInput;
  projectId: Scalars['ID'];
  questionId: Scalars['ID'];
};


export type MutationCreateClientArgs = {
  input: ClientInput;
  projectId: Scalars['ID'];
};


export type MutationCreateCommentArgs = {
  input: CommentInput;
  postId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type MutationCreateDiscussionArgs = {
  input: DiscussionInput;
  postId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type MutationCreateMeasureArgs = {
  input: MeasureInput;
  metricId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type MutationCreateMetricArgs = {
  name: Scalars['String'];
  projectId: Scalars['ID'];
};


export type MutationCreateMilestoneArgs = {
  input: MilestoneInput;
  projectId: Scalars['ID'];
};


export type MutationCreatePostArgs = {
  input: PostInput;
  projectId: Scalars['ID'];
};


export type MutationCreateProjectArgs = {
  input: ProjectInput;
};


export type MutationCreateQuestionArgs = {
  input: QuestionInput;
  projectId: Scalars['ID'];
};


export type MutationCreateReactionArgs = {
  input: ReactionInput;
  postId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type MutationCreateTeammateArgs = {
  input: TeammateInput;
  projectId: Scalars['ID'];
};


export type MutationInviteMemberArgs = {
  email: Scalars['String'];
  projectId: Scalars['ID'];
};


export type MutationPublishPostArgs = {
  id: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type MutationRemoveAnswerArgs = {
  answerId: Scalars['ID'];
  projectId: Scalars['ID'];
  questionId: Scalars['ID'];
};


export type MutationRemoveClientArgs = {
  id: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type MutationRemoveCommentArgs = {
  commentId: Scalars['ID'];
  postId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type MutationRemoveInviteArgs = {
  id: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type MutationRemoveMemberArgs = {
  id: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type MutationRemoveMilestoneArgs = {
  id: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type MutationRemovePostArgs = {
  id: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type MutationRemoveProjectArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveReactionArgs = {
  id: Scalars['ID'];
  postId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type MutationRemoveTeammateArgs = {
  projectId: Scalars['ID'];
  teammateId: Scalars['ID'];
};


export type MutationRequestResetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationUpdateMilestoneArgs = {
  id: Scalars['ID'];
  input: MilestoneInput;
  projectId: Scalars['ID'];
};


export type MutationUpdatePostArgs = {
  input: PostInput;
  postId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type MutationUpdateProjectArgs = {
  id: Scalars['ID'];
  input: ProjectUpdateInput;
};


export type MutationUpdateQuestionArgs = {
  id: Scalars['ID'];
  input: QuestionInput;
  projectId: Scalars['ID'];
};


export type MutationUpdateSelfArgs = {
  input: UserInput;
};


export type MutationUpdateTeammateArgs = {
  input: TeammateInput;
  projectId: Scalars['ID'];
  teammateId: Scalars['ID'];
};

export type AnswerInput = {
  value: Scalars['String'];
};

export type ClientInput = {
  email: Scalars['String'];
  notify?: Maybe<Scalars['Boolean']>;
};

export type CommentInput = {
  content: Scalars['String'];
};

export type DiscussionInput = {
  message?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
};

export type MeasureInput = {
  date?: Maybe<Scalars['Date']>;
  postId?: Maybe<Scalars['ID']>;
  reference?: Maybe<Scalars['String']>;
  value: Scalars['Float'];
};

export type MilestoneInput = {
  date?: Maybe<Scalars['Date']>;
  description: Scalars['String'];
  emoji: Scalars['String'];
};

export type PostInput = {
  content?: Maybe<Scalars['Mixed']>;
  date?: Maybe<Scalars['Date']>;
  metadata?: Maybe<Scalars['Mixed']>;
  summary?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ProjectInput = {
  audience?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  draftPrivacyPolicy?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  themeColor?: Maybe<Scalars['String']>;
};

export type QuestionInput = {
  message: Scalars['String'];
  options: Array<QuestionOptionInput>;
  postId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type QuestionOptionInput = {
  _id?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type ReactionInput = {
  reference?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type TeammateInput = {
  description?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
};

export type ProjectUpdateInput = {
  description?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  themeColor?: Maybe<Scalars['String']>;
};

export type UserInput = {
  name?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
};

export type Identity = {
  __typename?: 'Identity';
  id: Scalars['ID'];
  user: User;
  verified: Scalars['Boolean'];
};

export type Team = {
  __typename?: 'Team';
  _id: Scalars['ID'];
  project: Scalars['ID'];
  teammates: Array<Teammate>;
};

export type TextNode = {
  __typename?: 'TextNode';
  _id: Scalars['ID'];
  discussion?: Maybe<Discussion>;
  reactions?: Maybe<Array<Maybe<Reaction>>>;
  reference: Scalars['String'];
};

export type UserFragment = { __typename?: 'User', _id: string, photo: string, name: string, email: string };

export type GetSelfQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSelfQuery = { __typename?: 'Query', self?: { __typename?: 'User', _id: string, photo: string, name: string, email: string } | null | undefined };

export type ProjectFragment = { __typename?: 'Project', _id: string, name: string, role: string, description?: string | null | undefined, logo?: string | null | undefined, themeColor?: string | null | undefined, archivedAt?: any | null | undefined, createdAt: any, createdBy: { __typename?: 'User', _id: string, photo: string, name: string, email: string } };

export type ProjectQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProjectQuery = { __typename?: 'Query', self?: { __typename?: 'User', _id: string, photo: string, name: string, email: string } | null | undefined, project: { __typename?: 'Project', _id: string, name: string, role: string, description?: string | null | undefined, logo?: string | null | undefined, themeColor?: string | null | undefined, archivedAt?: any | null | undefined, createdAt: any, createdBy: { __typename?: 'User', _id: string, photo: string, name: string, email: string } } };

export type AuthenticateMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type AuthenticateMutation = { __typename?: 'Mutation', authenticate: string };

export type AuthenticateGoogleMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type AuthenticateGoogleMutation = { __typename?: 'Mutation', authenticateGoogle: string };

export type GetMetricsQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type GetMetricsQuery = { __typename?: 'Query', metrics: Array<{ __typename?: 'Metric', _id: string, name: string, measures?: Array<{ __typename?: 'Measure', _id: string, value: number, date?: any | null | undefined }> | null | undefined }> };

export type CreateMetricMutationVariables = Exact<{
  projectId: Scalars['ID'];
  name: Scalars['String'];
}>;


export type CreateMetricMutation = { __typename?: 'Mutation', createMetric: { __typename?: 'Metric', _id: string, name: string } };

export type CreateMeasureMutationVariables = Exact<{
  projectId: Scalars['ID'];
  metricId: Scalars['ID'];
  input: MeasureInput;
}>;


export type CreateMeasureMutation = { __typename?: 'Mutation', createMeasure: { __typename?: 'Measure', _id: string, value: number, date?: any | null | undefined } };

export type CreatePostMutationVariables = Exact<{
  projectId: Scalars['ID'];
  input: PostInput;
}>;


export type CreatePostMutation = { __typename?: 'Mutation', createPost: { __typename?: 'Post', _id: string, title: string, content?: any | null | undefined, date: any, summary: string, createdAt: any, updatedAt: any, publishedAt?: any | null | undefined, project: string, metadata?: any | null | undefined, createdBy: { __typename?: 'User', _id: string, photo: string, name: string, email: string } } };

export type UpdatePostMutationVariables = Exact<{
  projectId: Scalars['ID'];
  postId: Scalars['ID'];
  input: PostInput;
}>;


export type UpdatePostMutation = { __typename?: 'Mutation', updatePost: { __typename?: 'Post', _id: string, title: string, content?: any | null | undefined, date: any, summary: string, createdAt: any, updatedAt: any, publishedAt?: any | null | undefined, project: string, metadata?: any | null | undefined, createdBy: { __typename?: 'User', _id: string, photo: string, name: string, email: string } } };

export type PublishPostMutationVariables = Exact<{
  projectId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type PublishPostMutation = { __typename?: 'Mutation', publishPost: { __typename?: 'Post', _id: string, title: string, date: any, summary: string, content?: any | null | undefined, publishedAt?: any | null | undefined, updatedAt: any, project: string } };

export type GetMeasureQueryVariables = Exact<{
  projectId: Scalars['ID'];
  metricId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type GetMeasureQuery = { __typename?: 'Query', metric: { __typename?: 'Metric', _id: string, name: string }, measure: { __typename?: 'Measure', _id: string, value: number, date?: any | null | undefined, metric: string } };

export type QuestionFragment = { __typename?: 'Question', _id: string, type?: string | null | undefined, reference: string, project: string, message: string, metrics: any, options: Array<{ __typename?: 'AnswerOption', _id: string, message: string }> };

export type AnswerFragment = { __typename?: 'Answer', _id: string, value: string, createdAt: any };

export type GetPeopleWhoSelectedOptionQueryVariables = Exact<{
  projectId: Scalars['ID'];
  questionId: Scalars['ID'];
  optionId: Scalars['ID'];
}>;


export type GetPeopleWhoSelectedOptionQuery = { __typename?: 'Query', peopleWhoSelectedOption: Array<string | null | undefined> };

export type GetQuestionQueryVariables = Exact<{
  projectId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type GetQuestionQuery = { __typename?: 'Query', question: { __typename?: 'Question', _id: string, type?: string | null | undefined, reference: string, project: string, message: string, metrics: any, options: Array<{ __typename?: 'AnswerOption', _id: string, message: string }> } };

export type GetQuestionWithAnswerQueryVariables = Exact<{
  projectId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type GetQuestionWithAnswerQuery = { __typename?: 'Query', question: { __typename?: 'Question', _id: string, type?: string | null | undefined, reference: string, project: string, message: string, metrics: any, answer?: { __typename?: 'Answer', _id: string, value: string, createdAt: any } | null | undefined, options: Array<{ __typename?: 'AnswerOption', _id: string, message: string }> } };

export type GetQuestionWithAnswersQueryVariables = Exact<{
  projectId: Scalars['ID'];
  questionId: Scalars['ID'];
}>;


export type GetQuestionWithAnswersQuery = { __typename?: 'Query', question: { __typename?: 'Question', _id: string, type?: string | null | undefined, reference: string, project: string, message: string, metrics: any, answers: Array<{ __typename?: 'Answer', _id: string, value: string, createdAt: any, user?: { __typename?: 'User', _id: string, photo: string, name: string, email: string } | null | undefined, client?: { __typename?: 'Client', _id: string, email: string } | null | undefined }>, options: Array<{ __typename?: 'AnswerOption', _id: string, message: string }> } };

export type CreateAnswerMutationVariables = Exact<{
  projectId: Scalars['ID'];
  questionId: Scalars['ID'];
  input: AnswerInput;
}>;


export type CreateAnswerMutation = { __typename?: 'Mutation', createAnswer: { __typename?: 'Answer', _id: string, value: string, createdAt: any } };

export type RemoveAnswerMutationVariables = Exact<{
  projectId: Scalars['ID'];
  questionId: Scalars['ID'];
  answerId: Scalars['ID'];
}>;


export type RemoveAnswerMutation = { __typename?: 'Mutation', removeAnswer: { __typename?: 'Answer', _id: string, value: string, createdAt: any } };

export type CreateQuestionMutationVariables = Exact<{
  projectId: Scalars['ID'];
  input: QuestionInput;
}>;


export type CreateQuestionMutation = { __typename?: 'Mutation', createQuestion: { __typename?: 'Question', _id: string, type?: string | null | undefined, reference: string, project: string, message: string, metrics: any, options: Array<{ __typename?: 'AnswerOption', _id: string, message: string }> } };

export type UpdateQuestionMutationVariables = Exact<{
  projectId: Scalars['ID'];
  id: Scalars['ID'];
  input: QuestionInput;
}>;


export type UpdateQuestionMutation = { __typename?: 'Mutation', updateQuestion: { __typename?: 'Question', _id: string, type?: string | null | undefined, reference: string, project: string, message: string, metrics: any, options: Array<{ __typename?: 'AnswerOption', _id: string, message: string }> } };

export type GetReactionPeopleQueryVariables = Exact<{
  projectId: Scalars['ID'];
  postId: Scalars['ID'];
  reference: Scalars['String'];
  name: Scalars['String'];
}>;


export type GetReactionPeopleQuery = { __typename?: 'Query', reactionPeople: { __typename?: 'ReactionPeople', _id: string, people: Array<string> } };

export type ThreadFragment = { __typename?: 'ThreadItem', _id: string, message: string, createdAt: any, user?: { __typename?: 'User', _id: string, photo: string, name: string, email: string } | null | undefined, client?: { __typename?: 'Client', _id: string, email: string } | null | undefined };

export type CommentFragment = { __typename?: 'Comment', id: string, memberId?: string | null | undefined, clientId?: string | null | undefined, userId?: string | null | undefined, content: string, createdAt: any, client?: { __typename?: 'Client', id: string, email: string } | null | undefined, user?: { __typename?: 'User', _id: string, photo: string, name: string, email: string } | null | undefined };

export type ViewFragment = { __typename?: 'View', id: string, clientId: string, createdAt: any, client: { __typename?: 'Client', id: string, email: string, user?: { __typename?: 'User', _id: string, photo: string, name: string, email: string } | null | undefined } };

export type RoleFragment = { __typename?: 'Role', id: string, type: RoleType, member?: { __typename?: 'Member', _id: string } | null | undefined, client?: { __typename?: 'Client', _id: string, email: string } | null | undefined };

export type GetFeedbackQueryVariables = Exact<{
  projectId: Scalars['ID'];
  postId: Scalars['ID'];
  includeViews: Scalars['Boolean'];
}>;


export type GetFeedbackQuery = { __typename?: 'Query', self?: { __typename?: 'User', _id: string } | null | undefined, role: { __typename?: 'Role', id: string, type: RoleType, member?: { __typename?: 'Member', _id: string } | null | undefined, client?: { __typename?: 'Client', _id: string, email: string } | null | undefined }, views?: { __typename?: 'ViewsGroup', id: string, list: Array<{ __typename?: 'View', id: string, clientId: string, createdAt: any, client: { __typename?: 'Client', id: string, email: string, user?: { __typename?: 'User', _id: string, photo: string, name: string, email: string } | null | undefined } }> }, comments: { __typename?: 'CommentsGroup', id: string, list: Array<{ __typename?: 'Comment', id: string, memberId?: string | null | undefined, clientId?: string | null | undefined, userId?: string | null | undefined, content: string, createdAt: any, client?: { __typename?: 'Client', id: string, email: string } | null | undefined, user?: { __typename?: 'User', _id: string, photo: string, name: string, email: string } | null | undefined }> } };

export type CreateCommentMutationVariables = Exact<{
  projectId: Scalars['ID'];
  postId: Scalars['ID'];
  input: CommentInput;
}>;


export type CreateCommentMutation = { __typename?: 'Mutation', createComment: { __typename?: 'Comment', id: string, memberId?: string | null | undefined, clientId?: string | null | undefined, userId?: string | null | undefined, content: string, postId: string } };

export type RemoveCommentMutationVariables = Exact<{
  projectId: Scalars['ID'];
  postId: Scalars['ID'];
  commentId: Scalars['ID'];
}>;


export type RemoveCommentMutation = { __typename?: 'Mutation', removeComment: { __typename?: 'Comment', id: string, memberId?: string | null | undefined, clientId?: string | null | undefined, userId?: string | null | undefined, content: string, postId: string } };

export type ReactionFragment = { __typename?: 'Reaction', _id: string, client?: string | null | undefined, post: string, project: string, reference: string, self?: boolean | null | undefined, user?: string | null | undefined, value: string };

export type GetPostQueryVariables = Exact<{
  projectId: Scalars['ID'];
  postId: Scalars['ID'];
}>;


export type GetPostQuery = { __typename?: 'Query', post: { __typename?: 'Post', _id: string, title: string, content?: any | null | undefined, project: string, summary: string, date: any, createdAt: any, updatedAt: any, publishedAt?: any | null | undefined, metadata?: any | null | undefined, draft: boolean, createdBy: { __typename?: 'User', _id: string, photo: string, name: string, email: string } } };

export type GetDiscussionsQueryVariables = Exact<{
  projectId: Scalars['ID'];
  postId: Scalars['ID'];
}>;


export type GetDiscussionsQuery = { __typename?: 'Query', discussions: Array<{ __typename?: 'Discussion', _id: string, reference?: string | null | undefined, thread: Array<{ __typename?: 'ThreadItem', _id: string, message: string, createdAt: any, user?: { __typename?: 'User', _id: string, photo: string, name: string, email: string } | null | undefined, client?: { __typename?: 'Client', _id: string, email: string } | null | undefined }> }> };

export type CreateDiscussionMutationVariables = Exact<{
  projectId: Scalars['ID'];
  postId: Scalars['ID'];
  input: DiscussionInput;
}>;


export type CreateDiscussionMutation = { __typename?: 'Mutation', createDiscussion: { __typename?: 'Discussion', _id: string, reference?: string | null | undefined, thread: Array<{ __typename?: 'ThreadItem', _id: string, message: string, createdAt: any, user?: { __typename?: 'User', _id: string, photo: string, name: string, email: string } | null | undefined, client?: { __typename?: 'Client', _id: string, email: string } | null | undefined }> } };

export type GetReactionsQueryVariables = Exact<{
  projectId: Scalars['ID'];
  postId: Scalars['ID'];
}>;


export type GetReactionsQuery = { __typename?: 'Query', reactionGroup: { __typename?: 'ReactionGroup', _id: string, reactions: Array<{ __typename?: 'Reaction', _id: string, client?: string | null | undefined, post: string, project: string, reference: string, self?: boolean | null | undefined, user?: string | null | undefined, value: string } | null | undefined> } };

export type CreateReactionMutationVariables = Exact<{
  projectId: Scalars['ID'];
  postId: Scalars['ID'];
  input: ReactionInput;
}>;


export type CreateReactionMutation = { __typename?: 'Mutation', createReaction: { __typename?: 'Reaction', _id: string, client?: string | null | undefined, post: string, project: string, reference: string, self?: boolean | null | undefined, user?: string | null | undefined, value: string } };

export type RemoveReactionMutationVariables = Exact<{
  projectId: Scalars['ID'];
  postId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type RemoveReactionMutation = { __typename?: 'Mutation', removeReaction: { __typename?: 'Reaction', _id: string } };

export type GetMetricQueryVariables = Exact<{
  projectId: Scalars['ID'];
  metricId?: Maybe<Scalars['ID']>;
}>;


export type GetMetricQuery = { __typename?: 'Query', metric: { __typename?: 'Metric', _id: string, name: string, measures?: Array<{ __typename?: 'Measure', _id: string, value: number, date?: any | null | undefined }> | null | undefined } };

export type UpdateSelfMutationVariables = Exact<{
  input: UserInput;
}>;


export type UpdateSelfMutation = { __typename?: 'Mutation', updateSelf: { __typename?: 'User', _id: string, email: string, name: string, photo: string } };

export type UpdatePhotoMutationVariables = Exact<{
  input: UserInput;
}>;


export type UpdatePhotoMutation = { __typename?: 'Mutation', updateSelf: { __typename?: 'User', _id: string, photo: string } };

export type CreateMilestoneMutationVariables = Exact<{
  projectId: Scalars['ID'];
  input: MilestoneInput;
}>;


export type CreateMilestoneMutation = { __typename?: 'Mutation', createMilestone: { __typename?: 'Milestone', _id: string, emoji: string, date: any, description: string, createdAt: any, createdBy: { __typename?: 'User', _id: string, photo: string, name: string, email: string } } };

export type UpdateMilestoneMutationVariables = Exact<{
  projectId: Scalars['ID'];
  id: Scalars['ID'];
  input: MilestoneInput;
}>;


export type UpdateMilestoneMutation = { __typename?: 'Mutation', updateMilestone: { __typename?: 'Milestone', _id: string, emoji: string, date: any, description: string, createdAt: any, createdBy: { __typename?: 'User', _id: string, photo: string, name: string, email: string } } };

export type GetMilestoneQueryVariables = Exact<{
  projectId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type GetMilestoneQuery = { __typename?: 'Query', milestone: { __typename?: 'Milestone', _id: string, emoji: string, date: any, description: string, createdAt: any, createdBy: { __typename?: 'User', _id: string, photo: string, name: string, email: string } } };

export type GetMembershipQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetMembershipQuery = { __typename?: 'Query', membership?: { __typename?: 'Member', _id: string, role: string } | null | undefined };

export type AssetFragment = { __typename?: 'Asset', _id: string, url: string, mimeType?: string | null | undefined, extension?: string | null | undefined, name: string, createdAt?: any | null | undefined, size?: number | null | undefined };

export type ResourceFragment = { __typename?: 'Resource', _id: string, pinned: boolean, name?: string | null | undefined, asset: { __typename?: 'Asset', _id: string, url: string, mimeType?: string | null | undefined, extension?: string | null | undefined, name: string, createdAt?: any | null | undefined, size?: number | null | undefined } };

export type GetResourcesQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type GetResourcesQuery = { __typename?: 'Query', resources: Array<{ __typename?: 'Resource', _id: string, pinned: boolean, name?: string | null | undefined, asset: { __typename?: 'Asset', _id: string, url: string, mimeType?: string | null | undefined, extension?: string | null | undefined, name: string, createdAt?: any | null | undefined, size?: number | null | undefined } }> };

export type GetMetricDetailsQueryVariables = Exact<{
  projectId: Scalars['ID'];
  metricId: Scalars['ID'];
}>;


export type GetMetricDetailsQuery = { __typename?: 'Query', metric: { __typename?: 'Metric', _id: string, name: string, measures?: Array<{ __typename?: 'Measure', _id: string, value: number, date?: any | null | undefined }> | null | undefined } };

export type InviteGuestMutationVariables = Exact<{
  projectId: Scalars['ID'];
  input: ClientInput;
}>;


export type InviteGuestMutation = { __typename?: 'Mutation', createClient: { __typename?: 'Project', _id: string, clients: Array<{ __typename?: 'Client', _id: string, email: string }>, invites: Array<{ __typename?: 'Invite', _id: string, email: string }> } };

export type RemoveClientMutationVariables = Exact<{
  projectId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type RemoveClientMutation = { __typename?: 'Mutation', removeClient: { __typename?: 'Client', _id: string } };

export type GetClientsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetClientsQuery = { __typename?: 'Query', project: { __typename?: 'Project', _id: string, clients: Array<{ __typename?: 'Client', _id: string, email: string, user?: { __typename?: 'User', _id: string, photo: string, name: string, email: string } | null | undefined }> } };

export type GetProjectSettingsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetProjectSettingsQuery = { __typename?: 'Query', project: { __typename?: 'Project', _id: string, name: string, description?: string | null | undefined, updatedAt: any, themeColor?: string | null | undefined, logo?: string | null | undefined } };

export type UpdateProjectMutationVariables = Exact<{
  id: Scalars['ID'];
  input: ProjectUpdateInput;
}>;


export type UpdateProjectMutation = { __typename?: 'Mutation', updateProject: { __typename?: 'Project', _id: string, name: string, description?: string | null | undefined, updatedAt: any, themeColor?: string | null | undefined, logo?: string | null | undefined } };

export type RemoveProjectMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveProjectMutation = { __typename?: 'Mutation', removeProject?: { __typename?: 'Project', _id: string, archivedAt?: any | null | undefined } | null | undefined };

export type InviteMemberMutationVariables = Exact<{
  projectId: Scalars['ID'];
  email: Scalars['String'];
}>;


export type InviteMemberMutation = { __typename?: 'Mutation', inviteMember: { __typename?: 'Project', _id: string, invites: Array<{ __typename?: 'Invite', _id: string, email: string }>, members: Array<{ __typename?: 'Member', _id: string, role: string, user: { __typename?: 'User', _id: string, photo: string, name: string, email: string } }> } };

export type MemberFragment = { __typename?: 'Member', _id: string, role: string, user: { __typename?: 'User', _id: string, photo: string, name: string, email: string } };

export type GetTeamQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetTeamQuery = { __typename?: 'Query', project: { __typename?: 'Project', _id: string, members: Array<{ __typename?: 'Member', _id: string, role: string, user: { __typename?: 'User', _id: string, photo: string, name: string, email: string } }>, invites: Array<{ __typename?: 'Invite', _id: string, email: string }> } };

export type RemoveMemberMutationVariables = Exact<{
  projectId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type RemoveMemberMutation = { __typename?: 'Mutation', removeMember: { __typename?: 'Project', _id: string, members: Array<{ __typename?: 'Member', _id: string, role: string, user: { __typename?: 'User', _id: string, photo: string, name: string, email: string } }> } };

export type RemoveInviteMutationVariables = Exact<{
  projectId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type RemoveInviteMutation = { __typename?: 'Mutation', removeInvite: { __typename?: 'Invite', _id: string } };

export type TeammateFragment = { __typename?: 'Teammate', _id: string, firstName: string, lastName: string, description: string, position: string, phone?: string | null | undefined, linkedIn?: string | null | undefined, photoUrl?: string | null | undefined };

export type GetTeammateQueryVariables = Exact<{
  projectId: Scalars['ID'];
  teammateId: Scalars['ID'];
}>;


export type GetTeammateQuery = { __typename?: 'Query', teammate: { __typename?: 'Teammate', _id: string, firstName: string, lastName: string, description: string, position: string, phone?: string | null | undefined, linkedIn?: string | null | undefined, photoUrl?: string | null | undefined } };

export type RemoveTeammateMutationVariables = Exact<{
  projectId: Scalars['ID'];
  teammateId: Scalars['ID'];
}>;


export type RemoveTeammateMutation = { __typename?: 'Mutation', removeTeammate: { __typename?: 'Teammate', _id: string, firstName: string, lastName: string, description: string, position: string, phone?: string | null | undefined, linkedIn?: string | null | undefined, photoUrl?: string | null | undefined } };

export type GetTeammatesQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type GetTeammatesQuery = { __typename?: 'Query', teammates: Array<{ __typename?: 'Teammate', _id: string, firstName: string, lastName: string, description: string, position: string, phone?: string | null | undefined, linkedIn?: string | null | undefined, photoUrl?: string | null | undefined }> };

export type CreateTeammateMutationVariables = Exact<{
  projectId: Scalars['ID'];
  input: TeammateInput;
}>;


export type CreateTeammateMutation = { __typename?: 'Mutation', createTeammate: { __typename?: 'Teammate', _id: string, firstName: string, lastName: string, description: string, position: string, phone?: string | null | undefined, linkedIn?: string | null | undefined, photoUrl?: string | null | undefined } };

export type UpdateTeammateMutationVariables = Exact<{
  projectId: Scalars['ID'];
  teammateId: Scalars['ID'];
  input: TeammateInput;
}>;


export type UpdateTeammateMutation = { __typename?: 'Mutation', updateTeammate: { __typename?: 'Teammate', _id: string, firstName: string, lastName: string, description: string, position: string, phone?: string | null | undefined, linkedIn?: string | null | undefined, photoUrl?: string | null | undefined } };

export type RemoveMilestoneMutationVariables = Exact<{
  projectId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type RemoveMilestoneMutation = { __typename?: 'Mutation', removeMilestone: { __typename?: 'Milestone', _id: string } };

export type RemovePostMutationVariables = Exact<{
  projectId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type RemovePostMutation = { __typename?: 'Mutation', removePost?: boolean | null | undefined };

export type PostFragment = { __typename?: 'Post', _id: string, summary: string, title: string, project: string, createdAt: any, updatedAt: any, publishedAt?: any | null | undefined, date: any, views: number, resources: Array<{ __typename?: 'Resource', asset: { __typename?: 'Asset', _id: string, mimeType?: string | null | undefined, url: string } }>, createdBy: { __typename?: 'User', _id: string, photo: string, name: string, email: string } };

export type MilestoneFragment = { __typename?: 'Milestone', _id: string, emoji: string, date: any, description: string, createdAt: any, createdBy: { __typename?: 'User', _id: string, photo: string, name: string, email: string } };

export type GetPostsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetPostsQuery = { __typename?: 'Query', project: { __typename?: 'Project', _id: string, name: string, createdAt: any, milestones: Array<{ __typename?: 'Milestone', _id: string, emoji: string, date: any, description: string, createdAt: any, createdBy: { __typename?: 'User', _id: string, photo: string, name: string, email: string } }>, posts: Array<{ __typename?: 'Post', _id: string, summary: string, title: string, project: string, createdAt: any, updatedAt: any, publishedAt?: any | null | undefined, date: any, views: number, resources: Array<{ __typename?: 'Resource', asset: { __typename?: 'Asset', _id: string, mimeType?: string | null | undefined, url: string } }>, createdBy: { __typename?: 'User', _id: string, photo: string, name: string, email: string } }> } };

export type CreateProjectMutationVariables = Exact<{
  input: ProjectInput;
}>;


export type CreateProjectMutation = { __typename?: 'Mutation', createProject: { __typename?: 'Project', _id: string, name: string, logo?: string | null | undefined, archivedAt?: any | null | undefined, createdAt: any, themeColor?: string | null | undefined } };

export type GetProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProjectsQuery = { __typename?: 'Query', projects: Array<{ __typename?: 'Project', _id: string, name: string, logo?: string | null | undefined, themeColor?: string | null | undefined, role: string, createdAt: any, createdBy: { __typename?: 'User', _id: string, photo: string, name: string, email: string } }> };

export type ResetPasswordRequestMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ResetPasswordRequestMutation = { __typename?: 'Mutation', requestResetPassword: string };

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: boolean };

export type ResendVerificationEmailMutationVariables = Exact<{ [key: string]: never; }>;


export type ResendVerificationEmailMutation = { __typename?: 'Mutation', resendVerificationEmail: boolean };

export const UserFragmentDoc = gql`
    fragment User on User {
  _id
  photo
  name
  email
}
    `;
export const ProjectFragmentDoc = gql`
    fragment Project on Project {
  _id
  name
  role
  description
  logo
  themeColor
  archivedAt
  createdAt
  createdBy {
    ...User
  }
}
    ${UserFragmentDoc}`;
export const QuestionFragmentDoc = gql`
    fragment Question on Question {
  _id
  type
  reference
  project
  message
  options {
    _id
    message
  }
  metrics
}
    `;
export const AnswerFragmentDoc = gql`
    fragment Answer on Answer {
  _id
  value
  createdAt
}
    `;
export const ThreadFragmentDoc = gql`
    fragment Thread on ThreadItem {
  _id
  user {
    ...User
  }
  client {
    _id
    email
  }
  message
  createdAt
}
    ${UserFragmentDoc}`;
export const CommentFragmentDoc = gql`
    fragment Comment on Comment {
  id
  memberId
  clientId
  userId
  content
  client {
    id
    email
  }
  user {
    ...User
  }
  createdAt
}
    ${UserFragmentDoc}`;
export const ViewFragmentDoc = gql`
    fragment View on View {
  id
  clientId
  client {
    id
    email
    user {
      ...User
    }
  }
  createdAt
}
    ${UserFragmentDoc}`;
export const RoleFragmentDoc = gql`
    fragment Role on Role {
  id
  type
  member {
    _id
  }
  client {
    _id
    email
  }
}
    `;
export const ReactionFragmentDoc = gql`
    fragment Reaction on Reaction {
  _id
  client
  post
  project
  reference
  self
  user
  value
}
    `;
export const AssetFragmentDoc = gql`
    fragment Asset on Asset {
  _id
  url
  mimeType
  extension
  name
  createdAt
  size
}
    `;
export const ResourceFragmentDoc = gql`
    fragment Resource on Resource {
  _id
  pinned
  name
  asset {
    ...Asset
  }
}
    ${AssetFragmentDoc}`;
export const MemberFragmentDoc = gql`
    fragment Member on Member {
  _id
  role
  user {
    ...User
  }
}
    ${UserFragmentDoc}`;
export const TeammateFragmentDoc = gql`
    fragment Teammate on Teammate {
  _id
  firstName
  lastName
  description
  position
  phone
  linkedIn
  photoUrl
}
    `;
export const PostFragmentDoc = gql`
    fragment Post on Post {
  _id
  summary
  title
  project
  createdAt
  updatedAt
  publishedAt
  date
  views
  resources {
    asset {
      _id
      mimeType
      url
    }
  }
  createdBy {
    ...User
  }
}
    ${UserFragmentDoc}`;
export const MilestoneFragmentDoc = gql`
    fragment Milestone on Milestone {
  _id
  emoji
  date
  description
  createdAt
  createdBy {
    ...User
  }
}
    ${UserFragmentDoc}`;
export const GetSelfDocument = gql`
    query GetSelf {
  self {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetSelfQuery__
 *
 * To run a query within a React component, call `useGetSelfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelfQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSelfQuery(baseOptions?: Apollo.QueryHookOptions<GetSelfQuery, GetSelfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSelfQuery, GetSelfQueryVariables>(GetSelfDocument, options);
      }
export function useGetSelfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSelfQuery, GetSelfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSelfQuery, GetSelfQueryVariables>(GetSelfDocument, options);
        }
export type GetSelfQueryHookResult = ReturnType<typeof useGetSelfQuery>;
export type GetSelfLazyQueryHookResult = ReturnType<typeof useGetSelfLazyQuery>;
export type GetSelfQueryResult = Apollo.QueryResult<GetSelfQuery, GetSelfQueryVariables>;
export const ProjectDocument = gql`
    query Project($id: ID!) {
  self {
    ...User
  }
  project(id: $id) {
    ...Project
  }
}
    ${UserFragmentDoc}
${ProjectFragmentDoc}`;

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectQuery(baseOptions: Apollo.QueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
      }
export function useProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
        }
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>;
export type ProjectQueryResult = Apollo.QueryResult<ProjectQuery, ProjectQueryVariables>;
export const AuthenticateDocument = gql`
    mutation Authenticate($email: String!, $password: String!) {
  authenticate(email: $email, password: $password)
}
    `;
export type AuthenticateMutationFn = Apollo.MutationFunction<AuthenticateMutation, AuthenticateMutationVariables>;

/**
 * __useAuthenticateMutation__
 *
 * To run a mutation, you first call `useAuthenticateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateMutation, { data, loading, error }] = useAuthenticateMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAuthenticateMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticateMutation, AuthenticateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthenticateMutation, AuthenticateMutationVariables>(AuthenticateDocument, options);
      }
export type AuthenticateMutationHookResult = ReturnType<typeof useAuthenticateMutation>;
export type AuthenticateMutationResult = Apollo.MutationResult<AuthenticateMutation>;
export type AuthenticateMutationOptions = Apollo.BaseMutationOptions<AuthenticateMutation, AuthenticateMutationVariables>;
export const AuthenticateGoogleDocument = gql`
    mutation AuthenticateGoogle($token: String!) {
  authenticateGoogle(token: $token)
}
    `;
export type AuthenticateGoogleMutationFn = Apollo.MutationFunction<AuthenticateGoogleMutation, AuthenticateGoogleMutationVariables>;

/**
 * __useAuthenticateGoogleMutation__
 *
 * To run a mutation, you first call `useAuthenticateGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateGoogleMutation, { data, loading, error }] = useAuthenticateGoogleMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useAuthenticateGoogleMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticateGoogleMutation, AuthenticateGoogleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthenticateGoogleMutation, AuthenticateGoogleMutationVariables>(AuthenticateGoogleDocument, options);
      }
export type AuthenticateGoogleMutationHookResult = ReturnType<typeof useAuthenticateGoogleMutation>;
export type AuthenticateGoogleMutationResult = Apollo.MutationResult<AuthenticateGoogleMutation>;
export type AuthenticateGoogleMutationOptions = Apollo.BaseMutationOptions<AuthenticateGoogleMutation, AuthenticateGoogleMutationVariables>;
export const GetMetricsDocument = gql`
    query GetMetrics($projectId: ID!) {
  metrics(projectId: $projectId) {
    _id
    name
    measures {
      _id
      value
      date
    }
  }
}
    `;

/**
 * __useGetMetricsQuery__
 *
 * To run a query within a React component, call `useGetMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetricsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetMetricsQuery(baseOptions: Apollo.QueryHookOptions<GetMetricsQuery, GetMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMetricsQuery, GetMetricsQueryVariables>(GetMetricsDocument, options);
      }
export function useGetMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMetricsQuery, GetMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMetricsQuery, GetMetricsQueryVariables>(GetMetricsDocument, options);
        }
export type GetMetricsQueryHookResult = ReturnType<typeof useGetMetricsQuery>;
export type GetMetricsLazyQueryHookResult = ReturnType<typeof useGetMetricsLazyQuery>;
export type GetMetricsQueryResult = Apollo.QueryResult<GetMetricsQuery, GetMetricsQueryVariables>;
export const CreateMetricDocument = gql`
    mutation CreateMetric($projectId: ID!, $name: String!) {
  createMetric(projectId: $projectId, name: $name) {
    _id
    name
  }
}
    `;
export type CreateMetricMutationFn = Apollo.MutationFunction<CreateMetricMutation, CreateMetricMutationVariables>;

/**
 * __useCreateMetricMutation__
 *
 * To run a mutation, you first call `useCreateMetricMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMetricMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMetricMutation, { data, loading, error }] = useCreateMetricMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateMetricMutation(baseOptions?: Apollo.MutationHookOptions<CreateMetricMutation, CreateMetricMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMetricMutation, CreateMetricMutationVariables>(CreateMetricDocument, options);
      }
export type CreateMetricMutationHookResult = ReturnType<typeof useCreateMetricMutation>;
export type CreateMetricMutationResult = Apollo.MutationResult<CreateMetricMutation>;
export type CreateMetricMutationOptions = Apollo.BaseMutationOptions<CreateMetricMutation, CreateMetricMutationVariables>;
export const CreateMeasureDocument = gql`
    mutation CreateMeasure($projectId: ID!, $metricId: ID!, $input: MeasureInput!) {
  createMeasure(projectId: $projectId, metricId: $metricId, input: $input) {
    _id
    value
    date
  }
}
    `;
export type CreateMeasureMutationFn = Apollo.MutationFunction<CreateMeasureMutation, CreateMeasureMutationVariables>;

/**
 * __useCreateMeasureMutation__
 *
 * To run a mutation, you first call `useCreateMeasureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMeasureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMeasureMutation, { data, loading, error }] = useCreateMeasureMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      metricId: // value for 'metricId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMeasureMutation(baseOptions?: Apollo.MutationHookOptions<CreateMeasureMutation, CreateMeasureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMeasureMutation, CreateMeasureMutationVariables>(CreateMeasureDocument, options);
      }
export type CreateMeasureMutationHookResult = ReturnType<typeof useCreateMeasureMutation>;
export type CreateMeasureMutationResult = Apollo.MutationResult<CreateMeasureMutation>;
export type CreateMeasureMutationOptions = Apollo.BaseMutationOptions<CreateMeasureMutation, CreateMeasureMutationVariables>;
export const CreatePostDocument = gql`
    mutation CreatePost($projectId: ID!, $input: PostInput!) {
  createPost(projectId: $projectId, input: $input) {
    _id
    title
    content
    date
    summary
    createdAt
    updatedAt
    publishedAt
    project
    metadata
    createdBy {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;
export type CreatePostMutationFn = Apollo.MutationFunction<CreatePostMutation, CreatePostMutationVariables>;

/**
 * __useCreatePostMutation__
 *
 * To run a mutation, you first call `useCreatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostMutation, { data, loading, error }] = useCreatePostMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePostMutation(baseOptions?: Apollo.MutationHookOptions<CreatePostMutation, CreatePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePostMutation, CreatePostMutationVariables>(CreatePostDocument, options);
      }
export type CreatePostMutationHookResult = ReturnType<typeof useCreatePostMutation>;
export type CreatePostMutationResult = Apollo.MutationResult<CreatePostMutation>;
export type CreatePostMutationOptions = Apollo.BaseMutationOptions<CreatePostMutation, CreatePostMutationVariables>;
export const UpdatePostDocument = gql`
    mutation UpdatePost($projectId: ID!, $postId: ID!, $input: PostInput!) {
  updatePost(projectId: $projectId, postId: $postId, input: $input) {
    _id
    title
    content
    date
    summary
    createdAt
    updatedAt
    publishedAt
    project
    metadata
    createdBy {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;
export type UpdatePostMutationFn = Apollo.MutationFunction<UpdatePostMutation, UpdatePostMutationVariables>;

/**
 * __useUpdatePostMutation__
 *
 * To run a mutation, you first call `useUpdatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostMutation, { data, loading, error }] = useUpdatePostMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      postId: // value for 'postId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePostMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePostMutation, UpdatePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePostMutation, UpdatePostMutationVariables>(UpdatePostDocument, options);
      }
export type UpdatePostMutationHookResult = ReturnType<typeof useUpdatePostMutation>;
export type UpdatePostMutationResult = Apollo.MutationResult<UpdatePostMutation>;
export type UpdatePostMutationOptions = Apollo.BaseMutationOptions<UpdatePostMutation, UpdatePostMutationVariables>;
export const PublishPostDocument = gql`
    mutation PublishPost($projectId: ID!, $id: ID!) {
  publishPost(projectId: $projectId, id: $id) {
    _id
    title
    date
    summary
    content
    publishedAt
    updatedAt
    project
  }
}
    `;
export type PublishPostMutationFn = Apollo.MutationFunction<PublishPostMutation, PublishPostMutationVariables>;

/**
 * __usePublishPostMutation__
 *
 * To run a mutation, you first call `usePublishPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishPostMutation, { data, loading, error }] = usePublishPostMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishPostMutation(baseOptions?: Apollo.MutationHookOptions<PublishPostMutation, PublishPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishPostMutation, PublishPostMutationVariables>(PublishPostDocument, options);
      }
export type PublishPostMutationHookResult = ReturnType<typeof usePublishPostMutation>;
export type PublishPostMutationResult = Apollo.MutationResult<PublishPostMutation>;
export type PublishPostMutationOptions = Apollo.BaseMutationOptions<PublishPostMutation, PublishPostMutationVariables>;
export const GetMeasureDocument = gql`
    query GetMeasure($projectId: ID!, $metricId: ID!, $id: ID!) {
  metric(projectId: $projectId, metricId: $metricId) {
    _id
    name
  }
  measure(projectId: $projectId, metricId: $metricId, id: $id) {
    _id
    value
    date
    metric
  }
}
    `;

/**
 * __useGetMeasureQuery__
 *
 * To run a query within a React component, call `useGetMeasureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeasureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeasureQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      metricId: // value for 'metricId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMeasureQuery(baseOptions: Apollo.QueryHookOptions<GetMeasureQuery, GetMeasureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeasureQuery, GetMeasureQueryVariables>(GetMeasureDocument, options);
      }
export function useGetMeasureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeasureQuery, GetMeasureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeasureQuery, GetMeasureQueryVariables>(GetMeasureDocument, options);
        }
export type GetMeasureQueryHookResult = ReturnType<typeof useGetMeasureQuery>;
export type GetMeasureLazyQueryHookResult = ReturnType<typeof useGetMeasureLazyQuery>;
export type GetMeasureQueryResult = Apollo.QueryResult<GetMeasureQuery, GetMeasureQueryVariables>;
export const GetPeopleWhoSelectedOptionDocument = gql`
    query GetPeopleWhoSelectedOption($projectId: ID!, $questionId: ID!, $optionId: ID!) {
  peopleWhoSelectedOption(projectId: $projectId, questionId: $questionId, optionId: $optionId)
}
    `;

/**
 * __useGetPeopleWhoSelectedOptionQuery__
 *
 * To run a query within a React component, call `useGetPeopleWhoSelectedOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPeopleWhoSelectedOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeopleWhoSelectedOptionQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      questionId: // value for 'questionId'
 *      optionId: // value for 'optionId'
 *   },
 * });
 */
export function useGetPeopleWhoSelectedOptionQuery(baseOptions: Apollo.QueryHookOptions<GetPeopleWhoSelectedOptionQuery, GetPeopleWhoSelectedOptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPeopleWhoSelectedOptionQuery, GetPeopleWhoSelectedOptionQueryVariables>(GetPeopleWhoSelectedOptionDocument, options);
      }
export function useGetPeopleWhoSelectedOptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPeopleWhoSelectedOptionQuery, GetPeopleWhoSelectedOptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPeopleWhoSelectedOptionQuery, GetPeopleWhoSelectedOptionQueryVariables>(GetPeopleWhoSelectedOptionDocument, options);
        }
export type GetPeopleWhoSelectedOptionQueryHookResult = ReturnType<typeof useGetPeopleWhoSelectedOptionQuery>;
export type GetPeopleWhoSelectedOptionLazyQueryHookResult = ReturnType<typeof useGetPeopleWhoSelectedOptionLazyQuery>;
export type GetPeopleWhoSelectedOptionQueryResult = Apollo.QueryResult<GetPeopleWhoSelectedOptionQuery, GetPeopleWhoSelectedOptionQueryVariables>;
export const GetQuestionDocument = gql`
    query GetQuestion($projectId: ID!, $id: ID!) {
  question(projectId: $projectId, id: $id) {
    ...Question
  }
}
    ${QuestionFragmentDoc}`;

/**
 * __useGetQuestionQuery__
 *
 * To run a query within a React component, call `useGetQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetQuestionQuery(baseOptions: Apollo.QueryHookOptions<GetQuestionQuery, GetQuestionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionQuery, GetQuestionQueryVariables>(GetQuestionDocument, options);
      }
export function useGetQuestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionQuery, GetQuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionQuery, GetQuestionQueryVariables>(GetQuestionDocument, options);
        }
export type GetQuestionQueryHookResult = ReturnType<typeof useGetQuestionQuery>;
export type GetQuestionLazyQueryHookResult = ReturnType<typeof useGetQuestionLazyQuery>;
export type GetQuestionQueryResult = Apollo.QueryResult<GetQuestionQuery, GetQuestionQueryVariables>;
export const GetQuestionWithAnswerDocument = gql`
    query GetQuestionWithAnswer($projectId: ID!, $id: ID!) {
  question(projectId: $projectId, id: $id) {
    ...Question
    answer {
      ...Answer
    }
  }
}
    ${QuestionFragmentDoc}
${AnswerFragmentDoc}`;

/**
 * __useGetQuestionWithAnswerQuery__
 *
 * To run a query within a React component, call `useGetQuestionWithAnswerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionWithAnswerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionWithAnswerQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetQuestionWithAnswerQuery(baseOptions: Apollo.QueryHookOptions<GetQuestionWithAnswerQuery, GetQuestionWithAnswerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionWithAnswerQuery, GetQuestionWithAnswerQueryVariables>(GetQuestionWithAnswerDocument, options);
      }
export function useGetQuestionWithAnswerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionWithAnswerQuery, GetQuestionWithAnswerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionWithAnswerQuery, GetQuestionWithAnswerQueryVariables>(GetQuestionWithAnswerDocument, options);
        }
export type GetQuestionWithAnswerQueryHookResult = ReturnType<typeof useGetQuestionWithAnswerQuery>;
export type GetQuestionWithAnswerLazyQueryHookResult = ReturnType<typeof useGetQuestionWithAnswerLazyQuery>;
export type GetQuestionWithAnswerQueryResult = Apollo.QueryResult<GetQuestionWithAnswerQuery, GetQuestionWithAnswerQueryVariables>;
export const GetQuestionWithAnswersDocument = gql`
    query GetQuestionWithAnswers($projectId: ID!, $questionId: ID!) {
  question(projectId: $projectId, id: $questionId) {
    ...Question
    answers {
      ...Answer
      user {
        ...User
      }
      client {
        _id
        email
      }
    }
  }
}
    ${QuestionFragmentDoc}
${AnswerFragmentDoc}
${UserFragmentDoc}`;

/**
 * __useGetQuestionWithAnswersQuery__
 *
 * To run a query within a React component, call `useGetQuestionWithAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionWithAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionWithAnswersQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useGetQuestionWithAnswersQuery(baseOptions: Apollo.QueryHookOptions<GetQuestionWithAnswersQuery, GetQuestionWithAnswersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionWithAnswersQuery, GetQuestionWithAnswersQueryVariables>(GetQuestionWithAnswersDocument, options);
      }
export function useGetQuestionWithAnswersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionWithAnswersQuery, GetQuestionWithAnswersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionWithAnswersQuery, GetQuestionWithAnswersQueryVariables>(GetQuestionWithAnswersDocument, options);
        }
export type GetQuestionWithAnswersQueryHookResult = ReturnType<typeof useGetQuestionWithAnswersQuery>;
export type GetQuestionWithAnswersLazyQueryHookResult = ReturnType<typeof useGetQuestionWithAnswersLazyQuery>;
export type GetQuestionWithAnswersQueryResult = Apollo.QueryResult<GetQuestionWithAnswersQuery, GetQuestionWithAnswersQueryVariables>;
export const CreateAnswerDocument = gql`
    mutation CreateAnswer($projectId: ID!, $questionId: ID!, $input: AnswerInput!) {
  createAnswer(projectId: $projectId, questionId: $questionId, input: $input) {
    ...Answer
  }
}
    ${AnswerFragmentDoc}`;
export type CreateAnswerMutationFn = Apollo.MutationFunction<CreateAnswerMutation, CreateAnswerMutationVariables>;

/**
 * __useCreateAnswerMutation__
 *
 * To run a mutation, you first call `useCreateAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnswerMutation, { data, loading, error }] = useCreateAnswerMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      questionId: // value for 'questionId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAnswerMutation(baseOptions?: Apollo.MutationHookOptions<CreateAnswerMutation, CreateAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAnswerMutation, CreateAnswerMutationVariables>(CreateAnswerDocument, options);
      }
export type CreateAnswerMutationHookResult = ReturnType<typeof useCreateAnswerMutation>;
export type CreateAnswerMutationResult = Apollo.MutationResult<CreateAnswerMutation>;
export type CreateAnswerMutationOptions = Apollo.BaseMutationOptions<CreateAnswerMutation, CreateAnswerMutationVariables>;
export const RemoveAnswerDocument = gql`
    mutation RemoveAnswer($projectId: ID!, $questionId: ID!, $answerId: ID!) {
  removeAnswer(projectId: $projectId, questionId: $questionId, answerId: $answerId) {
    ...Answer
  }
}
    ${AnswerFragmentDoc}`;
export type RemoveAnswerMutationFn = Apollo.MutationFunction<RemoveAnswerMutation, RemoveAnswerMutationVariables>;

/**
 * __useRemoveAnswerMutation__
 *
 * To run a mutation, you first call `useRemoveAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAnswerMutation, { data, loading, error }] = useRemoveAnswerMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      questionId: // value for 'questionId'
 *      answerId: // value for 'answerId'
 *   },
 * });
 */
export function useRemoveAnswerMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAnswerMutation, RemoveAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAnswerMutation, RemoveAnswerMutationVariables>(RemoveAnswerDocument, options);
      }
export type RemoveAnswerMutationHookResult = ReturnType<typeof useRemoveAnswerMutation>;
export type RemoveAnswerMutationResult = Apollo.MutationResult<RemoveAnswerMutation>;
export type RemoveAnswerMutationOptions = Apollo.BaseMutationOptions<RemoveAnswerMutation, RemoveAnswerMutationVariables>;
export const CreateQuestionDocument = gql`
    mutation CreateQuestion($projectId: ID!, $input: QuestionInput!) {
  createQuestion(projectId: $projectId, input: $input) {
    ...Question
  }
}
    ${QuestionFragmentDoc}`;
export type CreateQuestionMutationFn = Apollo.MutationFunction<CreateQuestionMutation, CreateQuestionMutationVariables>;

/**
 * __useCreateQuestionMutation__
 *
 * To run a mutation, you first call `useCreateQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionMutation, { data, loading, error }] = useCreateQuestionMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQuestionMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuestionMutation, CreateQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuestionMutation, CreateQuestionMutationVariables>(CreateQuestionDocument, options);
      }
export type CreateQuestionMutationHookResult = ReturnType<typeof useCreateQuestionMutation>;
export type CreateQuestionMutationResult = Apollo.MutationResult<CreateQuestionMutation>;
export type CreateQuestionMutationOptions = Apollo.BaseMutationOptions<CreateQuestionMutation, CreateQuestionMutationVariables>;
export const UpdateQuestionDocument = gql`
    mutation UpdateQuestion($projectId: ID!, $id: ID!, $input: QuestionInput!) {
  updateQuestion(projectId: $projectId, id: $id, input: $input) {
    ...Question
  }
}
    ${QuestionFragmentDoc}`;
export type UpdateQuestionMutationFn = Apollo.MutationFunction<UpdateQuestionMutation, UpdateQuestionMutationVariables>;

/**
 * __useUpdateQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionMutation, { data, loading, error }] = useUpdateQuestionMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateQuestionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuestionMutation, UpdateQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuestionMutation, UpdateQuestionMutationVariables>(UpdateQuestionDocument, options);
      }
export type UpdateQuestionMutationHookResult = ReturnType<typeof useUpdateQuestionMutation>;
export type UpdateQuestionMutationResult = Apollo.MutationResult<UpdateQuestionMutation>;
export type UpdateQuestionMutationOptions = Apollo.BaseMutationOptions<UpdateQuestionMutation, UpdateQuestionMutationVariables>;
export const GetReactionPeopleDocument = gql`
    query GetReactionPeople($projectId: ID!, $postId: ID!, $reference: String!, $name: String!) {
  reactionPeople(projectId: $projectId, postId: $postId, reference: $reference, name: $name) {
    _id
    people
  }
}
    `;

/**
 * __useGetReactionPeopleQuery__
 *
 * To run a query within a React component, call `useGetReactionPeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReactionPeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReactionPeopleQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      postId: // value for 'postId'
 *      reference: // value for 'reference'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetReactionPeopleQuery(baseOptions: Apollo.QueryHookOptions<GetReactionPeopleQuery, GetReactionPeopleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReactionPeopleQuery, GetReactionPeopleQueryVariables>(GetReactionPeopleDocument, options);
      }
export function useGetReactionPeopleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReactionPeopleQuery, GetReactionPeopleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReactionPeopleQuery, GetReactionPeopleQueryVariables>(GetReactionPeopleDocument, options);
        }
export type GetReactionPeopleQueryHookResult = ReturnType<typeof useGetReactionPeopleQuery>;
export type GetReactionPeopleLazyQueryHookResult = ReturnType<typeof useGetReactionPeopleLazyQuery>;
export type GetReactionPeopleQueryResult = Apollo.QueryResult<GetReactionPeopleQuery, GetReactionPeopleQueryVariables>;
export const GetFeedbackDocument = gql`
    query GetFeedback($projectId: ID!, $postId: ID!, $includeViews: Boolean!) {
  self {
    _id
  }
  role(projectId: $projectId) {
    ...Role
  }
  views(projectId: $projectId, postId: $postId) @include(if: $includeViews) {
    id
    list {
      ...View
    }
  }
  comments(projectId: $projectId, postId: $postId) {
    id
    list {
      ...Comment
    }
  }
}
    ${RoleFragmentDoc}
${ViewFragmentDoc}
${CommentFragmentDoc}`;

/**
 * __useGetFeedbackQuery__
 *
 * To run a query within a React component, call `useGetFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedbackQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      postId: // value for 'postId'
 *      includeViews: // value for 'includeViews'
 *   },
 * });
 */
export function useGetFeedbackQuery(baseOptions: Apollo.QueryHookOptions<GetFeedbackQuery, GetFeedbackQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeedbackQuery, GetFeedbackQueryVariables>(GetFeedbackDocument, options);
      }
export function useGetFeedbackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeedbackQuery, GetFeedbackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeedbackQuery, GetFeedbackQueryVariables>(GetFeedbackDocument, options);
        }
export type GetFeedbackQueryHookResult = ReturnType<typeof useGetFeedbackQuery>;
export type GetFeedbackLazyQueryHookResult = ReturnType<typeof useGetFeedbackLazyQuery>;
export type GetFeedbackQueryResult = Apollo.QueryResult<GetFeedbackQuery, GetFeedbackQueryVariables>;
export const CreateCommentDocument = gql`
    mutation CreateComment($projectId: ID!, $postId: ID!, $input: CommentInput!) {
  createComment(projectId: $projectId, postId: $postId, input: $input) {
    id
    memberId
    clientId
    userId
    content
    postId
  }
}
    `;
export type CreateCommentMutationFn = Apollo.MutationFunction<CreateCommentMutation, CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      postId: // value for 'postId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommentMutation, CreateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommentMutation, CreateCommentMutationVariables>(CreateCommentDocument, options);
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<CreateCommentMutation, CreateCommentMutationVariables>;
export const RemoveCommentDocument = gql`
    mutation RemoveComment($projectId: ID!, $postId: ID!, $commentId: ID!) {
  removeComment(projectId: $projectId, postId: $postId, commentId: $commentId) {
    id
    memberId
    clientId
    userId
    content
    postId
  }
}
    `;
export type RemoveCommentMutationFn = Apollo.MutationFunction<RemoveCommentMutation, RemoveCommentMutationVariables>;

/**
 * __useRemoveCommentMutation__
 *
 * To run a mutation, you first call `useRemoveCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCommentMutation, { data, loading, error }] = useRemoveCommentMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      postId: // value for 'postId'
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useRemoveCommentMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCommentMutation, RemoveCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCommentMutation, RemoveCommentMutationVariables>(RemoveCommentDocument, options);
      }
export type RemoveCommentMutationHookResult = ReturnType<typeof useRemoveCommentMutation>;
export type RemoveCommentMutationResult = Apollo.MutationResult<RemoveCommentMutation>;
export type RemoveCommentMutationOptions = Apollo.BaseMutationOptions<RemoveCommentMutation, RemoveCommentMutationVariables>;
export const GetPostDocument = gql`
    query GetPost($projectId: ID!, $postId: ID!) {
  post(projectId: $projectId, id: $postId) {
    _id
    title
    content
    project
    summary
    date
    createdAt
    updatedAt
    publishedAt
    metadata
    draft
    createdBy {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetPostQuery__
 *
 * To run a query within a React component, call `useGetPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useGetPostQuery(baseOptions: Apollo.QueryHookOptions<GetPostQuery, GetPostQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostQuery, GetPostQueryVariables>(GetPostDocument, options);
      }
export function useGetPostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostQuery, GetPostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostQuery, GetPostQueryVariables>(GetPostDocument, options);
        }
export type GetPostQueryHookResult = ReturnType<typeof useGetPostQuery>;
export type GetPostLazyQueryHookResult = ReturnType<typeof useGetPostLazyQuery>;
export type GetPostQueryResult = Apollo.QueryResult<GetPostQuery, GetPostQueryVariables>;
export const GetDiscussionsDocument = gql`
    query GetDiscussions($projectId: ID!, $postId: ID!) {
  discussions(projectId: $projectId, postId: $postId) {
    _id
    reference
    thread {
      _id
      user {
        ...User
      }
      client {
        _id
        email
      }
      message
      createdAt
    }
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetDiscussionsQuery__
 *
 * To run a query within a React component, call `useGetDiscussionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscussionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscussionsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useGetDiscussionsQuery(baseOptions: Apollo.QueryHookOptions<GetDiscussionsQuery, GetDiscussionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDiscussionsQuery, GetDiscussionsQueryVariables>(GetDiscussionsDocument, options);
      }
export function useGetDiscussionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDiscussionsQuery, GetDiscussionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDiscussionsQuery, GetDiscussionsQueryVariables>(GetDiscussionsDocument, options);
        }
export type GetDiscussionsQueryHookResult = ReturnType<typeof useGetDiscussionsQuery>;
export type GetDiscussionsLazyQueryHookResult = ReturnType<typeof useGetDiscussionsLazyQuery>;
export type GetDiscussionsQueryResult = Apollo.QueryResult<GetDiscussionsQuery, GetDiscussionsQueryVariables>;
export const CreateDiscussionDocument = gql`
    mutation CreateDiscussion($projectId: ID!, $postId: ID!, $input: DiscussionInput!) {
  createDiscussion(projectId: $projectId, postId: $postId, input: $input) {
    _id
    reference
    thread {
      _id
      user {
        ...User
      }
      client {
        _id
        email
      }
      message
      createdAt
    }
  }
}
    ${UserFragmentDoc}`;
export type CreateDiscussionMutationFn = Apollo.MutationFunction<CreateDiscussionMutation, CreateDiscussionMutationVariables>;

/**
 * __useCreateDiscussionMutation__
 *
 * To run a mutation, you first call `useCreateDiscussionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDiscussionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDiscussionMutation, { data, loading, error }] = useCreateDiscussionMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      postId: // value for 'postId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDiscussionMutation(baseOptions?: Apollo.MutationHookOptions<CreateDiscussionMutation, CreateDiscussionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDiscussionMutation, CreateDiscussionMutationVariables>(CreateDiscussionDocument, options);
      }
export type CreateDiscussionMutationHookResult = ReturnType<typeof useCreateDiscussionMutation>;
export type CreateDiscussionMutationResult = Apollo.MutationResult<CreateDiscussionMutation>;
export type CreateDiscussionMutationOptions = Apollo.BaseMutationOptions<CreateDiscussionMutation, CreateDiscussionMutationVariables>;
export const GetReactionsDocument = gql`
    query GetReactions($projectId: ID!, $postId: ID!) {
  reactionGroup(projectId: $projectId, postId: $postId) {
    _id
    reactions {
      ...Reaction
    }
  }
}
    ${ReactionFragmentDoc}`;

/**
 * __useGetReactionsQuery__
 *
 * To run a query within a React component, call `useGetReactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReactionsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useGetReactionsQuery(baseOptions: Apollo.QueryHookOptions<GetReactionsQuery, GetReactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReactionsQuery, GetReactionsQueryVariables>(GetReactionsDocument, options);
      }
export function useGetReactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReactionsQuery, GetReactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReactionsQuery, GetReactionsQueryVariables>(GetReactionsDocument, options);
        }
export type GetReactionsQueryHookResult = ReturnType<typeof useGetReactionsQuery>;
export type GetReactionsLazyQueryHookResult = ReturnType<typeof useGetReactionsLazyQuery>;
export type GetReactionsQueryResult = Apollo.QueryResult<GetReactionsQuery, GetReactionsQueryVariables>;
export const CreateReactionDocument = gql`
    mutation CreateReaction($projectId: ID!, $postId: ID!, $input: ReactionInput!) {
  createReaction(projectId: $projectId, postId: $postId, input: $input) {
    ...Reaction
  }
}
    ${ReactionFragmentDoc}`;
export type CreateReactionMutationFn = Apollo.MutationFunction<CreateReactionMutation, CreateReactionMutationVariables>;

/**
 * __useCreateReactionMutation__
 *
 * To run a mutation, you first call `useCreateReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReactionMutation, { data, loading, error }] = useCreateReactionMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      postId: // value for 'postId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReactionMutation(baseOptions?: Apollo.MutationHookOptions<CreateReactionMutation, CreateReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReactionMutation, CreateReactionMutationVariables>(CreateReactionDocument, options);
      }
export type CreateReactionMutationHookResult = ReturnType<typeof useCreateReactionMutation>;
export type CreateReactionMutationResult = Apollo.MutationResult<CreateReactionMutation>;
export type CreateReactionMutationOptions = Apollo.BaseMutationOptions<CreateReactionMutation, CreateReactionMutationVariables>;
export const RemoveReactionDocument = gql`
    mutation RemoveReaction($projectId: ID!, $postId: ID!, $id: ID!) {
  removeReaction(projectId: $projectId, postId: $postId, id: $id) {
    _id
  }
}
    `;
export type RemoveReactionMutationFn = Apollo.MutationFunction<RemoveReactionMutation, RemoveReactionMutationVariables>;

/**
 * __useRemoveReactionMutation__
 *
 * To run a mutation, you first call `useRemoveReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeReactionMutation, { data, loading, error }] = useRemoveReactionMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      postId: // value for 'postId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveReactionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveReactionMutation, RemoveReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveReactionMutation, RemoveReactionMutationVariables>(RemoveReactionDocument, options);
      }
export type RemoveReactionMutationHookResult = ReturnType<typeof useRemoveReactionMutation>;
export type RemoveReactionMutationResult = Apollo.MutationResult<RemoveReactionMutation>;
export type RemoveReactionMutationOptions = Apollo.BaseMutationOptions<RemoveReactionMutation, RemoveReactionMutationVariables>;
export const GetMetricDocument = gql`
    query GetMetric($projectId: ID!, $metricId: ID) {
  metric(projectId: $projectId, metricId: $metricId) {
    _id
    name
    measures {
      _id
      value
      date
    }
  }
}
    `;

/**
 * __useGetMetricQuery__
 *
 * To run a query within a React component, call `useGetMetricQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetricQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      metricId: // value for 'metricId'
 *   },
 * });
 */
export function useGetMetricQuery(baseOptions: Apollo.QueryHookOptions<GetMetricQuery, GetMetricQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMetricQuery, GetMetricQueryVariables>(GetMetricDocument, options);
      }
export function useGetMetricLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMetricQuery, GetMetricQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMetricQuery, GetMetricQueryVariables>(GetMetricDocument, options);
        }
export type GetMetricQueryHookResult = ReturnType<typeof useGetMetricQuery>;
export type GetMetricLazyQueryHookResult = ReturnType<typeof useGetMetricLazyQuery>;
export type GetMetricQueryResult = Apollo.QueryResult<GetMetricQuery, GetMetricQueryVariables>;
export const UpdateSelfDocument = gql`
    mutation UpdateSelf($input: UserInput!) {
  updateSelf(input: $input) {
    _id
    email
    name
    photo
  }
}
    `;
export type UpdateSelfMutationFn = Apollo.MutationFunction<UpdateSelfMutation, UpdateSelfMutationVariables>;

/**
 * __useUpdateSelfMutation__
 *
 * To run a mutation, you first call `useUpdateSelfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSelfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSelfMutation, { data, loading, error }] = useUpdateSelfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSelfMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSelfMutation, UpdateSelfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSelfMutation, UpdateSelfMutationVariables>(UpdateSelfDocument, options);
      }
export type UpdateSelfMutationHookResult = ReturnType<typeof useUpdateSelfMutation>;
export type UpdateSelfMutationResult = Apollo.MutationResult<UpdateSelfMutation>;
export type UpdateSelfMutationOptions = Apollo.BaseMutationOptions<UpdateSelfMutation, UpdateSelfMutationVariables>;
export const UpdatePhotoDocument = gql`
    mutation UpdatePhoto($input: UserInput!) {
  updateSelf(input: $input) {
    _id
    photo
  }
}
    `;
export type UpdatePhotoMutationFn = Apollo.MutationFunction<UpdatePhotoMutation, UpdatePhotoMutationVariables>;

/**
 * __useUpdatePhotoMutation__
 *
 * To run a mutation, you first call `useUpdatePhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhotoMutation, { data, loading, error }] = useUpdatePhotoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePhotoMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePhotoMutation, UpdatePhotoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePhotoMutation, UpdatePhotoMutationVariables>(UpdatePhotoDocument, options);
      }
export type UpdatePhotoMutationHookResult = ReturnType<typeof useUpdatePhotoMutation>;
export type UpdatePhotoMutationResult = Apollo.MutationResult<UpdatePhotoMutation>;
export type UpdatePhotoMutationOptions = Apollo.BaseMutationOptions<UpdatePhotoMutation, UpdatePhotoMutationVariables>;
export const CreateMilestoneDocument = gql`
    mutation CreateMilestone($projectId: ID!, $input: MilestoneInput!) {
  createMilestone(projectId: $projectId, input: $input) {
    ...Milestone
  }
}
    ${MilestoneFragmentDoc}`;
export type CreateMilestoneMutationFn = Apollo.MutationFunction<CreateMilestoneMutation, CreateMilestoneMutationVariables>;

/**
 * __useCreateMilestoneMutation__
 *
 * To run a mutation, you first call `useCreateMilestoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMilestoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMilestoneMutation, { data, loading, error }] = useCreateMilestoneMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMilestoneMutation(baseOptions?: Apollo.MutationHookOptions<CreateMilestoneMutation, CreateMilestoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMilestoneMutation, CreateMilestoneMutationVariables>(CreateMilestoneDocument, options);
      }
export type CreateMilestoneMutationHookResult = ReturnType<typeof useCreateMilestoneMutation>;
export type CreateMilestoneMutationResult = Apollo.MutationResult<CreateMilestoneMutation>;
export type CreateMilestoneMutationOptions = Apollo.BaseMutationOptions<CreateMilestoneMutation, CreateMilestoneMutationVariables>;
export const UpdateMilestoneDocument = gql`
    mutation UpdateMilestone($projectId: ID!, $id: ID!, $input: MilestoneInput!) {
  updateMilestone(projectId: $projectId, id: $id, input: $input) {
    ...Milestone
  }
}
    ${MilestoneFragmentDoc}`;
export type UpdateMilestoneMutationFn = Apollo.MutationFunction<UpdateMilestoneMutation, UpdateMilestoneMutationVariables>;

/**
 * __useUpdateMilestoneMutation__
 *
 * To run a mutation, you first call `useUpdateMilestoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMilestoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMilestoneMutation, { data, loading, error }] = useUpdateMilestoneMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMilestoneMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMilestoneMutation, UpdateMilestoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMilestoneMutation, UpdateMilestoneMutationVariables>(UpdateMilestoneDocument, options);
      }
export type UpdateMilestoneMutationHookResult = ReturnType<typeof useUpdateMilestoneMutation>;
export type UpdateMilestoneMutationResult = Apollo.MutationResult<UpdateMilestoneMutation>;
export type UpdateMilestoneMutationOptions = Apollo.BaseMutationOptions<UpdateMilestoneMutation, UpdateMilestoneMutationVariables>;
export const GetMilestoneDocument = gql`
    query GetMilestone($projectId: ID!, $id: ID!) {
  milestone(projectId: $projectId, id: $id) {
    ...Milestone
  }
}
    ${MilestoneFragmentDoc}`;

/**
 * __useGetMilestoneQuery__
 *
 * To run a query within a React component, call `useGetMilestoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMilestoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMilestoneQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMilestoneQuery(baseOptions: Apollo.QueryHookOptions<GetMilestoneQuery, GetMilestoneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMilestoneQuery, GetMilestoneQueryVariables>(GetMilestoneDocument, options);
      }
export function useGetMilestoneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMilestoneQuery, GetMilestoneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMilestoneQuery, GetMilestoneQueryVariables>(GetMilestoneDocument, options);
        }
export type GetMilestoneQueryHookResult = ReturnType<typeof useGetMilestoneQuery>;
export type GetMilestoneLazyQueryHookResult = ReturnType<typeof useGetMilestoneLazyQuery>;
export type GetMilestoneQueryResult = Apollo.QueryResult<GetMilestoneQuery, GetMilestoneQueryVariables>;
export const GetMembershipDocument = gql`
    query GetMembership($id: ID!) {
  membership(projectId: $id) {
    _id
    role
  }
}
    `;

/**
 * __useGetMembershipQuery__
 *
 * To run a query within a React component, call `useGetMembershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembershipQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMembershipQuery(baseOptions: Apollo.QueryHookOptions<GetMembershipQuery, GetMembershipQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMembershipQuery, GetMembershipQueryVariables>(GetMembershipDocument, options);
      }
export function useGetMembershipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMembershipQuery, GetMembershipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMembershipQuery, GetMembershipQueryVariables>(GetMembershipDocument, options);
        }
export type GetMembershipQueryHookResult = ReturnType<typeof useGetMembershipQuery>;
export type GetMembershipLazyQueryHookResult = ReturnType<typeof useGetMembershipLazyQuery>;
export type GetMembershipQueryResult = Apollo.QueryResult<GetMembershipQuery, GetMembershipQueryVariables>;
export const GetResourcesDocument = gql`
    query GetResources($projectId: ID!) {
  resources(projectId: $projectId) {
    ...Resource
  }
}
    ${ResourceFragmentDoc}`;

/**
 * __useGetResourcesQuery__
 *
 * To run a query within a React component, call `useGetResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourcesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetResourcesQuery(baseOptions: Apollo.QueryHookOptions<GetResourcesQuery, GetResourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResourcesQuery, GetResourcesQueryVariables>(GetResourcesDocument, options);
      }
export function useGetResourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResourcesQuery, GetResourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResourcesQuery, GetResourcesQueryVariables>(GetResourcesDocument, options);
        }
export type GetResourcesQueryHookResult = ReturnType<typeof useGetResourcesQuery>;
export type GetResourcesLazyQueryHookResult = ReturnType<typeof useGetResourcesLazyQuery>;
export type GetResourcesQueryResult = Apollo.QueryResult<GetResourcesQuery, GetResourcesQueryVariables>;
export const GetMetricDetailsDocument = gql`
    query GetMetricDetails($projectId: ID!, $metricId: ID!) {
  metric(projectId: $projectId, metricId: $metricId) {
    _id
    name
    measures {
      _id
      value
      date
    }
  }
}
    `;

/**
 * __useGetMetricDetailsQuery__
 *
 * To run a query within a React component, call `useGetMetricDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetricDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetricDetailsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      metricId: // value for 'metricId'
 *   },
 * });
 */
export function useGetMetricDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetMetricDetailsQuery, GetMetricDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMetricDetailsQuery, GetMetricDetailsQueryVariables>(GetMetricDetailsDocument, options);
      }
export function useGetMetricDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMetricDetailsQuery, GetMetricDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMetricDetailsQuery, GetMetricDetailsQueryVariables>(GetMetricDetailsDocument, options);
        }
export type GetMetricDetailsQueryHookResult = ReturnType<typeof useGetMetricDetailsQuery>;
export type GetMetricDetailsLazyQueryHookResult = ReturnType<typeof useGetMetricDetailsLazyQuery>;
export type GetMetricDetailsQueryResult = Apollo.QueryResult<GetMetricDetailsQuery, GetMetricDetailsQueryVariables>;
export const InviteGuestDocument = gql`
    mutation InviteGuest($projectId: ID!, $input: ClientInput!) {
  createClient(projectId: $projectId, input: $input) {
    _id
    clients {
      _id
      email
    }
    invites {
      _id
      email
    }
  }
}
    `;
export type InviteGuestMutationFn = Apollo.MutationFunction<InviteGuestMutation, InviteGuestMutationVariables>;

/**
 * __useInviteGuestMutation__
 *
 * To run a mutation, you first call `useInviteGuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteGuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteGuestMutation, { data, loading, error }] = useInviteGuestMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteGuestMutation(baseOptions?: Apollo.MutationHookOptions<InviteGuestMutation, InviteGuestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteGuestMutation, InviteGuestMutationVariables>(InviteGuestDocument, options);
      }
export type InviteGuestMutationHookResult = ReturnType<typeof useInviteGuestMutation>;
export type InviteGuestMutationResult = Apollo.MutationResult<InviteGuestMutation>;
export type InviteGuestMutationOptions = Apollo.BaseMutationOptions<InviteGuestMutation, InviteGuestMutationVariables>;
export const RemoveClientDocument = gql`
    mutation RemoveClient($projectId: ID!, $id: ID!) {
  removeClient(projectId: $projectId, id: $id) {
    _id
  }
}
    `;
export type RemoveClientMutationFn = Apollo.MutationFunction<RemoveClientMutation, RemoveClientMutationVariables>;

/**
 * __useRemoveClientMutation__
 *
 * To run a mutation, you first call `useRemoveClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClientMutation, { data, loading, error }] = useRemoveClientMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveClientMutation(baseOptions?: Apollo.MutationHookOptions<RemoveClientMutation, RemoveClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveClientMutation, RemoveClientMutationVariables>(RemoveClientDocument, options);
      }
export type RemoveClientMutationHookResult = ReturnType<typeof useRemoveClientMutation>;
export type RemoveClientMutationResult = Apollo.MutationResult<RemoveClientMutation>;
export type RemoveClientMutationOptions = Apollo.BaseMutationOptions<RemoveClientMutation, RemoveClientMutationVariables>;
export const GetClientsDocument = gql`
    query GetClients($id: ID!) {
  project(id: $id) {
    _id
    clients {
      _id
      email
      user {
        ...User
      }
    }
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetClientsQuery__
 *
 * To run a query within a React component, call `useGetClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientsQuery(baseOptions: Apollo.QueryHookOptions<GetClientsQuery, GetClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientsQuery, GetClientsQueryVariables>(GetClientsDocument, options);
      }
export function useGetClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientsQuery, GetClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientsQuery, GetClientsQueryVariables>(GetClientsDocument, options);
        }
export type GetClientsQueryHookResult = ReturnType<typeof useGetClientsQuery>;
export type GetClientsLazyQueryHookResult = ReturnType<typeof useGetClientsLazyQuery>;
export type GetClientsQueryResult = Apollo.QueryResult<GetClientsQuery, GetClientsQueryVariables>;
export const GetProjectSettingsDocument = gql`
    query GetProjectSettings($id: ID!) {
  project(id: $id) {
    _id
    name
    description
    updatedAt
    themeColor
    logo
  }
}
    `;

/**
 * __useGetProjectSettingsQuery__
 *
 * To run a query within a React component, call `useGetProjectSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectSettingsQuery(baseOptions: Apollo.QueryHookOptions<GetProjectSettingsQuery, GetProjectSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectSettingsQuery, GetProjectSettingsQueryVariables>(GetProjectSettingsDocument, options);
      }
export function useGetProjectSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectSettingsQuery, GetProjectSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectSettingsQuery, GetProjectSettingsQueryVariables>(GetProjectSettingsDocument, options);
        }
export type GetProjectSettingsQueryHookResult = ReturnType<typeof useGetProjectSettingsQuery>;
export type GetProjectSettingsLazyQueryHookResult = ReturnType<typeof useGetProjectSettingsLazyQuery>;
export type GetProjectSettingsQueryResult = Apollo.QueryResult<GetProjectSettingsQuery, GetProjectSettingsQueryVariables>;
export const UpdateProjectDocument = gql`
    mutation UpdateProject($id: ID!, $input: ProjectUpdateInput!) {
  updateProject(id: $id, input: $input) {
    _id
    name
    description
    updatedAt
    themeColor
    logo
  }
}
    `;
export type UpdateProjectMutationFn = Apollo.MutationFunction<UpdateProjectMutation, UpdateProjectMutationVariables>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument, options);
      }
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<UpdateProjectMutation, UpdateProjectMutationVariables>;
export const RemoveProjectDocument = gql`
    mutation RemoveProject($id: ID!) {
  removeProject(id: $id) {
    _id
    archivedAt
  }
}
    `;
export type RemoveProjectMutationFn = Apollo.MutationFunction<RemoveProjectMutation, RemoveProjectMutationVariables>;

/**
 * __useRemoveProjectMutation__
 *
 * To run a mutation, you first call `useRemoveProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProjectMutation, { data, loading, error }] = useRemoveProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveProjectMutation(baseOptions?: Apollo.MutationHookOptions<RemoveProjectMutation, RemoveProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveProjectMutation, RemoveProjectMutationVariables>(RemoveProjectDocument, options);
      }
export type RemoveProjectMutationHookResult = ReturnType<typeof useRemoveProjectMutation>;
export type RemoveProjectMutationResult = Apollo.MutationResult<RemoveProjectMutation>;
export type RemoveProjectMutationOptions = Apollo.BaseMutationOptions<RemoveProjectMutation, RemoveProjectMutationVariables>;
export const InviteMemberDocument = gql`
    mutation InviteMember($projectId: ID!, $email: String!) {
  inviteMember(projectId: $projectId, email: $email) {
    _id
    invites {
      _id
      email
    }
    members {
      _id
      role
      user {
        ...User
      }
    }
  }
}
    ${UserFragmentDoc}`;
export type InviteMemberMutationFn = Apollo.MutationFunction<InviteMemberMutation, InviteMemberMutationVariables>;

/**
 * __useInviteMemberMutation__
 *
 * To run a mutation, you first call `useInviteMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteMemberMutation, { data, loading, error }] = useInviteMemberMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useInviteMemberMutation(baseOptions?: Apollo.MutationHookOptions<InviteMemberMutation, InviteMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteMemberMutation, InviteMemberMutationVariables>(InviteMemberDocument, options);
      }
export type InviteMemberMutationHookResult = ReturnType<typeof useInviteMemberMutation>;
export type InviteMemberMutationResult = Apollo.MutationResult<InviteMemberMutation>;
export type InviteMemberMutationOptions = Apollo.BaseMutationOptions<InviteMemberMutation, InviteMemberMutationVariables>;
export const GetTeamDocument = gql`
    query GetTeam($id: ID!) {
  project(id: $id) {
    _id
    members {
      ...Member
    }
    invites {
      _id
      email
    }
  }
}
    ${MemberFragmentDoc}`;

/**
 * __useGetTeamQuery__
 *
 * To run a query within a React component, call `useGetTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTeamQuery(baseOptions: Apollo.QueryHookOptions<GetTeamQuery, GetTeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamQuery, GetTeamQueryVariables>(GetTeamDocument, options);
      }
export function useGetTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamQuery, GetTeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamQuery, GetTeamQueryVariables>(GetTeamDocument, options);
        }
export type GetTeamQueryHookResult = ReturnType<typeof useGetTeamQuery>;
export type GetTeamLazyQueryHookResult = ReturnType<typeof useGetTeamLazyQuery>;
export type GetTeamQueryResult = Apollo.QueryResult<GetTeamQuery, GetTeamQueryVariables>;
export const RemoveMemberDocument = gql`
    mutation RemoveMember($projectId: ID!, $id: ID!) {
  removeMember(projectId: $projectId, id: $id) {
    _id
    members {
      ...Member
    }
  }
}
    ${MemberFragmentDoc}`;
export type RemoveMemberMutationFn = Apollo.MutationFunction<RemoveMemberMutation, RemoveMemberMutationVariables>;

/**
 * __useRemoveMemberMutation__
 *
 * To run a mutation, you first call `useRemoveMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMemberMutation, { data, loading, error }] = useRemoveMemberMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveMemberMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMemberMutation, RemoveMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveMemberMutation, RemoveMemberMutationVariables>(RemoveMemberDocument, options);
      }
export type RemoveMemberMutationHookResult = ReturnType<typeof useRemoveMemberMutation>;
export type RemoveMemberMutationResult = Apollo.MutationResult<RemoveMemberMutation>;
export type RemoveMemberMutationOptions = Apollo.BaseMutationOptions<RemoveMemberMutation, RemoveMemberMutationVariables>;
export const RemoveInviteDocument = gql`
    mutation RemoveInvite($projectId: ID!, $id: ID!) {
  removeInvite(projectId: $projectId, id: $id) {
    _id
  }
}
    `;
export type RemoveInviteMutationFn = Apollo.MutationFunction<RemoveInviteMutation, RemoveInviteMutationVariables>;

/**
 * __useRemoveInviteMutation__
 *
 * To run a mutation, you first call `useRemoveInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeInviteMutation, { data, loading, error }] = useRemoveInviteMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveInviteMutation(baseOptions?: Apollo.MutationHookOptions<RemoveInviteMutation, RemoveInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveInviteMutation, RemoveInviteMutationVariables>(RemoveInviteDocument, options);
      }
export type RemoveInviteMutationHookResult = ReturnType<typeof useRemoveInviteMutation>;
export type RemoveInviteMutationResult = Apollo.MutationResult<RemoveInviteMutation>;
export type RemoveInviteMutationOptions = Apollo.BaseMutationOptions<RemoveInviteMutation, RemoveInviteMutationVariables>;
export const GetTeammateDocument = gql`
    query GetTeammate($projectId: ID!, $teammateId: ID!) {
  teammate(projectId: $projectId, teammateId: $teammateId) {
    ...Teammate
  }
}
    ${TeammateFragmentDoc}`;

/**
 * __useGetTeammateQuery__
 *
 * To run a query within a React component, call `useGetTeammateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeammateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeammateQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      teammateId: // value for 'teammateId'
 *   },
 * });
 */
export function useGetTeammateQuery(baseOptions: Apollo.QueryHookOptions<GetTeammateQuery, GetTeammateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeammateQuery, GetTeammateQueryVariables>(GetTeammateDocument, options);
      }
export function useGetTeammateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeammateQuery, GetTeammateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeammateQuery, GetTeammateQueryVariables>(GetTeammateDocument, options);
        }
export type GetTeammateQueryHookResult = ReturnType<typeof useGetTeammateQuery>;
export type GetTeammateLazyQueryHookResult = ReturnType<typeof useGetTeammateLazyQuery>;
export type GetTeammateQueryResult = Apollo.QueryResult<GetTeammateQuery, GetTeammateQueryVariables>;
export const RemoveTeammateDocument = gql`
    mutation RemoveTeammate($projectId: ID!, $teammateId: ID!) {
  removeTeammate(projectId: $projectId, teammateId: $teammateId) {
    ...Teammate
  }
}
    ${TeammateFragmentDoc}`;
export type RemoveTeammateMutationFn = Apollo.MutationFunction<RemoveTeammateMutation, RemoveTeammateMutationVariables>;

/**
 * __useRemoveTeammateMutation__
 *
 * To run a mutation, you first call `useRemoveTeammateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTeammateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTeammateMutation, { data, loading, error }] = useRemoveTeammateMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      teammateId: // value for 'teammateId'
 *   },
 * });
 */
export function useRemoveTeammateMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTeammateMutation, RemoveTeammateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTeammateMutation, RemoveTeammateMutationVariables>(RemoveTeammateDocument, options);
      }
export type RemoveTeammateMutationHookResult = ReturnType<typeof useRemoveTeammateMutation>;
export type RemoveTeammateMutationResult = Apollo.MutationResult<RemoveTeammateMutation>;
export type RemoveTeammateMutationOptions = Apollo.BaseMutationOptions<RemoveTeammateMutation, RemoveTeammateMutationVariables>;
export const GetTeammatesDocument = gql`
    query GetTeammates($projectId: ID!) {
  teammates(projectId: $projectId) {
    ...Teammate
  }
}
    ${TeammateFragmentDoc}`;

/**
 * __useGetTeammatesQuery__
 *
 * To run a query within a React component, call `useGetTeammatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeammatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeammatesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetTeammatesQuery(baseOptions: Apollo.QueryHookOptions<GetTeammatesQuery, GetTeammatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeammatesQuery, GetTeammatesQueryVariables>(GetTeammatesDocument, options);
      }
export function useGetTeammatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeammatesQuery, GetTeammatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeammatesQuery, GetTeammatesQueryVariables>(GetTeammatesDocument, options);
        }
export type GetTeammatesQueryHookResult = ReturnType<typeof useGetTeammatesQuery>;
export type GetTeammatesLazyQueryHookResult = ReturnType<typeof useGetTeammatesLazyQuery>;
export type GetTeammatesQueryResult = Apollo.QueryResult<GetTeammatesQuery, GetTeammatesQueryVariables>;
export const CreateTeammateDocument = gql`
    mutation CreateTeammate($projectId: ID!, $input: TeammateInput!) {
  createTeammate(projectId: $projectId, input: $input) {
    ...Teammate
  }
}
    ${TeammateFragmentDoc}`;
export type CreateTeammateMutationFn = Apollo.MutationFunction<CreateTeammateMutation, CreateTeammateMutationVariables>;

/**
 * __useCreateTeammateMutation__
 *
 * To run a mutation, you first call `useCreateTeammateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeammateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeammateMutation, { data, loading, error }] = useCreateTeammateMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTeammateMutation(baseOptions?: Apollo.MutationHookOptions<CreateTeammateMutation, CreateTeammateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTeammateMutation, CreateTeammateMutationVariables>(CreateTeammateDocument, options);
      }
export type CreateTeammateMutationHookResult = ReturnType<typeof useCreateTeammateMutation>;
export type CreateTeammateMutationResult = Apollo.MutationResult<CreateTeammateMutation>;
export type CreateTeammateMutationOptions = Apollo.BaseMutationOptions<CreateTeammateMutation, CreateTeammateMutationVariables>;
export const UpdateTeammateDocument = gql`
    mutation UpdateTeammate($projectId: ID!, $teammateId: ID!, $input: TeammateInput!) {
  updateTeammate(projectId: $projectId, teammateId: $teammateId, input: $input) {
    ...Teammate
  }
}
    ${TeammateFragmentDoc}`;
export type UpdateTeammateMutationFn = Apollo.MutationFunction<UpdateTeammateMutation, UpdateTeammateMutationVariables>;

/**
 * __useUpdateTeammateMutation__
 *
 * To run a mutation, you first call `useUpdateTeammateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeammateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeammateMutation, { data, loading, error }] = useUpdateTeammateMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      teammateId: // value for 'teammateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeammateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeammateMutation, UpdateTeammateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeammateMutation, UpdateTeammateMutationVariables>(UpdateTeammateDocument, options);
      }
export type UpdateTeammateMutationHookResult = ReturnType<typeof useUpdateTeammateMutation>;
export type UpdateTeammateMutationResult = Apollo.MutationResult<UpdateTeammateMutation>;
export type UpdateTeammateMutationOptions = Apollo.BaseMutationOptions<UpdateTeammateMutation, UpdateTeammateMutationVariables>;
export const RemoveMilestoneDocument = gql`
    mutation RemoveMilestone($projectId: ID!, $id: ID!) {
  removeMilestone(projectId: $projectId, id: $id) {
    _id
  }
}
    `;
export type RemoveMilestoneMutationFn = Apollo.MutationFunction<RemoveMilestoneMutation, RemoveMilestoneMutationVariables>;

/**
 * __useRemoveMilestoneMutation__
 *
 * To run a mutation, you first call `useRemoveMilestoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMilestoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMilestoneMutation, { data, loading, error }] = useRemoveMilestoneMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveMilestoneMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMilestoneMutation, RemoveMilestoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveMilestoneMutation, RemoveMilestoneMutationVariables>(RemoveMilestoneDocument, options);
      }
export type RemoveMilestoneMutationHookResult = ReturnType<typeof useRemoveMilestoneMutation>;
export type RemoveMilestoneMutationResult = Apollo.MutationResult<RemoveMilestoneMutation>;
export type RemoveMilestoneMutationOptions = Apollo.BaseMutationOptions<RemoveMilestoneMutation, RemoveMilestoneMutationVariables>;
export const RemovePostDocument = gql`
    mutation RemovePost($projectId: ID!, $id: ID!) {
  removePost(projectId: $projectId, id: $id)
}
    `;
export type RemovePostMutationFn = Apollo.MutationFunction<RemovePostMutation, RemovePostMutationVariables>;

/**
 * __useRemovePostMutation__
 *
 * To run a mutation, you first call `useRemovePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePostMutation, { data, loading, error }] = useRemovePostMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemovePostMutation(baseOptions?: Apollo.MutationHookOptions<RemovePostMutation, RemovePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemovePostMutation, RemovePostMutationVariables>(RemovePostDocument, options);
      }
export type RemovePostMutationHookResult = ReturnType<typeof useRemovePostMutation>;
export type RemovePostMutationResult = Apollo.MutationResult<RemovePostMutation>;
export type RemovePostMutationOptions = Apollo.BaseMutationOptions<RemovePostMutation, RemovePostMutationVariables>;
export const GetPostsDocument = gql`
    query GetPosts($id: ID!) {
  project(id: $id) {
    _id
    name
    createdAt
    milestones {
      ...Milestone
    }
    posts {
      ...Post
    }
  }
}
    ${MilestoneFragmentDoc}
${PostFragmentDoc}`;

/**
 * __useGetPostsQuery__
 *
 * To run a query within a React component, call `useGetPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPostsQuery(baseOptions: Apollo.QueryHookOptions<GetPostsQuery, GetPostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostsQuery, GetPostsQueryVariables>(GetPostsDocument, options);
      }
export function useGetPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostsQuery, GetPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostsQuery, GetPostsQueryVariables>(GetPostsDocument, options);
        }
export type GetPostsQueryHookResult = ReturnType<typeof useGetPostsQuery>;
export type GetPostsLazyQueryHookResult = ReturnType<typeof useGetPostsLazyQuery>;
export type GetPostsQueryResult = Apollo.QueryResult<GetPostsQuery, GetPostsQueryVariables>;
export const CreateProjectDocument = gql`
    mutation CreateProject($input: ProjectInput!) {
  createProject(input: $input) {
    _id
    name
    logo
    archivedAt
    createdAt
    themeColor
  }
}
    `;
export type CreateProjectMutationFn = Apollo.MutationFunction<CreateProjectMutation, CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, options);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<CreateProjectMutation, CreateProjectMutationVariables>;
export const GetProjectsDocument = gql`
    query GetProjects {
  projects {
    _id
    name
    logo
    themeColor
    role
    createdAt
    createdBy {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetProjectsQuery__
 *
 * To run a query within a React component, call `useGetProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectsQuery(baseOptions?: Apollo.QueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, options);
      }
export function useGetProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, options);
        }
export type GetProjectsQueryHookResult = ReturnType<typeof useGetProjectsQuery>;
export type GetProjectsLazyQueryHookResult = ReturnType<typeof useGetProjectsLazyQuery>;
export type GetProjectsQueryResult = Apollo.QueryResult<GetProjectsQuery, GetProjectsQueryVariables>;
export const ResetPasswordRequestDocument = gql`
    mutation ResetPasswordRequest($email: String!) {
  requestResetPassword(email: $email)
}
    `;
export type ResetPasswordRequestMutationFn = Apollo.MutationFunction<ResetPasswordRequestMutation, ResetPasswordRequestMutationVariables>;

/**
 * __useResetPasswordRequestMutation__
 *
 * To run a mutation, you first call `useResetPasswordRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordRequestMutation, { data, loading, error }] = useResetPasswordRequestMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordRequestMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordRequestMutation, ResetPasswordRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordRequestMutation, ResetPasswordRequestMutationVariables>(ResetPasswordRequestDocument, options);
      }
export type ResetPasswordRequestMutationHookResult = ReturnType<typeof useResetPasswordRequestMutation>;
export type ResetPasswordRequestMutationResult = Apollo.MutationResult<ResetPasswordRequestMutation>;
export type ResetPasswordRequestMutationOptions = Apollo.BaseMutationOptions<ResetPasswordRequestMutation, ResetPasswordRequestMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($token: String!, $password: String!) {
  resetPassword(token: $token, password: $password)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ResendVerificationEmailDocument = gql`
    mutation ResendVerificationEmail {
  resendVerificationEmail
}
    `;
export type ResendVerificationEmailMutationFn = Apollo.MutationFunction<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>;

/**
 * __useResendVerificationEmailMutation__
 *
 * To run a mutation, you first call `useResendVerificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendVerificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendVerificationEmailMutation, { data, loading, error }] = useResendVerificationEmailMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendVerificationEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>(ResendVerificationEmailDocument, options);
      }
export type ResendVerificationEmailMutationHookResult = ReturnType<typeof useResendVerificationEmailMutation>;
export type ResendVerificationEmailMutationResult = Apollo.MutationResult<ResendVerificationEmailMutation>;
export type ResendVerificationEmailMutationOptions = Apollo.BaseMutationOptions<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>;