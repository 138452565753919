import Icon from "components/Icon"
import { Row } from "components/layout"
import { forwardRef } from "react"

import styles from "./Tag.module.scss"

export interface TagProps {
  tooltip?: string
  icon?: string
  text: string
  color?: string[]
  style?: any
}

export const TagColors = {
  orange: ["#fff5e6", "#ff9800"]
}

export const Tag = forwardRef<HTMLDivElement, TagProps>(
  ({ color, icon, text, style = {} }, ref) => (
    <div
      className={styles.tag}
      style={{
        backgroundColor: color?.[0],
        color: color?.[1],
        ...style
      }}
      ref={ref}
    >
      <Row distance={2}>
        {icon ? <Icon name={icon} bold /> : null}
        <div className={styles.text}>{text}</div>
      </Row>
    </div>
  )
)
