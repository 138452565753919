import { AUTHORIZATION } from "api"
import decode from "jwt-decode"

export interface AccessTokenClaim {
  internal_id: string
  exp: number
  iat: number
  email: string
}

export const decodeCachedIdToken = () => {
  const token = localStorage.getItem(AUTHORIZATION)

  if (!token) {
    return null
  }

  try {
    return decode<AccessTokenClaim>(token)
  } catch (error) {
    return null
  }
}
