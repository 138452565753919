import { NodeViewProps, NodeViewWrapper } from "@tiptap/react"
import Zoom from "react-medium-image-zoom"

import { Reactable } from "../Reactable"

import "react-medium-image-zoom/dist/styles.css"
import styles from "./ReadonlyImageComponent.module.scss"

export const ReadonlyImageComponent = ({ node }: NodeViewProps) => {
  const { assetId, id, src, width, height } = node.attrs

  return (
    <Reactable node={node}>
      <NodeViewWrapper
        data-asset-id={assetId}
        data-node-id={id}
        contentEditable="false"
        suppressContentEditableWarning
        className={styles.wrapper}
      >
        <Zoom zoomMargin={60}>
          <div
            className={styles.image}
            style={{
              width,
              height,
              backgroundImage: `url("${src}")`
            }}
          />
        </Zoom>
      </NodeViewWrapper>
    </Reactable>
  )
}
