import { ModalProvider } from "components/Modal"
import { ReactNode } from "react"

import { Navigation } from "./Navigation"

import styles from "./Window.module.scss"

export interface WindowProps {
  children: ReactNode
}

export const Window = ({ children }: WindowProps) => (
  <div className={styles.window}>
    <ModalProvider>
      <Navigation />
    </ModalProvider>
    <div className={styles.content}>{children}</div>
  </div>
)
