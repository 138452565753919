import { Commands } from "./Commands"
import getSuggestionItems from "./items"
import renderItems from "./renderItems"

export const CommandsMenu = Commands.configure({
  suggestion: {
    items: getSuggestionItems,
    render: renderItems
  }
})
