import { Row } from "components/layout"
import cn from "classnames"

import styles from "./Tabs.module.scss"

const TabButton = ({ active, label, onClick }: any) => {
  return (
    <div
      className={cn(styles.button, active && styles.active)}
      onClick={() => onClick()}
    >
      {label}
    </div>
  )
}

export interface TabsProps {
  options: {
    label: string
    value: string
  }[]

  value: string
  onChange: (value: string) => void
}

export const Tabs = ({ options, value, onChange }: TabsProps) => {
  return (
    <Row className={styles.buttons}>
      {options.map((option, index) => {
        return (
          <TabButton
            key={index}
            label={option.label}
            active={value === option.value}
            onClick={() => onChange(option.value)}
          />
        )
      })}
    </Row>
  )
}
