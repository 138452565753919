export const upload = async (accept: string): Promise<File | undefined> => {
  const el = document.createElement("input")

  console.log(accept)

  el.type = "file"
  el.accept = accept
  el.style.cssText = "display: none"
  // el.multiple = "multiple"; // remove to have a single file selection

  // (cancel will not trigger 'change')
  return new Promise((resolve, reject) => {
    el.addEventListener("change", function listener(event) {
      const file = el?.files?.[0]
      // el.files
      // ev;
      // access el.files[] to do something with it (test its length!)

      // add first image, if available
      // if (file) {
      // const src = URL.createObjectURL(file);
      // }

      el.removeEventListener("change", listener)
      el.remove()

      resolve(file)
    })

    el.addEventListener("blur", (event) => {
      console.log(el.files?.length)
    })

    el.addEventListener("focus", (event) => {
      console.log(el.files?.length)
    })

    el.click()
  })
}
