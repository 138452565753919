import { ReactNode, HTMLAttributes, forwardRef } from "react"
import cn from "classnames"

import styles from "./Stack.module.scss"

export interface StackProps extends HTMLAttributes<HTMLDivElement> {
  fill?: boolean
  distance?: number
  children: ReactNode
}

export const Stack = forwardRef<HTMLDivElement, StackProps>(
  ({ className, fill, children, distance = 0, ...props }) => (
    <div
      className={cn(
        styles.stack,
        className,
        fill && styles.fill,
        `distance-vertical-${distance}`
      )}
      {...props}
    >
      {children}
    </div>
  )
)
