import cn from "classnames"
import Icon from "components/Icon"
import { Row } from "components/layout"
import { Text } from "components/Text"
import { SimpleTooltip } from "components/Tooltip"
import { ReactNode } from "react"
import styles from "./HeaderButton.module.scss"

export interface HeaderButtonProps {
  icon?: string
  text?: string
  width?: number
  active?: boolean
  tooltip?: string
  children?: ReactNode
  onClick?: () => void
}

export const HeaderButton = ({
  icon,
  text,
  active,
  tooltip,
  children,
  width,
  onClick
}: HeaderButtonProps) => (
  <SimpleTooltip message={tooltip}>
    <div
      className={cn(styles.button, active && styles.active)}
      style={{ width }}
      onClick={onClick}
    >
      <Row distance={2}>
        {icon && <Icon name={icon} />}
        {text && <Text>{text}</Text>}
        {children}
      </Row>
    </div>
  </SimpleTooltip>
)
