import Tippy from "@tippyjs/react"
import { BlockPicker } from "react-color"

import { Row, Split } from "components/layout"
import { SVGLogo } from "components/SVGLogo"
import { Text } from "components/Text"

import styles from "./ColorPicker.module.scss"

const colors = [
  "#2f2f2f",
  "#697689",
  "#dce775",
  "#00d4ff",
  "#ff8a65",
  "#efb681",
  "#37d67a",
  "#8095ff",
  "#2749f1",
  "#ff7eae"
]

export interface ColorPickerProps {
  value: string
  onChange: (color: string) => void
}

export const ColorPicker = ({ value, onChange }: ColorPickerProps) => (
  <div className={styles.input}>
    <Tippy
      placement="top"
      trigger="click"
      interactive
      content={
        <div className={styles.container}>
          <BlockPicker
            colors={colors}
            color={value}
            triangle="hide"
            onChange={(color) => onChange(color.hex)}
          />
        </div>
      }
    >
      <Split>
        <div className={styles.placeholder}>Theme color</div>

        <Row distance={6}>
          <Text>{value}</Text>
          <SVGLogo color={value} />
        </Row>
      </Split>
    </Tippy>
  </div>
)
