import { ReactNode, forwardRef } from "react"
import cn from "classnames"

import Icon from "components/Icon"

import styles from "./Popover.module.scss"

export interface PopoverProps {
  width?: number
  style?: any
  children: ReactNode
}

export const Popover = forwardRef<HTMLDivElement, PopoverProps>(
  ({ width, children, style = {} }, ref) => (
    <div ref={ref} className={styles.popover} style={{ width, ...style }}>
      {children}
    </div>
  )
)

export interface PopoverListItemProps {
  active?: boolean
  icon?: string
  style?: any
  className?: string
  children?: ReactNode
  onClick: () => void
}

export const PopoverListItem = forwardRef<HTMLDivElement, PopoverListItemProps>(
  ({ active, children, onClick, style, className, icon }, ref) => (
    <div
      ref={ref}
      className={cn(styles.item, className, active && styles.active)}
      onClick={onClick}
      style={style}
    >
      {icon && <Icon name={icon} />}
      {children}
    </div>
  )
)
