import { Button } from "routes/Project/components/Button"
import { FormStack, Row, Split, Stack } from "components/layout"
import {
  cancelButtonSpec,
  confirmButtonSpec,
  ModalProps,
  useModal
} from "components/Modal"
import { Text } from "components/Text"
import { Formik, Form, FieldArray } from "formik"
import {
  useCreateQuestionMutation,
  useGetQuestionQuery,
  useUpdateQuestionMutation
} from "graphql/types"
import { Label } from "components/Label"
import Spinner from "components/Spinner"
import Dialog, { Element, Footer, Title } from "components/Modal/Dialog"
import { validationSchema } from "./validationSchema"
import { FormTextarea } from "components/Textarea"
import { FormSelect } from "components/Select"

const defaultOptions = [
  {
    _id: null,
    message: ""
  },
  {
    _id: null,
    message: ""
  }
]

export const EditQuestion = ({
  className,
  attributes,
  ...props
}: ModalProps) => {
  const modal = useModal()
  const node = attributes?.node

  const updateAttributes = attributes?.updateAttributes

  const questionId = node?.attrs?.questionId
  const projectId = attributes?.projectId

  const { data, loading } = useGetQuestionQuery({
    variables: {
      projectId,
      id: questionId
    },
    skip: !questionId
  })

  const question = data?.question

  const [updateQuestion] = useUpdateQuestionMutation()
  const [createQuestion] = useCreateQuestionMutation()

  return (
    <Dialog {...props}>
      <Title message="Question" />

      {loading ? (
        <Spinner />
      ) : (
        <Formik
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={validationSchema}
          initialValues={{
            type: question?.type || "multiple_choice",
            message: question?.message || "",
            options: question?.options || defaultOptions
          }}
          onSubmit={async (values) => {
            const message = values.message

            const options =
              values.type === "open_ended"
                ? []
                : values.options.map((option) => {
                    return {
                      _id: option._id || null,
                      message: option.message || ""
                    }
                  })

            const type = values.type

            if (questionId) {
              await updateQuestion({
                variables: {
                  projectId,
                  id: questionId,
                  input: {
                    message,
                    options,
                    type
                  }
                }
              })
            } else {
              const { data } = await createQuestion({
                variables: {
                  projectId,
                  input: {
                    message,
                    options,
                    type
                  }
                }
              })

              updateAttributes({
                questionId: data?.createQuestion._id
              })
            }

            modal.hide()
          }}
        >
          {(props) => (
            <Form>
              <Element>
                <FormStack>
                  <FormTextarea
                    allowNewline={false}
                    label="Question"
                    placeholder="Enter your question"
                    maxRows={6}
                    name="message"
                  />

                  <FormSelect
                    label="Type"
                    options={[
                      {
                        label: "Poll",
                        value: "multiple_choice"
                      },
                      {
                        label: "Free text question",
                        value: "open_ended"
                      }
                    ]}
                    name="type"
                  />

                  {props.values.type === "multiple_choice" && (
                    <Label message="Answer options">
                      <FieldArray name="options">
                        {(helpers) => (
                          <Stack distance={8}>
                            <Stack distance={4}>
                              {props.values.options?.map((option, index) => (
                                <Row
                                  key={index}
                                  distance={4}
                                  vertical="baseline"
                                >
                                  <FormTextarea
                                    allowNewline={false}
                                    name={`options.${index}.message`}
                                    placeholder={`Choice #${index + 1}`}
                                  />

                                  <Button
                                    tabIndex={-1}
                                    disabled={index < 2}
                                    secondary
                                    onClick={() => helpers.remove(index)}
                                  >
                                    Remove
                                  </Button>
                                </Row>
                              ))}
                            </Stack>

                            <Split>
                              <Text color="var(--secondary-text-color)">
                                * At least two choice options are required
                              </Text>

                              <Row horizontal="right">
                                <Button
                                  type="button"
                                  secondary
                                  onClick={() => helpers.push({ message: "" })}
                                >
                                  Add choices
                                </Button>
                              </Row>
                            </Split>
                          </Stack>
                        )}
                      </FieldArray>
                    </Label>
                  )}
                </FormStack>
              </Element>

              <Footer
                buttons={[
                  cancelButtonSpec(() => {
                    modal.hide()
                  }),
                  confirmButtonSpec()
                ]}
              />
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  )
}
