import {
  useCreateCommentMutation,
  useGetFeedbackQuery,
  useRemoveCommentMutation
} from "graphql/types"
import { useProject } from "providers/Project"
import { useIDs } from "routes/Editor/Editor/EditorStore"
import { useState } from "react"

import { Comments } from "./Comments"
import { Header } from "./Header"
import { Views } from "./Views"

import styles from "./Feedback.module.scss"

export const Feedback = () => {
  const { authenticated, clientId } = useProject()
  const { projectId, postId } = useIDs()

  const [tab, setTab] = useState("comments")

  const [createComment] = useCreateCommentMutation()
  const [removeComment] = useRemoveCommentMutation()

  const enableViews = authenticated && !clientId

  const { loading, data, refetch } = useGetFeedbackQuery({
    variables: {
      projectId,
      postId,
      includeViews: enableViews
    }
  })

  const commentsCount = data?.comments?.list.length ?? 0
  const viewsCount = data?.views?.list.length ?? 0

  return loading ? null : (
    <div className={styles.container}>
      <Header
        enableViews={enableViews}
        loading={loading}
        viewsCount={viewsCount}
        commentsCount={commentsCount}
        value={tab}
        onChange={setTab}
      />

      {data && tab === "views" && <Views list={data.views?.list ?? []} />}
      {data && tab === "comments" && (
        <Comments
          role={data.role}
          list={data.comments?.list ?? []}
          onSubmit={async (content) => {
            await createComment({
              variables: {
                projectId,
                postId,
                input: {
                  content
                }
              }
            })

            await refetch()
          }}
          onDelete={async (commentId) => {
            await removeComment({
              variables: {
                projectId,
                postId,
                commentId
              }
            })

            await refetch()
          }}
        />
      )}
    </div>
  )
}
