import config from "config"

import { initializeApp } from "firebase/app"
import { getAuth, GoogleAuthProvider, updateEmail } from "firebase/auth"

export const app = initializeApp(config.firebase)
export const auth = getAuth(app)
export const googleAuthProvider = new GoogleAuthProvider()
// @ts-ignore
window.auth = auth
// @ts-ignore
window.updateEmail = updateEmail
