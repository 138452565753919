import { Layout } from "./Layout"
import { Router } from "./Router"

export const Project = () => (
  <Layout>
    <Router />
  </Layout>
)

export default Project
