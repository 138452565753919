import { Editor } from "@tiptap/react"
import { useUpdatePostMutation } from "graphql/types"
import { throttle } from "lodash"
import { ReactNode, useEffect, useRef } from "react"
import { useEditorStore } from "."

export interface EditorStoreProps {
  children: ReactNode
  editor: Editor
}

export const useSynchronizer = (
  projectId: string,
  postId: string,
  editor: Editor
) => {
  const { dispatch, state } = useEditorStore()
  const [updatePost] = useUpdatePostMutation()

  const update = useRef(
    throttle(async (input) => {
      const update = async () => {
        dispatch({
          type: "startSync"
        })

        await updatePost({
          variables: {
            projectId,
            postId,
            input
          }
        })

        dispatch({
          type: "finishSync"
        })

        console.log("> Content synchronized", input)
      }

      update()
    }, 400)
  )
  /**
   * Call function which send data to the servicer (function itself is throttled)
   */
  useEffect(() => {
    if (state.ready) {
      update.current({
        title: state.title,
        content: state.content,
        metadata: {
          titleBackgroundColor: state.titleColor
        }
      })
    }
  }, [state.ready, state.title, state.content, state.titleColor])
  /**
   * Update store when editor content changes
   */
  useEffect(() => {
    const onUpdate = ({ editor }: any) => {
      dispatch({
        type: "setContent",
        value: editor.getJSON()
      })
    }

    editor.on("update", onUpdate)

    return () => {
      editor.off("update", onUpdate)
    }
  }, [dispatch, editor])
}
