import toast from "react-hot-toast"

import { useSelf } from "hooks/useSelf"
import { SingleBoxLayout } from "layouts/SingleBoxLayout"
import { useUpdateSelfMutation } from "graphql/types"

import { ProfileForm } from "./ProfileForm"

const Profile = () => {
  const { self } = useSelf()
  const [mutate] = useUpdateSelfMutation()

  return (
    <SingleBoxLayout title="Profile" maxWidth={700}>
      {self && (
        <ProfileForm
          initialValues={{
            name: self.name ?? "",
            email: self.email ?? "",
            photo: self.photo ?? ""
          }}
          onSubmit={async ({ photo, email, ...values }) => {
            try {
              await mutate({
                variables: {
                  input: {
                    ...values
                  }
                }
              })

              toast("🙌 Profile has been updated")
            } catch (error) {}
          }}
        />
      )}
    </SingleBoxLayout>
  )
}

export default Profile
