import { Option } from "components/Menu"

import styles from "./Select.module.scss"

export interface SelectProps {
  value: string
  options: Option[]
  onChange: (value: string) => void
}

export const Select = ({ value, onChange, options }: SelectProps) => (
  <select
    className={styles.select}
    onChange={(event) => onChange(event.target.value)}
    value={value}
  >
    {options.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
  </select>
)
