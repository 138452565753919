import localhost from "./localhost"
import io from "./shareback.com"
import xyz from "./shareback.xyz"

console.log(`Environment: ${process.env.NODE_ENV}`)

let config = io

if (window.location.host.endsWith("localhost:3000")) {
  config = localhost
}

if (window.location.host.endsWith("shareback.xyz")) {
  config = xyz
}

export default config
