import { Node, mergeAttributes } from "@tiptap/core"
import { ReactNodeViewRenderer } from "@tiptap/react"
import { replaceIfInEmptyParagraph } from "../utils"

import { EditorFileComponent } from "./EditorFileComponent"
import { ViewerFileComponent } from "./ViewerFileComponent"

export interface FileOptions {
  HTMLAttributes: Record<string, any>
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    file: {
      /**
       * Add a file
       */
      setFileInfo: (options: {
        assetId?: string
        src: string
        name?: string
        type?: string
        size?: string | number
      }) => ReturnType

      setFile: (file: File) => ReturnType
    }
  }
}

export const File = Node.create<FileOptions>({
  name: "file",
  inline: false,
  group: "block",
  atom: true,
  selectable: true,

  addAttributes() {
    return {
      assetId: {
        default: null,
        parseHTML: (element) => {
          return {
            src: element.getAttribute("data-asset-id")
          }
        },
        renderHTML: (attributes) => {
          return {
            "data-asset-id": attributes.assetId
          }
        }
      },

      src: {
        default: null,
        parseHTML: (element) => {
          return {
            src: element.getAttribute("data-src")
          }
        },
        renderHTML: (attributes) => {
          return {
            "data-src": attributes.src
          }
        }
      },

      name: {
        default: null,
        parseHTML: (element) => {
          return {
            name: element.getAttribute("data-name")
          }
        },
        renderHTML: (attributes) => {
          return {
            "data-name": attributes.name
          }
        }
      },

      type: {
        default: null,
        parseHTML: (element) => {
          return {
            name: element.getAttribute("data-type")
          }
        },

        renderHTML: (attributes) => {
          return {
            "data-type": attributes.type
          }
        }
      },

      size: {
        default: 0,
        parseHTML: (element) => {
          return {
            size: element.getAttribute("data-size")
          }
        },
        renderHTML: (attributes) => {
          return {
            "data-size": attributes.size
          }
        }
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: "file"
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "file",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)
    ]
  },

  addNodeView() {
    return ReactNodeViewRenderer(
      this.editor.isEditable ? EditorFileComponent : ViewerFileComponent
    )
  },

  addCommands() {
    return {
      setFileInfo: (options) => {
        return ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options
          })
        }
      },

      setFile: (file: File) => {
        return (commands) => {
          return (
            replaceIfInEmptyParagraph(commands, this.name, {
              name: file.name,
              type: file.type,
              size: file.size
            }) || true
          )
        }
      }
    }
  }
})
