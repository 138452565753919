import Switch from "react-switch"

export interface ToggleProps {
  value: boolean
  onChange: (v: boolean) => void
}

export const Toggle = ({ value, onChange }: ToggleProps) => (
  <Switch
    onChange={onChange}
    height={20}
    width={34}
    activeBoxShadow="0"
    checkedIcon={false}
    offColor="#b3c2d3"
    onColor="#52d86a"
    uncheckedIcon={false}
    checked={value}
    className="react-switch"
  />
)
