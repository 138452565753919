import cn from "classnames"
import { HashIcon } from "components/HashIcon"
import { Row } from "components/layout"
import { Text } from "components/Text"
import { useProject } from "providers/Project"
import { useHistory, useLocation } from "react-router-dom"
import styles from "./HeaderLogo.module.scss"

export const HeaderLogo = () => {
  const location = useLocation()
  const history = useHistory()

  const { project } = useProject()

  return (
    <div
      className={cn(styles.component, styles.link)}
      onClick={async () => {
        history.push(`/projects/${project._id}/updates${location.search}`)
      }}
    >
      <Row distance={4}>
        {/* <Icon name="ri-arrow-left-s-line" /> */}

        <HashIcon
          size="small"
          color="var(--theme-color)"
          value={project.name}
          url={project.logo || ""}
        />

        <Text className={styles.title}>{project.name}</Text>
      </Row>
    </div>
  )
}
