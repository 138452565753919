import React from "react"

import { MenuList, Option } from "../Menu"
import Button from "../Button"

import Dropdown from "./Dropdown"
import { isFn } from "utils/function"

export interface DropdownSelectProps {
  fill?: boolean
  icon?: string
  use?: "primary" | "secondary" | "tertiary"
  size?: "small" | "medium"
  loading?: boolean
  disabled?: boolean
  style?: any
  scrollable?: boolean
  className?: string
  label?: string | ((value: any) => string)
  onChange: (v: any) => void
  options: Option[]
  placeholder?: string
  value: any
}

const DropdownSelect = ({
  icon,
  use,
  fill,
  options,
  size,
  scrollable,
  style,
  loading,
  disabled,
  label: labelProp,
  placeholder,
  className,
  value,
  onChange
}: DropdownSelectProps) => {
  const selected = options.find((v) => v.value === value)

  const label = labelProp ?? selected?.label ?? placeholder ?? " "
  const labelValue = isFn(label) ? label(value) : label

  return (
    <Dropdown
      style={{
        width: fill ? "100%" : undefined,
        pointerEvents: disabled ? "none" : undefined
      }}
      menu={(isOpen, setOpen) => (
        <MenuList
          scrollable={scrollable}
          items={options}
          onClick={(option, index, event) => {
            onChange(option.value)
            setOpen(!isOpen)

            event.stopPropagation()
          }}
        />
      )}
    >
      {(isOpen) => (
        <Button
          use={use}
          icon={icon}
          disabled={disabled}
          loading={loading}
          type="button"
          size={size}
          style={style}
          className={className}
        >
          {labelValue ? labelValue : null}
        </Button>
      )}
    </Dropdown>
  )
}

export default DropdownSelect
