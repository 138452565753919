import cn from "classnames"

import { Row } from "components/layout"
import Icon from "components/Icon"

import styles from "./Button.module.scss"
import { ButtonHTMLAttributes, forwardRef, ReactNode } from "react"
import { Link } from "react-router-dom"

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: string
  fill?: boolean
  size?: "medium" | "large"
  children?: ReactNode
  link?: string
  use?: "primary" | "secondary" | "green" | "amber" | "red"
  secondary?: boolean
  square?: boolean
  loading?: boolean
}

const ButtonComponent = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      secondary = false,
      square = false,
      icon,
      use,
      size = "medium",
      fill,
      loading,
      children,
      disabled,
      className,
      color,
      style,
      type = "button",
      ...rest
    },
    ref
  ) => (
    <button
      ref={ref}
      disabled={loading || disabled}
      className={cn(
        styles.button,
        secondary && styles.secondary,
        square && styles.square,
        disabled && styles.disabled,
        loading && styles.loading,
        fill && styles.fill,
        use && styles[use],
        styles[size],
        className
      )}
      type={type}
      style={{ backgroundColor: color, ...style }}
      {...rest}
    >
      <Row distance={2} vertical="center">
        {icon && <Icon bold name={icon} />}
        {children && <div className={styles.children}>{children}</div>}
      </Row>
    </button>
  )
)

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ link, onClick, ...props }, ref) => {
    return link ? (
      <Link className={styles.link} to={link}>
        <ButtonComponent ref={ref} {...props} />
      </Link>
    ) : (
      <ButtonComponent ref={ref} {...props} onClick={onClick} />
    )
  }
)
