import { Node, mergeAttributes } from "@tiptap/core"
import { ReactNodeViewRenderer } from "@tiptap/react"

import { EditorQuestionComponent } from "./EditorQuestionComponent"
import { ViewerQuestionComponent } from "./ViewerQuestionComponent"

export interface QuestionOptions {
  HTMLAttributes: Record<string, any>
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    question: {
      /**
       * Set a statement node
       */
      setQuestion: () => ReturnType
    }
  }
}

export const Question = Node.create<QuestionOptions>({
  name: "question",

  group: "block",
  defining: true,
  atom: true,

  addAttributes() {
    return {
      questionId: {
        type: null
      },

      reference: {
        type: null
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: "question"
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ["question", mergeAttributes(HTMLAttributes), 0]
  },

  addNodeView() {
    return ReactNodeViewRenderer(
      this.editor.isEditable ? EditorQuestionComponent : ViewerQuestionComponent
    )
  },

  addCommands() {
    return {
      setQuestion:
        () =>
        ({ commands }) => {
          return commands.insertContent({
            type: "question",
            content: []
          })
        }
    }
  }
})
