import { useGetSelfQuery } from "graphql/types"

export const useSelf = () => {
  const query = useGetSelfQuery()
  const self = query?.data?.self ?? null

  return { self }
}

export default useSelf
