import { object, string } from "yup"

export const validationSchema = object().shape({
  metricId: string().default(""),

  name: string()
    .trim()
    .max(100, "Name is too long")
    .when("metricId", {
      is: (value: string) => {
        return !value
      },
      then: string().required("Name is a required field")
    }),

  value: string().required("Value is a required field")
})
