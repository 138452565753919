import { Milestone } from "components/Milestone"
import { useModal } from "components/Modal"
import { MilestoneFragment, useRemoveMilestoneMutation } from "graphql/types"

export interface EventMilestoneProps {
  projectId: string
  data: MilestoneFragment
}

export const EventMilestone = ({ projectId, data }: EventMilestoneProps) => {
  const modal = useModal()
  const [remove] = useRemoveMilestoneMutation()

  return (
    <Milestone
      emoji={data.emoji}
      name={data.createdBy.name}
      onEdit={() => {
        modal.show("create-milestone", {
          milestoneId: data._id
        })
      }}
      onRemove={() => {
        const confirmation = window.confirm(
          "Are you sure you want to remove this milestone?"
        )

        if (!confirmation) {
          return
        }

        remove({
          variables: {
            projectId,
            id: data._id
          },
          update(cache, { data }) {
            const milestone = data?.removeMilestone

            if (milestone) {
              const id = cache.identify(milestone)

              if (id) {
                cache.evict({ id })
                cache.gc()
              }
            }
          }
        })
      }}
    >
      {data.description}
    </Milestone>
  )
}
