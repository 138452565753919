import Input from "components/Input"
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "./styles.scss"

export const Datepicker = (props: ReactDatePickerProps) => (
  <div>
    <ReactDatePicker
      showYearDropdown
      showPopperArrow={false}
      scrollableYearDropdown
      yearDropdownItemNumber={20}
      customInput={<Input fill />}
      {...props}
    />
  </div>
)
