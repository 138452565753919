import { Route, Switch } from "react-router-dom"
import { Editor } from "routes/Editor"
import { ProjectDataProvider } from "providers/Project/Project"
import Authenticate from "routes/Authenticate"
import CreateAccount from "routes/CreateAccount"
import Post from "routes/Post"
import Profile from "routes/Profile"
import Project from "routes/Project"
import Projects from "routes/Projects"
import Reset from "routes/Reset"
import ResetToken from "routes/ResetToken"
import Root from "routes/Root"
import { Window } from "components/Window/Window"
import { VerifyEmail } from "routes/VerifyEmail"

import { ActiveRoute } from "./ActiveRoute"

export const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Root} />
      <Route exact path="/authenticate" component={Authenticate} />
      <Route exact path="/verify" component={VerifyEmail} />
      <Route exact path="/register" component={CreateAccount} />
      <Route exact path="/reset" component={Reset} />
      <Route exact path="/reset/:token" component={ResetToken} />

      <Window>
        <ActiveRoute exact path="/projects" component={Projects} />

        <Route path="/projects/:projectId">
          <ProjectDataProvider>
            <Switch>
              <ActiveRoute
                exact
                path="/projects/:projectId/posts/:postId"
                component={Post}
              />

              <ActiveRoute
                exact
                path="/projects/:id/editor/:postId"
                component={Editor}
              />

              <ActiveRoute path="/projects/:id/:page" component={Project} />
            </Switch>
          </ProjectDataProvider>
        </Route>

        <ActiveRoute path="/profile" component={Profile} />
      </Window>
    </Switch>
  )
}
