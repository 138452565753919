import React, { ReactNode, HTMLAttributes } from "react"

import useDropdown from "./useDropdown"

import styles from "./Dropdown.module.scss"

export interface DropdownElementProps
  extends Pick<HTMLAttributes<HTMLDivElement>, "className" | "style"> {
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  children: ReactNode | ((open: boolean, setOpen: Function) => ReactNode)
  menu: ReactNode | ((open: boolean, setOpen: Function) => ReactNode)
}
/**
 * Dropdown container; both props can be functions.
 */
export const DropdownElement = ({
  children,
  menu,
  onClick,
  ...props
}: DropdownElementProps) => {
  const [isOpen, setOpen, ref] = useDropdown()

  return (
    <div className={styles.container} ref={ref} {...props}>
      <span
        onClick={(event) => {
          event.stopPropagation()
          setOpen(!isOpen)

          onClick?.(event)
        }}
      >
        {typeof children === "function" ? children(isOpen, setOpen) : children}
      </span>
      {isOpen && (typeof menu === "function" ? menu(isOpen, setOpen) : menu)}
    </div>
  )
}

export default DropdownElement
