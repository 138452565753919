import { Row, Stack } from "components/layout"
import { Textarea } from "components/Textarea"
import { useState } from "react"
import { Button } from "routes/Project/components"

import styles from "./CommentsEditor.module.scss"

export interface CommentsEditorProps {
  onSubmit: (text: string) => void | Promise<void>
}

export const CommentsEditor = ({ onSubmit }: CommentsEditorProps) => {
  const [focused, setFocused] = useState(false)
  const [value, setValue] = useState("")

  const filled = !!value
  const active = focused || filled

  return (
    <Stack distance={6} className={styles.container}>
      <Textarea
        className={styles.textarea}
        value={value}
        minRows={active ? 2 : 1}
        placeholder="Write a comment..."
        onChange={(event) => {
          setValue(event.target.value)
        }}
        onFocus={() => {
          setFocused(true)
        }}
        onBlur={() => {
          setFocused(false)
        }}
      />
      {active && (
        <Row horizontal="right">
          <Button
            className={styles.button}
            onClick={async () => {
              const $value = value.trim()

              if ($value.length > 0) {
                await onSubmit($value)
              }

              setValue("")
            }}
          >
            Add Comment
          </Button>
        </Row>
      )}
    </Stack>
  )
}
