import { NodeViewProps } from "@tiptap/react"
import Icon from "components/Icon"

import { getIcon, sizable } from "./utils"

import styles from "./FileComponent.module.scss"
import { EditorNodeWithControls } from "../EditorNode"

interface EditorFileComponentProps extends NodeViewProps {}

export const EditorFileComponent = (props: EditorFileComponentProps) => {
  const { node } = props
  const { name, size, type, assetId, src } = node.attrs

  return (
    <EditorNodeWithControls {...props}>
      <div
        className={styles.file}
        data-name="file"
        data-asset-id={assetId}
        data-src={src}
        data-size={size}
      >
        <div className={styles.icon}>
          <Icon size={20} name={getIcon(type)} />
        </div>
        <div className={styles.content}>
          <div className={styles.title}>{name}</div>
          <div className={styles.size}>{sizable(size)}</div>
        </div>
      </div>
    </EditorNodeWithControls>
  )
}
