import {
  useCreateDiscussionMutation,
  useGetDiscussionsQuery
} from "graphql/types"
import { groupBy } from "lodash"
import { useProject } from "providers/Project"

import { GET_DISCUSSIONS } from "./operations"

export const useDiscussionsQuery = (projectId: string, postId: string) => {
  const { data } = useGetDiscussionsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      projectId,
      postId
    }
  })

  const discussions = data?.discussions ?? []

  return groupBy(discussions, "reference")
}

export const useDiscussions = (postId: string) => {
  const { projectId } = useProject()

  const [mutate] = useCreateDiscussionMutation({
    refetchQueries: [
      {
        query: GET_DISCUSSIONS,
        variables: {
          projectId,
          postId
        }
      }
    ]
  })

  const discussions = useDiscussionsQuery(projectId, postId)

  const createDiscussion = async (reference: string, message: string) => {
    return mutate({
      variables: {
        projectId,
        postId,
        input: {
          message,
          reference
        }
      }
    })
  }

  return {
    discussions,
    createDiscussion
  } as const
}
