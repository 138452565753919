import { DropdownSelect } from "../Dropdown"
import Option from "./Option"

import styles from "./KebabMenu.module.scss"
export interface KebabMenuProps {
  options: Option[]
  onSelect: (option: Option["value"]) => void
}

export const KebabMenu = ({ options, onSelect }: KebabMenuProps) => (
  <DropdownSelect
    value={null}
    size="medium"
    use="secondary"
    icon="ri-more-2-fill"
    label=""
    className={styles.button}
    options={options}
    onChange={onSelect}
  />
)
