import { NodeViewProps, NodeViewWrapper } from "@tiptap/react"
import cn from "classnames"
import { useGetQuestionQuery } from "graphql/types"

import { useModal } from "components/Modal"
import { Row, Stack } from "components/layout"
import { numberToLetters } from "utils/string"
import { useProject } from "providers/Project"
import { Text } from "components/Text"

import { EditorNodeWithControls } from "../EditorNode"

import styles from "./QuestionComponent.module.scss"

interface EditorQuestionComponentProps extends NodeViewProps {}

export const EditorQuestionComponent = (
  props: EditorQuestionComponentProps
) => {
  const { node, updateAttributes, deleteNode } = props
  const { projectId } = useProject()
  const { questionId } = node.attrs

  const modal = useModal()

  const { data } = useGetQuestionQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      projectId,
      id: questionId
    },
    skip: !questionId
  })

  const question = data?.question

  if (!questionId) {
    return (
      <NodeViewWrapper contentEditable={false}>
        <Stack
          className={cn(styles.container, styles.addButton)}
          distance={2}
          role="button"
          onClick={() => {
            modal.show("editor::edit-question", {
              projectId,
              node,
              updateAttributes,
              deleteNode
            })
          }}
        >
          <div>
            <Text bold>Add question</Text>
          </div>
          <div className={styles.description}>
            Click to create a new question
          </div>
        </Stack>
      </NodeViewWrapper>
    )
  }

  return (
    <EditorNodeWithControls {...props}>
      {question && (
        <div className={cn(styles.container, question.type)}>
          <div className={styles.question}>{question?.message}</div>

          {question.type === "multiple_choice" && (
            <div className={styles.answers}>
              {question?.options.map((option, index) => (
                <div className={styles.answer} key={option._id}>
                  <Row distance={2}>
                    <div>
                      <div className={styles.icon}>
                        {numberToLetters(index)}
                      </div>
                    </div>
                    <div className={styles.message}>{option.message}</div>
                  </Row>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </EditorNodeWithControls>
  )
}
