import { useHistory, useRouteMatch } from "react-router"
import qs from "qs"

import { Row, Split } from "components/layout"
import { HeaderLogo } from "components/HeaderLogo"
import { HeaderButton } from "components/HeaderButton"

import { Button } from "../components"
import { useProject } from "providers/Project"

import styles from "./Header.module.scss"

export const Header = () => {
  const history = useHistory()
  const context = useProject()

  const route = useRouteMatch<{ page?: string }>()
  const page = route.params.page ?? ""

  const showPreviewButton = ["updates", "assets", "metrics", "team"].includes(
    page
  )

  const showBackButton = !showPreviewButton

  const goToPreview = () => {
    const query = qs.stringify(
      { clientId: context.clientId, preview: true },
      { addQueryPrefix: true, skipNulls: true }
    )

    window.open(`/projects/${context.projectId}/updates${query}`)
  }

  return (
    <div className={styles.header}>
      <Split>
        <div>{showBackButton && <HeaderLogo />}</div>

        {context.preview ? (
          <div className={styles.previewLabel}>PREVIEW</div>
        ) : (
          context.role === "member" && (
            <Row distance={10} edges>
              {showPreviewButton && (
                <Button style={{ width: 80 }} onClick={goToPreview}>
                  Preview
                </Button>
              )}

              <HeaderButton
                text="Settings"
                active={
                  route.path === "/projects/:id/:page" &&
                  route.params.page === "settings"
                }
                onClick={() => {
                  history.push(`/projects/${context.projectId}/settings`)
                }}
              />
            </Row>
          )
        )}
      </Split>
    </div>
  )
}
