import { gql } from "@apollo/client"
import { USER_FRAGMENT } from "graphql/fragments"

export const POST_FRAGMENT = gql`
  fragment Post on Post {
    _id
    summary
    title
    project
    createdAt
    updatedAt
    publishedAt
    date
    views

    resources {
      asset {
        _id
        mimeType
        url
      }
    }

    createdBy {
      ...User
    }
  }

  ${USER_FRAGMENT}
`

export const MILESTONE_FRAGMENT = gql`
  fragment Milestone on Milestone {
    _id
    emoji
    date
    description
    createdAt
    createdBy {
      ...User
    }
  }

  ${USER_FRAGMENT}
`

export const GET_POSTS = gql`
  query GetPosts($id: ID!) {
    project(id: $id) {
      _id
      name
      createdAt

      milestones {
        ...Milestone
      }

      posts {
        ...Post
      }
    }
  }

  ${POST_FRAGMENT}
  ${MILESTONE_FRAGMENT}
`

export const REMOVE_POST = gql`
  mutation RemovePost($projectId: ID!, $id: ID!) {
    removePost(projectId: $projectId, id: $id)
  }
`
