import { Row } from "components/layout"
import cn from "classnames"

import styles from "./Tabs.module.scss"

interface TabProps {
  active: boolean
  label: string
  onClick: () => void
}

interface Option {
  label: string
  value: string
}

export interface TabsProps {
  options: Option[]
  value: string
  onChange: (value: string) => void
}

const Tab = ({ active, label, onClick }: TabProps) => (
  <div className={cn(styles.button, active && styles.active)} onClick={onClick}>
    {label}
  </div>
)

export const Tabs = ({ options, value, onChange }: TabsProps) => (
  <Row className={styles.buttons}>
    {options.map((option, index) => (
      <Tab
        key={index}
        label={option.label}
        active={value === option.value}
        onClick={() => onChange(option.value)}
      />
    ))}
  </Row>
)
