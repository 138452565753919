import { IReducer } from "./types"

export const reducer: IReducer = (state, action) => {
  switch (action.type) {
    case "initialize":
      return {
        ...state,
        ...action.value,
        ready: true
      }

    case "startSync": {
      return {
        ...state,
        saving: true
      }
    }

    case "finishSync": {
      return {
        ...state,
        saving: false,
        savedAt: new Date()
      }
    }

    case "setTitleFocused": {
      return {
        ...state,
        titleFocused: action.value
      }
    }

    case "setInitialTitle":
      return {
        ...state,
        initialTitle: action.value
      }

    case "setInitialContent": {
      return {
        ...state,
        initialContent: action.value
      }
    }
    case "setTitle":
      return {
        ...state,
        title: action.value
      }

    case "setTitleColor":
      return {
        ...state,
        titleColor: action.value
      }

    case "setContent":
      return {
        ...state,
        content: action.value
      }
  }
}
