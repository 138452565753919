import { forwardRef } from "react"

export interface SVGLogoProps {
  color: string
  size?: number
  onClick?: () => void
}

export const SVGLogo = forwardRef<SVGSVGElement, SVGLogoProps>(
  ({ size = 24, color, onClick }, ref) => (
    <svg
      ref={ref}
      onClick={onClick}
      width={size}
      height={size}
      viewBox="0 0 37 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.6952 0C7.6754 0 6.0381 1.63736 6.0381 3.65714V24.3123C5.9258 26.558 5.3069 28.3753 4.1813 29.7643C3.136 31.0515 1.7739 31.7968 0.0951996 32H6.0381H7.8666H33.3238C35.3436 32 36.9809 30.3626 36.9809 28.3429V3.65714C36.9809 1.63736 35.3436 0 33.3238 0H9.6952Z"
      />
    </svg>
  )
)
