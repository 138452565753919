import Card from "components/Card"
import Spinner from "components/Spinner"
import { EmptyMessage } from "components/EmptyMessage/EmptyMessage"
import { useProject } from "providers/Project"
import { useGetResourcesQuery } from "graphql/types"

import { AssetItem } from "./AssetItem"

import styles from "./Assets.module.scss"

const Assets = () => {
  const { projectId } = useProject()

  const { data, loading } = useGetResourcesQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      projectId
    }
  })

  const resources = data?.resources ?? []

  return (
    <Card>
      {!loading && resources.length === 0 && (
        <EmptyMessage message="Images and files will appear here" />
      )}

      {loading && !resources && <Spinner />}

      <div className={styles.assets}>
        {resources
          .filter((resource) => resource?.asset.mimeType)
          .map(({ name, asset, _id }) => (
            <AssetItem
              key={_id}
              name={name || asset.name}
              extension={asset.extension ?? ""}
              mime={asset.mimeType ?? ""}
              url={asset.url}
              size={asset.size ?? 0}
              createdAt={asset.createdAt}
            />
          ))}
      </div>
    </Card>
  )
}

export default Assets
