import cn from "classnames"

import { Split, Stack } from "components/layout"
import { formatFriendlyDate } from "utils/date"

import { ProjectImage } from "./ProjectImage"

import styles from "./Project.module.scss"

export const Project = ({
  id,
  name,
  logo,
  themeColor,
  createdAt,
  createdBy,
  onClick
}: any) => {
  const date = formatFriendlyDate(new Date(createdAt))

  return (
    <div
      className={cn(styles.project)}
      style={{
        // @ts-ignore - css variable
        "--theme-color": themeColor
      }}
    >
      <div onClick={() => onClick(id)}>
        <ProjectImage logo={logo} />

        <div className={styles.footer}>
          <Stack distance={1}>
            <Split>
              <div className={styles.title}>{name}</div>
            </Split>
            <Split>
              <div className={styles.subtext}>
                {createdBy.name} · {date}
              </div>
            </Split>
          </Stack>
        </div>
      </div>
    </div>
  )
}
