import cn from "classnames"
import { Editor } from "@tiptap/core"
import Icon from "components/Icon"

import styles from "./Button.module.scss"
import { SimpleTooltip } from "components/Tooltip"

export interface ButtonProps {
  editor: Editor | null
  icon: string
  mark: any
  disabled?: boolean
  markAttributes?: Record<string, unknown>
  onClick: () => void
  tooltip: string
}

export const Button = ({
  editor,
  icon,
  disabled,
  mark,
  markAttributes,
  onClick,
  tooltip
}: ButtonProps) => (
  <SimpleTooltip placement="bottom" message={tooltip}>
    <button
      tabIndex={-1}
      onClick={() => onClick()}
      className={cn(
        styles.button,
        disabled
          ? styles.disabled
          : mark && editor?.isActive(mark, markAttributes)
          ? styles.active
          : null
      )}
    >
      <Icon className={styles.icon} name={icon} />
    </button>
  </SimpleTooltip>
)
