import styles from "./HeroFrame.module.scss"

export const HeroFrame = ({ children, toolbar }: any) => {
  return (
    <div className={styles.component}>
      <div className={styles.frame}>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  )
}
