import { Stack } from "components/layout"

import { ThreadMessage } from "./ThreadMessage"
import { ThreadReply } from "./ThreadReply"

import styles from "./Thread.module.scss"

export interface ThreadProps {
  messages: {
    _id: string
    message: string
    createdAt: string
    user?: {
      name: string
    }
    client?: {
      email: string
    }
  }[]

  onMessage: (value: string) => void | Promise<void>
}

export const Thread = ({ messages, onMessage }: ThreadProps) => (
  <div className={styles.thread}>
    <Stack distance={6}>
      {messages?.map((comment: any) => (
        <ThreadMessage
          key={comment._id}
          message={comment.message}
          user={comment.user}
          client={comment.client}
          createdAt={comment.createdAt}
        />
      ))}

      <ThreadReply onSubmit={onMessage} />
    </Stack>
  </div>
)
