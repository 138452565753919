import { useAuthState } from "features/auth"
import { BrowserRouter } from "react-router-dom"

import { Routes } from "./Routes"

export const Router = () => {
  const [, loading] = useAuthState()

  return <BrowserRouter>{loading ? null : <Routes />}</BrowserRouter>
}
