import { useState } from "react"

import { Textarea } from "components/Textarea"
import { Split, Stack } from "components/layout"
import { Button } from "routes/Project/components"

import styles from "./ThreadReply.module.scss"

export interface ThreadReplyProps {
  onSubmit: (message: string) => void | Promise<void>
}

export const ThreadReply = ({ onSubmit }: ThreadReplyProps) => {
  const [value, setValue] = useState("")
  const [loading, setLoading] = useState(false)

  const submit = async () => {
    const trimmed = value.trim()

    setLoading(true)

    if (trimmed.length > 0) {
      try {
        await onSubmit(trimmed)
        setValue("")
      } catch (error) {
        console.log(error)
      }
    }

    setLoading(false)
  }

  return (
    <Stack distance={6} className={styles.component}>
      <div>
        <Textarea
          autoFocus
          value={value}
          maxLength={300}
          showMaxLength
          onChange={(event) => {
            setValue(event.target.value)
          }}
          minRows={2}
          maxRows={10}
          placeholder="Message..."
          className={styles.textarea}
          onKeyDown={(event) => {
            if (event.code === "Enter" && event.metaKey) {
              submit()
            }
          }}
        />
      </div>
      <Split>
        <div />

        <div>
          <Button loading={loading} onClick={() => submit()}>
            Comment
          </Button>
        </div>
      </Split>
    </Stack>
  )
}
