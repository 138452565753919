import { HashIcon } from "components/HashIcon"
import { Row, Stack } from "components/layout"
import { Text } from "components/Text"
import { formatDistanceToNow } from "date-fns"
import { ViewFragment } from "graphql/types"

export interface ViewsProps {
  list: ViewFragment[]
}

export const Views = ({ list }: ViewsProps) => {
  return (
    <Stack distance={8}>
      <Stack distance={4}>
        {list.map((view) => {
          const name = view.client.user?.name ?? view.client.email

          return (
            <Row distance={6} key={view.id}>
              <div>
                <HashIcon
                  size={32}
                  value={name}
                  url={view.client.user?.photo}
                />
              </div>
              <Stack distance={2}>
                <Text>{name}</Text>
                <Text size={12} color={"var(--secondary-text-color"}>
                  {formatDistanceToNow(new Date(view.createdAt), {
                    addSuffix: true
                  })}
                </Text>
              </Stack>
            </Row>
          )
        })}
      </Stack>
    </Stack>
  )
}
