import { NodeViewProps, NodeViewWrapper } from "@tiptap/react"
import { Size } from "re-resizable"
import { isNil, omitBy } from "lodash"
import { useEffect, useState } from "react"

import { Frame } from "./Frame"
import { FloatSize, useImageSize } from "./useImageSize"

import styles from "./ImageComponent.module.scss"

const resize = (naturalSize: FloatSize) => {
  const naturalWidth = naturalSize.width
  const width = Math.min(698, naturalWidth)
  const scale = width / naturalWidth
  const height = naturalSize.height * scale

  return {
    width,
    height
  }
}

export const ImageComponent = ({
  updateAttributes,
  selected,
  node
}: NodeViewProps) => {
  const { id, assetId, src, width, height } = node.attrs

  const [loading, naturalSize] = useImageSize(src)
  const [defaultSize, setDefaultSize] = useState<Size>()

  const sizeIsUndefined = !width || !height

  useEffect(() => {
    if (!loading) {
      if (sizeIsUndefined) {
        const size = resize(naturalSize)

        updateAttributes(omitBy(size, isNil))
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  useEffect(() => {
    if (width && height) {
      setDefaultSize({
        width,
        height
      })
    }
  }, [width, height])

  return (
    <NodeViewWrapper
      data-asset-id={assetId}
      data-node-id={id}
      contentEditable="false"
      suppressContentEditableWarning
      className={styles.container}
    >
      {defaultSize && (
        <Frame
          defaultSize={defaultSize}
          selected={selected}
          onResize={(size) => {
            updateAttributes(size)
          }}
          onScale={(resizable) => {
            const size = resize(naturalSize)

            updateAttributes(size)
            resizable.updateSize(size)
          }}
        >
          <div
            className={styles.imageWrapper}
            style={{ backgroundImage: `url("${src}")` }}
          />
        </Frame>
      )}
    </NodeViewWrapper>
  )
}
