import { useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"

import { Stack } from "components/layout"

import styles from "./ImageImport.module.scss"

export interface ImageImportProps {
  placeholder?: string
  onFile: (file: File) => void
}

export const ImageImport = ({ onFile }: ImageImportProps) => {
  const [preview, setPreview] = useState<string>("")

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0]

      if (file) {
        setPreview(URL.createObjectURL(file))
      }

      onFile(file)
    }
  })
  /**
   * Clears up memory allocated by URL.createObjectURL
   */
  useEffect(() => {
    return () => {
      URL.revokeObjectURL(preview)
    }
  }, [preview])

  const content = preview ? (
    <div className={styles.background}>
      <div
        className={styles.frame}
        style={{ backgroundImage: `url(${preview})` }}
      />
    </div>
  ) : (
    <div className={styles.placeholder}>Project logo</div>
  )

  return (
    <Stack distance={2}>
      <div {...getRootProps({ className: styles.zone })}>
        <input {...getInputProps()} />
        {content}
      </div>
    </Stack>
  )
}
