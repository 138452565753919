import TextareaAutoresize, {
  TextareaAutosizeProps
} from "react-textarea-autosize"
import cn from "classnames"

import styles from "./Textarea.module.scss"
import { forwardRef } from "react"

export interface TextareaProps extends TextareaAutosizeProps {
  showMaxLength?: boolean
  headless?: boolean
  allowNewline?: boolean
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      className,
      headless,
      showMaxLength,
      maxLength,
      allowNewline = true,
      ...props
    },
    ref
  ) => {
    const value = (props.value || "").toString()
    const enableMaxLengthElement = showMaxLength && maxLength

    return (
      <div
        className={cn(
          styles.container,
          enableMaxLengthElement && styles.maxLength
        )}
        data-length-allowed={
          enableMaxLengthElement ? maxLength - value.length : undefined
        }
      >
        <TextareaAutoresize
          ref={ref}
          maxLength={maxLength}
          value={value}
          className={cn(
            styles.textarea,
            headless && styles.headless,
            maxLength && styles.maxLength,
            className
          )}
          {...props}
          onKeyDown={(event) => {
            /**
             * Do not submit form when Enter is pressed
             */
            if (event.key === "Enter") {
              event.stopPropagation()

              if (!allowNewline) {
                event.preventDefault()
              }
            }

            props.onKeyDown?.(event)
          }}
        />
      </div>
    )
  }
)
