import { useHistory } from "react-router"

import Modal, { ModalProps } from "components/Modal"

import { AuthenticateForm } from "./AuthenticateForm"

export const AuthenticateModal = (props: ModalProps) => {
  const history = useHistory()

  return (
    <Modal {...props} maxWidth={500}>
      <AuthenticateForm
        onAuthenticate={() => {
          history.push("/projects")
        }}
      />
    </Modal>
  )
}
