import Card from "components/Card"
import { ModalManager, useModal } from "components/Modal"
import { Row, Split, Stack } from "components/layout"
import { Button } from "routes/Project/components"
import toast from "react-hot-toast"
import {
  useGetTeamQuery,
  useRemoveInviteMutation,
  useRemoveMemberMutation
} from "graphql/types"
import { useProject } from "providers/Project"

import { InviteMember } from "./InviteMember"
import { GET_TEAM } from "./operations"
import { TeamListItem } from "./TeamListItem"

const Team = () => {
  const { projectId } = useProject()

  const { data, refetch } = useGetTeamQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      id: projectId
    }
  })

  const [removeMember] = useRemoveMemberMutation()
  const [removeInvite] = useRemoveInviteMutation()

  const modal = useModal()
  const { members = [], invites = [] } = data?.project || {}

  return (
    <Stack distance={10}>
      <Card
        toolbar={
          <Button
            secondary
            onClick={() => {
              modal.show("invite-member")
            }}
          >
            Invite teammate
          </Button>
        }
      >
        <Stack distance={8}>
          {members.map((member) => (
            <TeamListItem
              key={member._id}
              user={member.user}
              showControls
              onRemove={async () => {
                try {
                  await removeMember({
                    variables: {
                      projectId,
                      id: member._id
                    }
                  })
                } catch (error) {
                  toast("Oops! Something went wrong!")
                }
              }}
            />
          ))}
        </Stack>
      </Card>
      {invites.length > 0 && (
        <Card>
          <Stack distance={6}>
            <h5 style={{ fontWeight: 600 }}>PENDING INVITES</h5>

            {invites.map((invite) => (
              <Row distance={6} key={invite._id}>
                <Split>
                  <div>{invite.email}</div>
                  <div>
                    <Button
                      secondary
                      onClick={() => {
                        removeInvite({
                          variables: {
                            projectId,
                            id: invite._id
                          },
                          refetchQueries: [
                            {
                              query: GET_TEAM,

                              variables: {
                                id: projectId
                              }
                            }
                          ]
                        })
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                </Split>
              </Row>
            ))}
          </Stack>
        </Card>
      )}

      <ModalManager
        component={InviteMember}
        name="invite-member"
        onAfterClose={() => refetch()}
      />
    </Stack>
  )
}

export default Team
