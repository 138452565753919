import { FormStack } from "components/layout"
import {
  cancelButtonSpec,
  confirmButtonSpec,
  ModalProps,
  useModal
} from "components/Modal"
import { Formik, Form } from "formik"
import Dialog, { Element, Footer, Title } from "components/Modal/Dialog"
import { FormTextarea } from "components/Textarea"

export const EditFile = ({ className, attributes, ...props }: ModalProps) => {
  const modal = useModal()

  const node = attributes?.node
  const updateAttributes = attributes?.updateAttributes

  const initialValues = {
    name: node?.attrs.name ?? ""
  }

  return (
    <Dialog {...props}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          updateAttributes({
            name: values.name
          })

          modal.hide()
        }}
      >
        <Form>
          <Title message="File" />

          <Element>
            <FormStack>
              <FormTextarea label="Name" name="name" />
            </FormStack>
          </Element>
          <Footer
            buttons={[
              cancelButtonSpec(() => modal.hide()),
              confirmButtonSpec()
            ]}
          />
        </Form>
      </Formik>
    </Dialog>
  )
}
