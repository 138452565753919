import { NodeViewWrapper, NodeViewProps } from "@tiptap/react"
import { ReactNode } from "react"
import cn from "classnames"

import { useModal } from "components/Modal"
import { useProject } from "providers/Project"

import { ControlsButton, ControlsContainer } from "./Controls"

import styles from "./EditorNode.module.scss"

export interface EditorNodeProps {
  className?: string
  children: ReactNode
}

export interface EditorNodeWithControlsProps
  extends EditorNodeProps,
    NodeViewProps {}

export const EditorNodeWithControls = ({
  className,
  children,
  node,
  selected,
  updateAttributes,
  deleteNode
}: EditorNodeWithControlsProps) => {
  const modal = useModal()
  const nodeName = node.type.name

  const { projectId } = useProject()

  return (
    <EditorNode className={cn(className, selected && styles.selected)}>
      {children}

      <ControlsContainer className={styles.controls}>
        <ControlsButton
          icon="ri-edit-line"
          onMouseDown={() => {
            modal.show(`editor::edit-${nodeName}`, {
              projectId,
              node,
              updateAttributes,
              deleteNode
            })
          }}
        />

        <ControlsButton
          icon="ri-delete-bin-line"
          onMouseDown={() => {
            deleteNode()
          }}
        />
      </ControlsContainer>
    </EditorNode>
  )
}

export const EditorNode = ({ className, children }: EditorNodeProps) => {
  return (
    <NodeViewWrapper
      className={cn(styles.container, className)}
      contentEditable={false}
    >
      {children}
    </NodeViewWrapper>
  )
}
