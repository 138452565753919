import { Node, mergeAttributes } from "@tiptap/core"
import { ReactNodeViewRenderer } from "@tiptap/react"

import { EmbedComponent } from "./EmbedComponent"
import { ViewerEmbedComponent } from "./ViewerEmbedComponent"

export interface EmbedOptions {
  HTMLAttributes: Record<string, any>
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    embed: {
      /**
       * Set a statement node
       */
      setEmbed: () => ReturnType
    }
  }
}

export const Embed = Node.create({
  name: "embed",

  inline: false,
  atom: true,

  group: "block",

  defaultOptions: {
    HTMLAttributes: {
      logo: null
    }
  },

  addAttributes() {
    return {
      src: {
        default: ""
      },

      type: {
        default: null
      },

      anchor: {
        default: null
      },

      iframe: {
        default: null
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: "embed"
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ["embed", mergeAttributes(HTMLAttributes), 0]
  },

  addCommands() {
    return {
      setEmbed:
        () =>
        ({ commands, chain }) => {
          return chain()
            .focus()
            .insertContent({
              type: "embed"
            })
            .run()
        }
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(
      this.editor.isEditable ? EmbedComponent : ViewerEmbedComponent
    )
  }
})
