import { NodeViewContent, NodeViewProps } from "@tiptap/react"

import { Reactable } from "../Reactable"

export const ViewerParagraphComponent = (props: NodeViewProps) => {
  const { node } = props

  const isEmpty = node.type.name === "paragraph" && node.childCount === 0
  const id = node.attrs.id

  return (
    <Reactable node={node}>
      <NodeViewContent
        as="p"
        style={{ textAlign: node.attrs.textAlign }}
        data-node-id={isEmpty ? null : id}
      />
    </Reactable>
  )
}
