import { useGetPostsQuery } from "graphql/types"

import { Stack } from "components/layout"
import Spinner from "components/Spinner"
import { useProject } from "providers/Project"
import { ModalManager } from "components/Modal"
import { EditMilestone } from "routes/Project/modals/Milestone"

import { ProjectStartMilestone } from "./ProjectStartMilestone"
import { CreateSection } from "./Create"
import { groupEventsByDate } from "./utils"
import { EventTimestamp } from "./EventTimestamp"
import { EventMilestone } from "./EventMilestone"
import { EventPost } from "./EventPost"
import { ViewPost } from "./ViewPost"

import styles from "./Updates.module.scss"

const Updates = () => {
  const { preview, role, projectId } = useProject()

  const { data, loading } = useGetPostsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      id: projectId
    }
  })

  const events = groupEventsByDate(
    data?.project.posts || [],
    data?.project.milestones || [],
    preview
  )

  const showAddButton = preview ? false : role === "member"

  return (
    <>
      <Stack className={styles.list} distance={4}>
        {showAddButton && <CreateSection />}

        {events.map((event, index) => {
          switch (event.type) {
            case "date":
              return <EventTimestamp key={index} date={event.value} />

            case "milestone":
              return (
                <EventMilestone
                  key={index}
                  projectId={projectId}
                  data={event.value}
                />
              )

            case "post":
              return <EventPost key={index} data={event.value} />

            default:
              return null
          }
        })}

        {data?.project && (
          <ProjectStartMilestone
            name={data.project.name}
            date={new Date(data.project.createdAt)}
          />
        )}

        {loading && <Spinner />}
      </Stack>

      <ModalManager name="create-milestone" component={EditMilestone} />
      <ModalManager name="view-post" component={ViewPost} />
    </>
  )
}

export default Updates
