import { formatDistanceToNow } from "date-fns"

import { Split, Stack } from "components/layout"

import styles from "./ThreadMessage.module.scss"

export interface ThreadMessageProps {
  user?: {
    name: string
  }
  client?: {
    email: string
  }
  createdAt: string
  message: string
}

const getName = (user: any, client: any) => {
  if (user) {
    return user.name
  }

  if (client) {
    return client?.email ?? ""
  }

  return ""
}

export const ThreadMessage = ({
  user,
  client,
  createdAt,
  message
}: ThreadMessageProps) => {
  const name = getName(user, client)

  return (
    <Stack distance={4} className={styles.component}>
      <Split className={styles.header}>
        <div className={styles.name}>{name}</div>

        <div className={styles.date}>
          {formatDistanceToNow(new Date(createdAt))} ago
        </div>
      </Split>

      <div className={styles.text}>{message}</div>
    </Stack>
  )
}
