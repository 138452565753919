import cn from "classnames"
import { NodeViewWrapper, NodeViewProps } from "@tiptap/react"
import { isNil, omitBy } from "lodash"

import { Row } from "components/layout"
import Spinner from "components/Spinner"
import { getMeta } from "api"

import { createRef, useEffect, useState } from "react"
import { Anchor } from "./Anchor"
import { IFrame } from "./IFrame"

import styles from "./EmbedComponent.module.scss"
import toast from "react-hot-toast"

export const EmbedComponent = ({
  node,
  editor,
  selected,
  updateAttributes
}: NodeViewProps) => {
  const { src = "", type, anchor, iframe } = node.attrs
  const [loading, setLoading] = useState(false)

  const input = createRef<HTMLInputElement>()

  useEffect(() => {
    if (input.current) {
      input.current.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <NodeViewWrapper
      className={cn(styles.wrapper, selected && styles.selected)}
      contentEditable="false"
      suppressContentEditableWarning
    >
      {editor.isEditable && !type && (
        <Row className={styles.tools} distance={6}>
          <input
            ref={input}
            className={styles.input}
            placeholder='Paste a link to embed content from another site and press "Enter"'
            type="text"
            value={src}
            onKeyDown={async (event) => {
              if (src.trim().length === 0) {
                return
              }

              if (event.key === "Enter") {
                setLoading(true)
                let data = null

                try {
                  data = await getMeta(src ?? "")
                } catch (error) {
                  toast.error(
                    "Error happened while trying to access the link. It might be because the website does not exist or does not allow embedding it's content"
                  )

                  setLoading(false)
                  return
                }

                const node = omitBy(data, isNil)

                updateAttributes({
                  ...node
                })

                setLoading(false)
              }
            }}
            onChange={(event) => {
              updateAttributes({
                src: event.target.value.trim()
              })
            }}
          />

          {loading && <Spinner />}
        </Row>
      )}

      {iframe && <IFrame {...iframe} />}
      {anchor && <Anchor {...anchor} />}
    </NodeViewWrapper>
  )
}
