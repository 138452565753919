import { useField } from "formik"

import { ColorPicker } from "./ColorPicker"

export interface FormColorPickerProps {
  name: string
}

export const FormColorPicker = ({ name }: FormColorPickerProps) => {
  const [field, , helper] = useField(name)

  return (
    <ColorPicker
      value={field.value}
      onChange={(event) => {
        helper.setValue(event)
      }}
    />
  )
}
