import { gql } from "@apollo/client"

export const USER_FRAGMENT = gql`
  fragment User on User {
    _id
    photo
    name
    email
  }
`
