import Tippy from "@tippyjs/react"
import { AUTHORIZATION } from "api"
import { client } from "apollo"
import cn from "classnames"
import { Popover, PopoverListItem } from "components/Menu/Popover"
import { SimpleTooltip } from "components/Tooltip"
import { auth } from "features/auth"
import { signOut } from "firebase/auth"
import { useSelf } from "hooks/useSelf"
import { ReactNode } from "react"
import { useHistory } from "react-router"
import styles from "./Personal.module.scss"

export const logout = async () => {
  localStorage.removeItem(AUTHORIZATION)

  await signOut(auth)
  await client.clearStore()
  await client.cache.reset()

  client.cache.gc()
}

interface AuthenticatedDropdownProps {
  children: ReactNode
}

const AuthenticatedDropdown = ({ children }: AuthenticatedDropdownProps) => {
  const history = useHistory()

  return (
    <Tippy
      interactive
      trigger="click"
      placement="top-start"
      content={
        <Popover width={200}>
          <PopoverListItem
            onClick={() => {
              history.push("/profile")
            }}
          >
            Account
          </PopoverListItem>
          <PopoverListItem
            onClick={() => {
              logout()
              history.push("/")
            }}
          >
            Logout
          </PopoverListItem>
        </Popover>
      }
    >
      <SimpleTooltip placement="right" message="Profile">
        {children}
      </SimpleTooltip>
    </Tippy>
  )
}

export const Personal = () => {
  const { self } = useSelf()

  const button = (
    <div
      className={cn(styles.photo, "mask", "squircle")}
      style={{
        background: self?.photo
          ? `url("${self?.photo}") center center / cover no-repeat`
          : "#eee"
      }}
    />
  )

  return (
    <div className={styles.wrapper}>
      {self ? <AuthenticatedDropdown>{button}</AuthenticatedDropdown> : null}
    </div>
  )
}
