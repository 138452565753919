import { Editor } from "@tiptap/react"
import { ReactNode, createContext, useReducer } from "react"

import { IEditorStoreContext, IReducer } from "./types"
import { initialState } from "./initialState"
import { reducer } from "./reducer"
import { useEditorStore } from "./useEditorStore"
import { useIDs } from "./useIDs"
import { useInitializer } from "./useInitializer"
import { useSynchronizer } from "./useSynchronizer"

export const context = createContext<Partial<IEditorStoreContext>>({})

export interface EditorStoreProps {
  children: ReactNode
  editor: Editor
}

const EditorStoreProvider = context.Provider

const EditorInitializer = ({ editor, children }: EditorStoreProps) => {
  const { projectId, postId } = useIDs()
  const { state } = useEditorStore()

  useInitializer(projectId, postId, editor)
  useSynchronizer(projectId, postId, editor)

  if (!state.ready) {
    return null
  }

  return <>{children}</>
}

export const EditorStore = ({ editor, children }: EditorStoreProps) => {
  const [state, dispatch] = useReducer<IReducer>(reducer, initialState)

  return (
    <EditorStoreProvider value={{ state, dispatch }}>
      <EditorInitializer editor={editor}>{children}</EditorInitializer>
    </EditorStoreProvider>
  )
}
