import Tippy, { TippyProps } from "@tippyjs/react"
import Icon from "components/Icon"
import { Row } from "components/layout"
import { Option } from "components/Menu"
import { Popover, PopoverListItem } from "components/Menu/Popover"
import { HTMLAttributes } from "react"
import styles from "./SelectButton.module.scss"

export interface SelectButtonProps {
  value: string
  options: Option[]
  placement?: TippyProps["placement"]
  onSelect: (option: Option) => void
  style?: HTMLAttributes<HTMLDivElement>["style"]
}

export const SelectButton = ({
  value,
  options,
  onSelect,
  placement = "bottom-end",
  style
}: SelectButtonProps) => {
  return (
    <div className={styles.wrapper}>
      <Tippy
        interactive
        trigger="click"
        duration={0}
        placement={placement}
        content={
          <Popover width={200}>
            {options.map((option) => (
              <PopoverListItem
                key={option.label}
                onClick={() => {
                  onSelect(option)
                }}
              >
                {option.label}
              </PopoverListItem>
            ))}
          </Popover>
        }
      >
        <div className={styles.button} style={style}>
          <Row distance={2} className={styles.label}>
            <div>{options.find((v) => v.value === value)?.label}</div>
            <div>
              <Icon name="ri-arrow-down-s-fill" />
            </div>
          </Row>
        </div>
      </Tippy>
    </div>
  )
}
