import { Extension } from "@tiptap/core"
import { Plugin, PluginKey } from "prosemirror-state"
import { v4 } from "uuid"

const isTargetNodeOfType = (node: any, type: string) => {
  return node.type === type
}

export const uniqueIDPlugin = (field: string, types: string[]) => {
  return new Plugin({
    key: new PluginKey("node-unique-id"),

    appendTransaction: (transactions, prevState, nextState) => {
      let tr = nextState.tr

      if (types.length === 0) {
        return null
      }
      /**
       * Run this plugin only if content has changed.
       */
      const hasChanged = transactions.some(
        (transaction) => transaction.docChanged
      )

      // let modified: boolean = false
      /**
       * In cases where you split paragraph into two, both will have the same attributes (including ID).
       * This cache stores all generated IDs and makes sure that there are no duplicates.
       */
      let cache: Record<string, boolean> = {}
      let changed = false

      if (hasChanged) {
        /**
         * Pulls schemas for whitelisted nodes from the "schema registry" (or whatever it's called).
         */
        const nodeTypes = types.map((name) => nextState.schema.nodes[name])

        nextState.doc.descendants((node, pos) => {
          /**
           * Checks if node belongs to whitelisted type.
           */
          const hasIndexableTarget = nodeTypes.some((nodeType) =>
            isTargetNodeOfType(node, nodeType)
          )

          if (hasIndexableTarget) {
            let value = node.attrs[field]

            if (!value || cache[value]) {
              value = v4()

              console.log(
                `New ${node.type.name} has been indexed (ID: ${value})`
              )

              const attrs = node.attrs

              tr.setNodeMarkup(pos, undefined, {
                ...attrs,
                [field]: value
              })

              changed = true
            }

            cache[value] = true
          }
        })
      }
      /**
       * If transaction modification has happened - return updated transaction, otherwise pretend like nothing happened.
       */
      return changed ? tr : null
    }
  })
}

export interface IndexerOptions {
  types: string[]
  field: string
}

export const Indexer = Extension.create<IndexerOptions>({
  name: "uniqueID",

  defaultOptions: {
    types: [],
    field: "id"
  },

  addProseMirrorPlugins() {
    const { field, types } = this.options

    return [uniqueIDPlugin(field, types)]
  },

  addGlobalAttributes() {
    const { field, types } = this.options

    return [
      {
        types,

        attributes: {
          [field]: {
            default: "",

            renderHTML: (attributes) => {
              return {
                [field]: attributes[field]
              }
            },

            parseHTML: (element) => {
              return element.getAttribute(field)
            }
          }
        }
      }
    ]
  }
})
