import { HttpLink } from "@apollo/client"
import config from "config"

/**
 * Adds ?op=OPERATION_NAME to request. Very useful for debugging.
 */
export const http = new HttpLink({
  uri: `${config.graphqlUrl}/graphql`,

  fetch(uri: any, options: any) {
    const { body } = options
    const { operationName } = JSON.parse(body)
    const query = operationName ? `?op=${operationName}` : ""

    return fetch(`${uri}${query}`, options)
  }
})
