import { Redirect, Route, Switch } from "react-router"

import { Hero } from "./Hero"
import Updates from "./routes/Updates"
import Assets from "./routes/Assets"
import Settings from "./routes/Settings"
import { Team } from "./routes/Team"
import { Metrics } from "./routes/Metrics"

import styles from "./Router.module.scss"

export const Router = () => {
  return (
    <Switch>
      <Route exact path="/projects/:id/settings/:page">
        <Settings />
      </Route>

      <Redirect
        from="/projects/:id/settings"
        to="/projects/:id/settings/general"
      />

      <Route>
        <div className={styles.content}>
          <Hero />

          <div className={styles.contentInner}>
            <Switch>
              <Route exact path="/projects/:id/updates">
                <Updates />
              </Route>

              <Route exact path="/projects/:id/assets">
                <Assets />
              </Route>

              <Route exact path="/projects/:id/team">
                <Team />
              </Route>

              <Route exact path="/projects/:id/metrics">
                <Metrics />
              </Route>

              <Redirect exact path="/projects/:id" to="/projects/:id/updates" />
            </Switch>
          </div>
        </div>
      </Route>
    </Switch>
  )
}
