import { useRef, useEffect, useState } from "react"

/**
 * Todo: improve this. This is very naive implementation
 */
const useScreenFit = function <T extends HTMLElement>() {
  const ref = useRef<T | null>(null)

  const [style, setStyle] = useState<any>({
    right: null,
    opacity: 0
  })

  useEffect(() => {
    if (ref.current) {
      const delta =
        window.innerWidth - ref.current.getBoundingClientRect().right

      const right = delta < 0 ? 0 : null

      setStyle?.({ right, opacity: 1 })
    }
  }, [])

  return [ref, style] as const
}

export default useScreenFit
