import Tippy from "@tippyjs/react"
import cn from "classnames"
import { NodeViewProps, NodeViewWrapper } from "@tiptap/react"
import { ReactNode } from "react"

import { ThreadContainer } from "routes/Post/interactivity/ThreadContainer"
import { usePostStoreByReference } from "routes/Post/PostStore"

import { ReactionList } from "./ReactionList"

import styles from "./Reactable.module.scss"

export interface ReactableProps {
  className?: string
  node: NodeViewProps["node"]
  children: ReactNode
}

export const Reactable = ({ className, node, children }: ReactableProps) => {
  const isEmpty = node.type.name === "paragraph" && node.childCount === 0
  const nodeId = node.attrs.id

  const context = usePostStoreByReference(nodeId)

  const reactions = Object.entries<{ count: number; self: boolean }>(
    context?.reactions || {}
  )

  const discussion = context?.discussions?.[0]
  const thread = discussion?.thread

  const hasToolbar = !isEmpty && (reactions.length > 0 || discussion)

  return (
    <NodeViewWrapper
      className={cn(styles.container, className)}
      data-node-id={nodeId}
      contentEditable={false}
    >
      {children}
      {hasToolbar && (
        <div className={styles.toolbar}>
          <div>
            {reactions.length > 0 && (
              <ReactionList
                reference={nodeId}
                reactions={context?.reactions || {}}
                onAdd={(name) => {
                  context?.createReaction(nodeId, name)
                }}
                onRemove={(id) => {
                  context?.removeReaction(id)
                }}
              />
            )}
          </div>

          <div>
            {discussion && (
              <Tippy
                zIndex={2}
                content={
                  <ThreadContainer
                    postId={context?.id}
                    reference={nodeId}
                    discussions={[discussion]}
                  />
                }
                interactive
                trigger="click"
                placement="bottom-end"
              >
                <div className={styles.comments}>
                  {thread.length} comment{thread.length > 1 ? "s" : ""}
                </div>
              </Tippy>
            )}
          </div>
        </div>
      )}
    </NodeViewWrapper>
  )
}
