import Tippy from "@tippyjs/react"
import { useState } from "react"
import cn from "classnames"
import { Picker } from "emoji-mart"

import "emoji-mart/css/emoji-mart.css"

import styles from "./EditMilestone.module.scss"
import { useField } from "formik"

interface EditEmojiProps {
  value: string
  onSelect: (value: string) => void
}

export const EmojiInput = ({ value, onSelect }: EditEmojiProps) => {
  const [visible, setVisible] = useState(false)
  const show = () => setVisible(true)
  const hide = () => setVisible(false)

  return (
    <Tippy
      placement="bottom-start"
      interactive
      visible={visible}
      onClickOutside={hide}
      content={
        <Picker
          enableFrequentEmojiSort={false}
          perLine={12}
          native
          showSkinTones={false}
          showPreview={false}
          onSelect={(emoji) => {
            // @ts-ignore
            onSelect(emoji.native)
            hide()
          }}
        />
      }
    >
      <div className={styles.editEmoji} onClick={visible ? hide : show}>
        <div className={cn(styles.emojiContainer, "mask", "squircle")}>
          <div className={styles.emoji}>{value}</div>
        </div>
        <div className={styles.description}>
          Click to choose a different emoji
        </div>
      </div>
    </Tippy>
  )
}

export interface FormEmojiInputProps {
  name: string
}

export const FormEmojiInput = ({ name }: FormEmojiInputProps) => {
  const [input, , helper] = useField(name)

  return (
    <EmojiInput
      value={input.value}
      onSelect={(emoji) => {
        helper.setValue(emoji)
      }}
    />
  )
}
