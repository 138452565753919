import React from "react"
import cn from "classnames"

import { Row } from "components/layout"
import Icon from "components/Icon"

import styles from "./Banner.module.scss"
import { Button } from "routes/Project/components"

interface BannerProps {
  icon?: string
  style?: any
  type?: "none" | "warning" | "error" | "info"
  children: React.ReactNode
  action?: {
    label: string
    on: () => void
  }
}

const getIcon = (icon: string) => {
  if (icon.startsWith("ri-")) {
    return <Icon size={20} name={icon} />
  }

  return <span style={{ fontSize: 20 }}>{icon}</span>
}

const Banner = ({
  style,
  children,
  icon,
  type = "none",
  action
}: BannerProps) => (
  <div className={cn(styles.container, styles[type])} style={style}>
    <Row distance={8}>
      {icon && getIcon(icon)}
      {<div>{children}</div>}
      {action && (
        <Button tabIndex={0} onClick={action.on}>
          {action.label}
        </Button>
      )}
    </Row>
  </div>
)

export default Banner
