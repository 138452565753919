import { ApolloProvider } from "@apollo/client"
import { Toaster } from "react-hot-toast"
import { client } from "apollo"
import { Helmet } from "react-helmet"

import { Router } from "./Router"

export const App = () => {
  return (
    <ApolloProvider client={client}>
      <Toaster
        toastOptions={{
          style: {
            lineHeight: 1.5,
            textAlign: "center"
          }
        }}
      />
      <Helmet>
        <title>Shareback</title>
      </Helmet>
      <Router />
    </ApolloProvider>
  )
}
