import { IEditorStoreState } from "./types"

export const initialState: IEditorStoreState = {
  title: "",
  titleFocused: false,
  titleColor: "",
  content: null,
  initialTitle: "",
  initialContent: null,
  ready: false,
  savedAt: null,
  saving: false
}
