import { FormInput } from "components/Input"
import { FormStack } from "components/layout"
import {
  cancelButtonSpec,
  confirmButtonSpec,
  Element,
  Footer,
  Title
} from "components/Modal"
import { Form, Formik } from "formik"
import { FormToggle } from "components/Toggle/FormToggle"

export interface InviteClientFormValues {
  email: string
  notify: boolean
}

const initialValues = {
  email: "",
  notify: true
}

export interface InviteClientFormProps {
  onSubmit: (values: InviteClientFormValues) => Promise<void>
  onCancel: () => void
}

export const InviteClientForm = ({
  onSubmit,
  onCancel
}: InviteClientFormProps) => {
  return (
    <Formik<InviteClientFormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <Form autoComplete="off">
        <Title message="New client" />
        <Element>
          <FormStack>
            <FormInput
              label="Email"
              required
              type="email"
              autoComplete="off"
              placeholder="Email address"
              name="email"
            />

            <FormToggle
              label=""
              name="notify"
              description="Send notification to client"
            />
          </FormStack>
        </Element>

        <Footer buttons={[cancelButtonSpec(onCancel), confirmButtonSpec()]} />
      </Form>
    </Formik>
  )
}
