import { ReactNode, forwardRef } from "react"
import cn from "classnames"

import Icon from "components/Icon"

import styles from "./Popover.module.scss"

export interface PopoverProps {
  width?: number
  style?: any
  children: ReactNode
}

export const Popover = forwardRef<HTMLDivElement, PopoverProps>(
  ({ width, children, style = {} }, ref) => (
    <div ref={ref} className={styles.popover} style={{ width, ...style }}>
      {children}
    </div>
  )
)

export interface PopoverButtonProps {
  active?: boolean
  icon?: string
  style?: any
  children?: ReactNode
  className?: string
  onClick: () => void
}

export const PopoverButton = forwardRef<HTMLButtonElement, PopoverButtonProps>(
  ({ active, children, onClick, icon, style, className }, ref) => (
    <button
      ref={ref}
      style={style}
      className={cn(styles.button, active && styles.active, className)}
      onClick={() => onClick()}
    >
      {icon && <Icon size={16} name={icon} />}
      {children}
    </button>
  )
)
