import Icon from "components/Icon"
import { Row } from "components/layout"
import { Text } from "components/Text"

import styles from "./Header.module.scss"

interface HeaderButtonProps {
  active: boolean
  icon: string
  label: string
  onClick: () => void
}

const HeaderButton = ({ label, icon, onClick }: HeaderButtonProps) => {
  return (
    <div className={styles.button} onClick={() => onClick()}>
      <Row distance={3} vertical="center">
        <Icon color="var(--secondary-text-color)" name={icon} />
        <Text color="var(--secondary-text-color)">{label}</Text>
      </Row>
    </div>
  )
}

export interface HeaderProps {
  enableViews: boolean
  loading: boolean
  commentsCount: number
  viewsCount: number
  value: string
  onChange: (v: string) => void
}

export const Header = ({
  enableViews,
  loading,
  commentsCount,
  viewsCount,
  value,
  onChange
}: HeaderProps) => {
  return loading ? null : (
    <Row distance={0} className={styles.container}>
      <HeaderButton
        active={value === "comments"}
        onClick={() => onChange("comments")}
        icon="ri-chat-1-fill"
        label={`${commentsCount} comment${commentsCount === 1 ? "" : "s"}`}
      />

      {enableViews && (
        <HeaderButton
          active={value === "views"}
          onClick={() => onChange("views")}
          icon="ri-group-fill"
          label={`${viewsCount} views${viewsCount === 1 ? "" : "s"}`}
        />
      )}
    </Row>
  )
}
