import React, { forwardRef, HTMLAttributes } from "react"
import cn from "classnames"

import styles from "./styles.module.scss"

interface IconProps extends HTMLAttributes<HTMLSpanElement> {
  name: string
  inline?: boolean
  className?: any
  color?: string
  size?: number
  bold?: boolean
}

const Icon = forwardRef<HTMLBaseElement, IconProps>(
  ({ className, inline, name, color, size, bold, style, ...props }, ref) => (
    <i
      ref={ref}
      className={cn(
        styles.icon,
        bold && styles.bold,
        inline && styles.inline,
        name,
        className
      )}
      style={{ color, fontSize: size, ...style }}
      {...props}
    />
  )
)

export default Icon
