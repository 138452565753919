import cn from "classnames"
import { ReactNode } from "react"
import ReactModal, { Props } from "react-modal"
import styles from "./Modal.module.scss"

export interface ModalProps extends Props {
  maxWidth?: number
  closable?: boolean
  attributes?: Record<string, any>
  callbacks?: Record<string, Function>
  children?: ReactNode
}

export const Modal = ({
  children,
  className,
  maxWidth,
  ...props
}: ModalProps) => (
  <ReactModal
    closeTimeoutMS={300}
    className={cn(styles.modal, className)}
    style={{ content: { maxWidth } }}
    overlayClassName={styles.overlay}
    {...props}
  >
    {children}
  </ReactModal>
)

export default Modal
