import { ApolloCache, Reference, StoreObject } from "@apollo/client"
import { Formik, Form } from "formik"
import { endOfDay, formatISO } from "date-fns"
import {
  MilestoneFragment,
  useCreateMilestoneMutation,
  useGetMilestoneQuery,
  useUpdateMilestoneMutation
} from "graphql/types"

import { FormStack } from "components/layout"
import { FormTextarea } from "components/Textarea"
import {
  Dialog,
  ModalProps,
  useModal,
  Title,
  Element,
  Footer,
  submitButtonSpec
} from "components/Modal"
import { useProject } from "providers/Project"
import { FormDatepickerISO } from "components/Datepicker/FormDatepicker"
import { MILESTONE_FRAGMENT } from "routes/Project/routes/Updates/operations"

import "emoji-mart/css/emoji-mart.css"

import { FormEmojiInput } from "./EmojiInput"

export interface EditMilestoneFormValues {
  emoji: string
  date: string
  description: string
}

const today = new Date()

const defaultValues = {
  emoji: "🎉",
  date: formatISO(today),
  description: ""
}

function updateMilestoneCache<T>(
  cache: ApolloCache<T>,
  project: StoreObject,
  milestone: MilestoneFragment
) {
  cache.modify({
    id: cache.identify(project),
    fields: {
      milestones(milestones, { readField }) {
        const newMilestoneRef = cache.writeFragment({
          data: milestone,
          fragment: MILESTONE_FRAGMENT
        })

        if (!newMilestoneRef) {
          return milestones
        }

        const isPresentInCache = milestones.some(
          (ref: Reference) => readField("_id", ref) === milestone?._id
        )

        return isPresentInCache ? milestones : [...milestones, newMilestoneRef]
      }
    }
  })
}

export const EditMilestone = ({
  className,
  attributes,
  ...props
}: ModalProps) => {
  const { project, projectId } = useProject()
  const modal = useModal()

  const id = attributes?.milestoneId

  const { data, loading } = useGetMilestoneQuery({
    variables: {
      projectId,
      id
    },
    skip: !id
  })

  const [createMilestoneMutation] = useCreateMilestoneMutation()
  const [updateMilestoneMutation] = useUpdateMilestoneMutation()

  const createMilestone = async (values: EditMilestoneFormValues) => {
    await createMilestoneMutation({
      variables: {
        projectId,
        input: {
          emoji: values.emoji,
          description: values.description,
          date: values.date
        }
      },
      update(cache, { data }) {
        const milestone = data?.createMilestone

        if (milestone) {
          updateMilestoneCache(cache, project, milestone)
        }
      }
    })
  }

  const updateMilestone = async (values: EditMilestoneFormValues) => {
    await updateMilestoneMutation({
      variables: {
        projectId,
        id,
        input: {
          emoji: values.emoji,
          description: values.description,
          date: values.date
        }
      },
      update(cache, { data }) {
        const milestone = data?.updateMilestone

        if (milestone) {
          updateMilestoneCache(cache, project, milestone)
        }
      }
    })
  }

  const submit = async (values: EditMilestoneFormValues) => {
    const mutate = id ? updateMilestone : createMilestone
    await mutate(values)

    modal.hide()
  }

  const milestone = data?.milestone

  const initialValues = milestone
    ? {
        emoji: milestone.emoji,
        date: milestone.date,
        description: milestone.description
      }
    : defaultValues

  return (
    <Dialog {...props}>
      {!loading && (
        <Formik<EditMilestoneFormValues>
          initialValues={initialValues}
          onSubmit={submit}
        >
          <Form>
            <Title message="Milestone" />

            <Element>
              <FormStack>
                <div>
                  <FormEmojiInput name="emoji" />
                </div>

                <FormTextarea
                  label="Caption"
                  minRows={1}
                  name="description"
                  placeholder="E.g. We've reached 2bn users!"
                />

                <FormDatepickerISO
                  label="Date"
                  name="date"
                  maxDate={endOfDay(today)}
                />
              </FormStack>
            </Element>

            <Footer buttons={[submitButtonSpec("Publish")]} />
          </Form>
        </Formik>
      )}
    </Dialog>
  )
}
