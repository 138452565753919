import { forwardRef } from "react"
import { useHistory } from "react-router"

import { useProject } from "providers/Project"
import { HeaderLogo } from "components/HeaderLogo"
import { Button } from "routes/Project/components"
import { Row, Split } from "components/layout"
import { useModal } from "components/Modal"

import styles from "./PostHero.module.scss"

export const PostHero = forwardRef<HTMLDivElement, any>(({ post }, ref) => {
  const { self, authenticated, preview } = useProject()

  const history = useHistory()
  const modal = useModal()

  const draft = !post.publishedAt
  const author = self?._id === post?.createdBy?._id
  const showEditButton = authenticated && !preview && author

  return (
    <div className={styles.hero} ref={ref}>
      <Split style={{ paddingTop: 8 }}>
        <HeaderLogo />
        <div>
          {showEditButton && (
            <Row distance={3} style={{ paddingRight: 12 }}>
              {!draft && (
                <Button
                  secondary
                  onClick={() => {
                    modal.show("publish", {
                      post,
                      notifications: false
                    })
                  }}
                >
                  Edit summary
                </Button>
              )}

              <Button
                secondary
                onClick={() => {
                  history.push(`/projects/${post?.project}/editor/${post?._id}`)
                }}
              >
                Edit update
              </Button>
            </Row>
          )}
        </div>
      </Split>
    </div>
  )
})
