import { EmptyMessage } from "components/EmptyMessage/EmptyMessage"
import { ModalManager, useModal } from "components/Modal"
import { Stack } from "components/layout"
import { useGetClientsQuery, useRemoveClientMutation } from "graphql/types"
import { useIDs } from "routes/Editor/Editor/EditorStore"
import Card from "components/Card"
import Spinner from "components/Spinner"
import toast from "react-hot-toast"

import { Button } from "../../../components/Button"
import { GET_CLIENTS } from "./operations"
import { InviteClientModal } from "./InviteClientModal"
import { TeamListItem } from "../Team/TeamListItem"

export const Clients = () => {
  const { projectId } = useIDs()
  const modal = useModal()

  const { data, loading } = useGetClientsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      id: projectId
    }
  })

  const [removeClint] = useRemoveClientMutation({
    refetchQueries: [
      {
        query: GET_CLIENTS,
        variables: {
          id: projectId
        }
      }
    ]
  })

  const clients = data?.project.clients?.filter(Boolean) ?? []

  return (
    <Card
      toolbar={
        <Button
          secondary
          onClick={() => {
            modal.show("invite-client")
          }}
        >
          Invite client
        </Button>
      }
    >
      <Stack distance={10}>
        {clients.length === 0 && <EmptyMessage message="No clients yet" />}

        {clients.length !== 0 && (
          <Stack distance={6}>
            {clients.filter(Boolean).map((client) => (
              <TeamListItem
                key={client._id}
                role={client.user ? "" : "guest"}
                user={{
                  name: client.user?.name || "Guest",
                  email: client.user?.email || client?.email,
                  photo: client.user?.photo
                }}
                showControls
                onRemove={async () => {
                  try {
                    await removeClint({
                      variables: {
                        projectId,
                        id: client._id
                      }
                    })
                  } catch (error) {
                    toast("Oops! Something went wrong")
                  }
                }}
              />
            ))}
          </Stack>
        )}

        {loading && !clients && <Spinner />}
      </Stack>
      <ModalManager component={InviteClientModal} name="invite-client" />
    </Card>
  )
}

export default Clients
