import styles from "./AssetIcon.module.scss"

export interface AssetIconProps {
  url: string
  mime: string
  extension: string
}

export const AssetIcon = ({ url, mime, extension }: AssetIconProps) => {
  const isImage = mime?.startsWith("image")

  return (
    <div
      className={styles.icon}
      style={{
        backgroundImage: isImage ? `url(${url})` : undefined
      }}
    >
      {!isImage && <div className={styles.extension}>{extension}</div>}
    </div>
  )
}
