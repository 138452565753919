import cn from "classnames"

import styles from "./EventTimestamp.module.scss"

export interface EventTimestampProps {
  date: string
}

export const EventTimestamp = ({ date }: EventTimestampProps) => (
  <div className={cn(styles.container, styles.head, styles.tail)}>
    <div className={styles.timestamp}>{date}</div>
  </div>
)
