import {
  useRedirectUnauthenticated,
  useRedirectUnverified
} from "features/auth"
import { Route, RouteProps } from "react-router"

import ErrorBoundary from "../components/ErrorBoundary"
import { ModalProvider } from "../components/Modal"

export interface ActiveRouteProps extends RouteProps {}

export const ActiveRoute = (props: ActiveRouteProps) => {
  useRedirectUnauthenticated()
  useRedirectUnverified()

  return (
    <ModalProvider>
      <ErrorBoundary>
        <Route {...props} />
      </ErrorBoundary>
    </ModalProvider>
  )
}
