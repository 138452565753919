import { useHistory } from "react-router"
import Tippy from "@tippyjs/react"

import Button from "components/Button"
import { useProject } from "providers/Project"
import { Popover, PopoverListItem } from "components/Menu/Popover"
import { Text } from "components/Text"
import { Stack } from "components/layout"
import { useModal } from "components/Modal"

import styles from "./Create.module.scss"
import { useCreatePostMutation } from "graphql/types"

export const ENABLE_MILESTONES = true

const ListItem = ({ title, description, onClick }: any) => (
  <PopoverListItem onClick={onClick}>
    <Stack distance={3}>
      <Text style={{ fontWeight: 600 }} size={16} bold>
        {title}
      </Text>
      <Text style={{ opacity: 0.8 }} size={13} lineHeight={1.5}>
        {description}
      </Text>
    </Stack>
  </PopoverListItem>
)

export const CreateSection = () => {
  const history = useHistory()
  const modal = useModal()
  const { projectId } = useProject()

  const [createPost] = useCreatePostMutation()

  return (
    <div className={styles.container}>
      <Tippy
        interactive
        placement="bottom"
        hideOnClick={false}
        content={
          ENABLE_MILESTONES ? (
            <Popover width={330}>
              <ListItem
                onClick={async () => {
                  const { data } = await createPost({
                    variables: {
                      projectId,
                      input: {
                        title: "",
                        content: null,
                        summary: ""
                      }
                    }
                  })

                  history.push(
                    `/projects/${projectId}/editor/${data?.createPost._id}`
                  )
                }}
                title="Post"
                description="Full featured update with an ability to showcase assets, ask
                  for feedback, and more."
              />

              <ListItem
                onClick={() => {
                  modal.show("create-milestone")
                }}
                title="Milestone"
                description="Cheeky milestone - celebrate wins, highlight achievements or
              share meaningful metrics!"
              />
            </Popover>
          ) : null
        }
      >
        <div>
          <Button
            icon="ri-add-line"
            className={styles.primaryButton}
            use="dashed"
          />
        </div>
      </Tippy>
    </div>
  )
}
