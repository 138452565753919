import { onError } from "@apollo/client/link/error"

export const errors = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      console.group("Graphql error")
      console.warn(message)
      console.warn(locations)
      console.warn(path)
      console.groupEnd()

      if (message.includes("Firebase ID token has expired.")) {
      }

      // if (message === "401: Unauthorized") {
      //   localStorage.removeItem(AUTHORIZATION);
      //   window.location.href = "/";
      // }
    })
  }

  console.log(graphQLErrors, networkError)

  if (networkError) {
    console.warn(networkError)
  }
})
