import { ButtonHTMLAttributes } from "react"
import cn from "classnames"

import styles from "./Button.module.scss"
import Icon from "components/Icon"

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: string
  loading?: boolean
  fill?: boolean
  use?: "primary" | "secondary" | "tertiary" | "dashed"
  size?: "medium" | "small" | "large"
}

const Button = ({
  className,
  icon,
  type = "button",
  use = "primary",
  size = "large",
  fill,
  loading,
  children,
  ...props
}: ButtonProps) => (
  <button
    type={type}
    className={cn(
      styles.element,
      loading && styles.loading,
      fill && styles.fill,
      className,
      styles[use],
      styles[size]
    )}
    {...props}
  >
    {icon && <Icon className={styles.icon} name={icon} />}
    {children && <span className={styles.text}>{children}</span>}
  </button>
)

export default Button
