import Tippy from "@tippyjs/react"
import { useRef, useMemo } from "react"

import { Row } from "components/layout"
import { REACTIONS } from "utils/feedback"
import Icon from "components/Icon"

import { Popover, PopoverButton } from "./Popover"
import { ThreadReply } from "./ThreadReply"

import styles from "./FeedbackMenu.module.scss"

const ReactionMenu = ({ onSelect }: any) => (
  <Popover>
    <Row distance={4}>
      {REACTIONS.map((reaction) => (
        <PopoverButton key={reaction.name} onClick={() => onSelect(reaction)}>
          <span style={{ fontSize: 16 }}>{reaction.emoji}</span>
        </PopoverButton>
      ))}
    </Row>
  </Popover>
)

const DiscussionMenu = ({ onSubmit }: any) => (
  <Popover width={300} style={{ padding: 12 }}>
    <ThreadReply onSubmit={onSubmit} />
  </Popover>
)

export interface FeedbackMenuProps {}

export const FeedbackMenu = ({ reference, onReaction, onDiscussion }: any) => {
  const parent = useRef<unknown>(null)

  const id = useMemo(() => reference?.getAttribute("data-node-id"), [reference])

  return (
    <Tippy
      delay={150}
      zIndex={100}
      offset={[0, 20]}
      trigger="mouseenter"
      hideOnClick={false}
      reference={reference}
      onCreate={(instance) => {
        parent.current = instance
      }}
      placement="left-start"
      interactive
      content={
        <Row distance={2} style={{ backgroundColor: "#fff" }}>
          <div>
            <Tippy
              placement="top-start"
              trigger="click"
              zIndex={20}
              offset={[-10, 10]}
              content={
                <ReactionMenu
                  onSelect={(reaction: Record<string, string>) =>
                    onReaction(id, reaction)
                  }
                />
              }
              interactive
            >
              <div className={styles.button}>
                <Icon size={20} name="ri-user-smile-line" />
              </div>
            </Tippy>
          </div>

          <div>
            <Tippy
              placement="bottom-start"
              trigger="click"
              offset={[-10, 10]}
              zIndex={20}
              content={
                <DiscussionMenu
                  onSubmit={async (message: string) => {
                    try {
                      await onDiscussion(id, message)
                      // @ts-ignore
                      parent.current?.hide()
                    } catch (error) {
                      console.warn(error)
                    }
                  }}
                />
              }
              interactive
            >
              <div className={styles.button}>
                <Icon size={20} name="ri-message-3-line" />
              </div>
            </Tippy>
          </div>
        </Row>
      }
    />
  )
}
