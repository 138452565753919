import cn from "classnames"

import { Tooltip } from "components/Tooltip"
import { usePostStore } from "routes/Post/PostStore"
import { useProject } from "providers/Project"

import styles from "./Reaction.module.scss"
import { useGetReactionPeopleLazyQuery } from "graphql/types"

export interface ReactionProps {
  name: string
  reference: string
  count: number
  emoji: string
  active: boolean
  onClick: () => void
}

export const Reaction = ({
  name,
  reference,
  count,
  emoji,
  active,
  onClick
}: ReactionProps) => {
  const { project } = useProject()
  const post = usePostStore()

  const [fetch, { data }] = useGetReactionPeopleLazyQuery({
    fetchPolicy: "network-only"
  })

  const people = data?.reactionPeople?.people
  const enabled = !!people?.length

  const tooltip = people ? `${people?.join(", ")} reacted with ${emoji}` : ""

  return (
    <Tooltip enabled={enabled} placement="top" message={tooltip}>
      <div
        className={cn(styles.reaction, active && styles.active)}
        onMouseEnter={async () => {
          fetch({
            variables: {
              projectId: project._id,
              postId: post.id,
              name,
              reference
            }
          })
        }}
        onClick={() => onClick()}
      >
        <div className={styles.emoji}>{emoji}</div>
        <div className={styles.count}>{count}</div>
      </div>
    </Tooltip>
  )
}
