import { createRef, useEffect, useState } from "react"

import styles from "./IFrame.module.scss"

export interface IFrameProps {
  src: string | null
  html: string | null
  width: string | null
  height: string | null
}

const RESIZE_SCRIPT = `<script type="application/javascript">
  window.onload = function () {
    setTimeout(function () {
      window.parent.postMessage({
        source: "IFRAME_RESIZE",
        height: document.body.scrollHeight,
        width: document.body.scrollWidth
      }, "*"); 
    }, 1500)
  };
  </script>`

interface IFrameHTMLProps {
  html: string
}

const IFrameHTML = ({ html }: IFrameHTMLProps) => {
  const ref = createRef<HTMLIFrameElement>()
  const [height, setHeight] = useState(0)

  useEffect(() => {
    const listener = (event: MessageEvent<any>) => {
      if (event.data.source === "IFRAME_RESIZE") {
        console.log(event.data.height)
        setHeight(event.data.height)
      }
    }

    window.addEventListener("message", listener)

    return () => {
      window.removeEventListener("message", listener)
    }
  }, [])

  return (
    <div className={styles.container} style={{ height }}>
      <iframe
        allowFullScreen
        ref={ref}
        className={styles.iframe}
        title="Embedded content"
        frameBorder="0"
        src={"data:text/html," + encodeURIComponent(RESIZE_SCRIPT + html)}
      />
    </div>
  )
}

export const IFrame = ({ height, width, src, html }: IFrameProps) => {
  if (html) {
    return <IFrameHTML html={html} />
  }

  return (
    <div className={styles.container}>
      {src && (
        <iframe
          className={styles.iframe}
          title="Embedded content"
          src={src}
          frameBorder="0"
          allowFullScreen
        />
      )}
    </div>
  )
}
