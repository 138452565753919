import { decodeCachedIdToken } from "helpers/AccessToken"
import { sortBy } from "lodash"

export const ownershipType = ["all", "personal", "shared"]
export const sortType = ["createdAt", "client", "name"]

export const filterByOwnershipType = (items: any[], ownershipType: string) => {
  const token = decodeCachedIdToken()
  const userId = token?.internal_id

  if (!userId) {
    return items
  }

  switch (ownershipType) {
    case "personal":
      return items.filter((item) => item.createdBy._id === userId)
    case "shared":
      return items.filter((item) => item.createdBy._id !== userId)
    default:
      return items
  }
}

export const prepare = <T>(items: T[], filter: string, sort: string): T[] => {
  let values = sortBy(filterByOwnershipType(items, filter), sort)

  if (sort === "createdAt") {
    values = values.reverse()
  }

  return values.filter(Boolean)
}
