import cn from "classnames"
import {
  useCreateAnswerMutation,
  useGetPeopleWhoSelectedOptionLazyQuery
} from "graphql/types"

import { Tooltip } from "components/Tooltip"
import { Row } from "components/layout"
import { numberToLetters } from "utils/string"

import styles from "../QuestionComponent.module.scss"

interface ViewerQuestionComponentOptionProps {
  index: number
  owned: boolean
  message: string
  quantity: string
  projectId: string
  questionId: string
  optionId: string
  onAnswer: () => void
}

export const ViewerQuestionComponentOption = ({
  projectId,
  questionId,
  optionId,
  owned,
  message,
  index,
  quantity,
  onAnswer
}: ViewerQuestionComponentOptionProps) => {
  const [getNames, { data }] = useGetPeopleWhoSelectedOptionLazyQuery({
    fetchPolicy: "network-only"
  })

  const names = data?.peopleWhoSelectedOption

  const [createAnswer] = useCreateAnswerMutation()

  const answer = async () => {
    await createAnswer({
      variables: {
        projectId,
        questionId,
        input: {
          value: optionId
        }
      }
    })

    onAnswer()
  }

  return (
    <div
      className={cn(styles.answer, styles.button, owned && styles.chosen)}
      onClick={answer}
    >
      <Row distance={2} fill>
        <div>
          <div className={styles.icon}>{numberToLetters(index)}</div>
        </div>
        <div className={styles.message}>{message}</div>
        <Tooltip
          enabled={!!names?.length}
          placement="top-end"
          message={names?.join(", ") || ""}
        >
          <div
            className={styles.quantity}
            onMouseEnter={() => {
              getNames({
                variables: {
                  projectId,
                  questionId,
                  optionId
                }
              })
            }}
          >
            {quantity}
          </div>
        </Tooltip>
      </Row>
    </div>
  )
}
