import { Formik, Form } from "formik"

import { FormStack } from "components/layout"
import {
  cancelButtonSpec,
  confirmButtonSpec,
  ModalProps,
  useModal
} from "components/Modal"
import { FormInput } from "components/Input"

import {
  useCreateMeasureMutation,
  useCreateMetricMutation,
  useGetMeasureQuery,
  useGetMetricsQuery
} from "graphql/types"
import { FormSelect } from "components/Select"
import { BoxSpinner } from "components/Spinner"
import { FormDatepickerISO } from "components/Datepicker/FormDatepicker"
import { formatISO } from "date-fns"
import Dialog, { Element, Footer, Title } from "components/Modal/Dialog"

import { useIDs } from "../../EditorStore"
import { validationSchema } from "./validationSchema"

const today = new Date()

export const EditMetrics = ({
  className,
  attributes,
  ...props
}: ModalProps) => {
  const { projectId, postId } = useIDs()
  const modal = useModal()

  const { node, updateAttributes } = attributes || {}

  const metricsQuery = useGetMetricsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      projectId
    }
  })

  const measureQuery = useGetMeasureQuery({
    variables: {
      projectId,
      metricId: node.attrs.metricId,
      id: node.attrs.measureId
    },
    skip: !node.attrs.measureId || !node.attrs.metricId
  })

  const [createMetric] = useCreateMetricMutation()
  const [createMeasure] = useCreateMeasureMutation()

  const options =
    metricsQuery.data?.metrics.map((metric) => {
      return {
        label: metric.name,
        value: metric._id
      }
    }) ?? []

  const loading = metricsQuery.loading && measureQuery.loading

  return (
    <Dialog {...props}>
      {loading ? (
        <BoxSpinner />
      ) : (
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            metricId: node.attrs.metricId ?? "",
            name: "",
            date: measureQuery.data?.measure.date ?? formatISO(today),
            value: measureQuery.data?.measure.value ?? ""
          }}
          onSubmit={async (values) => {
            let metricId = values.metricId

            if (!metricId) {
              const { data } = await createMetric({
                variables: {
                  projectId,
                  name: values.name
                }
              })

              metricId = data?.createMetric._id
            }

            const { data } = await createMeasure({
              variables: {
                projectId,
                metricId,
                input: {
                  reference: node.attrs.reference,
                  postId,
                  value: Number(values.value) || 0,
                  date: values.date
                }
              }
            })

            const measureId = data?.createMeasure._id

            updateAttributes({
              metricId,
              measureId
            })

            modal.hide()
          }}
        >
          {(props) => (
            <Form>
              <Title message="Metrics" />
              <Element>
                <FormStack>
                  <FormSelect
                    options={[
                      { value: "", label: "New metric..." },
                      ...options
                    ]}
                    name="metricId"
                  />

                  {!props.values.metricId && (
                    <FormInput
                      name="name"
                      label="Name"
                      placeholder="E.g. Clicks per day"
                    />
                  )}

                  <FormInput
                    type="number"
                    name="value"
                    label="Value"
                    placeholder="E.g. 100"
                  />

                  <FormDatepickerISO label="Date" name="date" />
                </FormStack>
              </Element>

              <Footer
                buttons={[
                  cancelButtonSpec(() => modal.hide()),
                  confirmButtonSpec()
                ]}
              />
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  )
}
