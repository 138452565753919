import { Size } from "re-resizable"
import { useEffect, useState } from "react"

export interface FloatSize extends Size {
  width: number
  height: number
}

export const useImageSize = (src: string) => {
  const [loading, setLoading] = useState(true)
  const [size, setSize] = useState<FloatSize>({ width: 0, height: 0 })

  useEffect(() => {
    const image = new Image()
    setLoading(true)

    image.addEventListener("load", () => {
      const { naturalHeight, naturalWidth } = image

      setSize({
        width: naturalWidth,
        height: naturalHeight
      })

      setLoading(false)
    })

    image.src = src

    return () => {
      // image.removeEventListener('load')
    }
  }, [src])

  return [loading, size] as const
}
