import React, { HTMLAttributes } from "react"

import styles from "./Centered.module.scss"

interface CenteredProps extends HTMLAttributes<HTMLDivElement> {}

const Centered = ({ children, ...style }: CenteredProps) => (
  <div className={styles.centered} style={{ ...style }}>
    {children}
  </div>
)

export default Centered
