import { HashIcon } from "components/HashIcon"
import { Row, Stack } from "components/layout"
import {
  cancelButtonSpec,
  Dialog,
  DialogWithAttributesProps,
  Element,
  Footer,
  Title,
  useModal
} from "components/Modal"
import { Text } from "components/Text"
import { QuestionFragment, useGetQuestionWithAnswersQuery } from "graphql/types"
import { formatFriendlyDateTime } from "utils/date"

import styles from "./AnswersModal.module.scss"

const Answer = ({ user, client, createdAt, value }: any) => {
  const name = user?.name || client?.email || "Guest"

  return (
    <div className={styles.answer}>
      <Row distance={6} vertical="top">
        <div style={{ paddingTop: 2 }}>
          <HashIcon size="small" value={name} url={user?.photo} />
        </div>
        <Stack distance={8} className={styles.content}>
          <Stack distance={2}>
            <Text color="var(--primary-text-color)" bold>
              {name}
            </Text>

            <Text size={11} color="var(--secondary-text-color)">
              {formatFriendlyDateTime(new Date(createdAt))}
            </Text>
          </Stack>
          <div>{value}</div>
        </Stack>
      </Row>
    </div>
  )
}

export const AnswersModal = (
  props: DialogWithAttributesProps<{ question: QuestionFragment }>
) => {
  const modal = useModal()

  const { data } = useGetQuestionWithAnswersQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      projectId: props.attributes.question.project,
      questionId: props.attributes.question._id
    }
  })

  const answers = data?.question.answers ?? []

  return (
    <Dialog {...props}>
      <Title message="Answers" />
      <Element>
        <div className={styles.list}>
          {answers.map((answer) => (
            <Answer {...answer} key={answer._id} />
          ))}
        </div>
      </Element>
      <Footer
        buttons={[
          cancelButtonSpec(() => {
            modal.hide()
          })
        ]}
      />
    </Dialog>
  )
}
