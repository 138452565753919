import { NodeViewProps } from "@tiptap/react"
import numeral from "numeral"

import { Stack } from "components/layout"
import { useModal } from "components/Modal"
import { Text } from "components/Text"
import { useGetMeasureQuery } from "graphql/types"
import { useProject } from "providers/Project"

import { EditorNode, EditorNodeWithControls } from "../EditorNode"

import styles from "./MetricsComponent.module.scss"

interface EditorMetricsComponentProps extends NodeViewProps {}

export const EditorMetricsComponent = (props: EditorMetricsComponentProps) => {
  const modal = useModal()
  const { projectId } = useProject()

  const { node, updateAttributes, deleteNode } = props
  const { metricId, measureId } = node.attrs

  const isConfigured = !!measureId

  const { data } = useGetMeasureQuery({
    variables: {
      projectId,
      metricId,
      id: measureId
    },
    skip: !isConfigured
  })

  if (!isConfigured) {
    return (
      <EditorNode>
        <div className={styles.component}>
          <Stack
            className={styles.addButton}
            distance={2}
            role="button"
            onClick={() => {
              modal.show("editor::edit-metric", {
                node,
                updateAttributes,
                deleteNode
              })
            }}
          >
            <div>
              <Text bold>Add metrics</Text>
            </div>
            <div className={styles.description}>
              Click to connect more metrics to this update
            </div>
          </Stack>
        </div>
      </EditorNode>
    )
  }

  return data ? (
    <EditorNodeWithControls {...props}>
      <div className={styles.component}>
        <div className={styles.metric}>
          <h5 className={styles.title}>{data.metric.name}</h5>
          <div className={styles.value}>
            {numeral(data.measure.value).format("0,0")}
          </div>
        </div>
      </div>
    </EditorNodeWithControls>
  ) : null
}
