import React, { forwardRef, InputHTMLAttributes } from "react"
import cn from "classnames"

import styles from "./Input.module.scss"

type InputAttributes = InputHTMLAttributes<HTMLInputElement>

export interface InputProps extends Omit<InputAttributes, "size"> {
  fill?: boolean
  size?: "medium" | "large"
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, fill, type = "text", size = "medium", ...props }, ref) => (
    <input
      className={cn(styles.container, styles[size], className)}
      type={type}
      ref={ref}
      {...props}
    />
  )
)

export default Input
