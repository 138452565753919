import { uploadFile } from "api"
import { useDropzone } from "react-dropzone"
import { useState } from "react"
import Spinner from "components/Spinner"

import styles from "./FormImage.module.scss"

export interface FormImageProps {
  url: string
  onChange: (value: string) => void
}

export const FormImage = ({ url, onChange }: FormImageProps) => {
  const [loading, setLoading] = useState(false)

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    maxFiles: 1,
    onDrop: async (acceptedFiles) => {
      const file = acceptedFiles[0]

      setLoading(true)

      const { asset } = await uploadFile(file)

      onChange(asset.url)
      setLoading(false)
    }
  })

  return (
    <div className={styles.frame}>
      <div className={styles.frameInner}>
        <div {...getRootProps({ className: styles.zone })}>
          <input {...getInputProps()} />
          <div
            className={styles.content}
            style={{ backgroundImage: `url(${url})` }}
          >
            {loading && <Spinner />}
          </div>
        </div>
      </div>
    </div>
  )
}
