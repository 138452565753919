import { useField } from "formik"

import { uploadFile } from "api"
import Button from "components/Button"
import { Row, Stack } from "components/layout"
import { useUpdatePhotoMutation } from "graphql/types"

import { upload } from "utils/upload"

import styles from "./FormImage.module.scss"

export interface FormImageProps {
  name: string
}

export const FormImage = ({ name }: FormImageProps) => {
  const [mutate] = useUpdatePhotoMutation()

  const [input, , helper] = useField(name)

  return (
    <Stack distance={4}>
      <div className={styles.frame}>
        <div className={styles.frameInner}>
          <div
            className={styles.content}
            style={{
              backgroundImage: `url(${input.value})`
            }}
          ></div>
        </div>
      </div>

      <Row fill distance={4}>
        <Button
          fill
          use="primary"
          type="button"
          onClick={async () => {
            const file = await upload("image/*")

            if (file) {
              try {
                const { asset } = await uploadFile(file)

                helper.setValue(asset.url)

                await mutate({
                  variables: {
                    input: {
                      photo: asset.url
                    }
                  }
                })
              } catch (error) {}
            }
          }}
        >
          Upload
        </Button>
        <Button
          type="button"
          onClick={async () => {
            helper.setValue("")

            await mutate({
              variables: {
                input: {
                  photo: ""
                }
              }
            })
          }}
        >
          Remove
        </Button>
      </Row>
    </Stack>
  )
}
