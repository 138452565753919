import { ReactNode, MouseEvent } from "react"
import cn from "classnames"

import Menu from "./Menu"
import Option from "./Option"
import Icon from "../Icon"
import { Row } from "../layout"

import styles from "./MenuList.module.scss"

export interface MenuListItem extends Option {
  icon?: string
  color?: string
  children?: MenuListItem[]
}

export interface MenuListProps<T extends MenuListItem> {
  items: T[]
  scrollable?: boolean
  children?: ReactNode | ((option: T, index: number) => ReactNode)
  onClick?: (
    option: T,
    index: number,
    sourceEvent: MouseEvent<HTMLLIElement>
  ) => void
}

const defaultChildren = (option: MenuListItem) => (
  <Row distance={2} style={{ color: option.color }}>
    {option.icon && <Icon name={option.icon} />}
    <span>{option.label}</span>
  </Row>
)

const MenuList = function <T extends MenuListItem>({
  items,
  scrollable,
  children = defaultChildren,
  onClick
}: MenuListProps<T>) {
  return (
    <Menu>
      <ul className={cn(styles.list, scrollable && styles.scrollable)}>
        {items.map((option, index) => (
          <li
            key={index}
            className={styles.item}
            onMouseDown={(event) => onClick?.(option, index, event)}
          >
            {typeof children === "function"
              ? children(option, index)
              : children}
          </li>
        ))}
      </ul>
    </Menu>
  )
}

export default MenuList
