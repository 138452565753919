import { EditorContent } from "@tiptap/react"
import { Editor } from "@tiptap/react"

import { EditorNewlineMenu } from "./EditorNewlineMenu"
import { EditorToolbar } from "./EditorToolbar"

import styles from "./PageWrapper.module.scss"

export interface PageWrapperProps {
  editor: Editor
}

export const PageWrapper = ({ editor }: PageWrapperProps) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <EditorToolbar editor={editor} />

        <div className={styles.pageWrapper}>
          <div className={styles.page}>
            <EditorNewlineMenu editor={editor} />

            <EditorContent
              className="text-editor"
              editor={editor}
              onClick={() => {
                editor?.view.focus()
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
