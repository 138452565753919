import { Row, Split } from "components/layout"
import { HeaderLogo } from "components/HeaderLogo"
import { Button } from "routes/Project/components"

import { useEditorStore } from "../EditorStore"

import styles from "./PageHeader.module.scss"
import { Text } from "components/Text"

export interface PageHeaderProps {
  onNext: () => void
}

export const PageHeader = ({ onNext }: PageHeaderProps) => {
  const { state } = useEditorStore()

  return (
    <header className={styles.container}>
      <Split>
        <div>
          <HeaderLogo />
        </div>

        <Row
          horizontal="right"
          distance={8}
          edges
          style={{
            height: "var(--editor-header-height)",
            paddingRight: 20
          }}
        >
          <div>
            <Text color="var(--placeholder-color)">
              {state.saving ? "Saving..." : ""}
            </Text>
          </div>
          <Button secondary onClick={onNext}>
            Save
          </Button>
        </Row>
      </Split>
    </header>
  )
}
