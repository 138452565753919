import { ReactNode } from "react"
import { Stack } from "./layout"
import { Text } from "./Text"

export interface TitleProps {
  description?: string
  children: ReactNode
}

export const Title = ({ children, description }: TitleProps) => (
  <Stack distance={2}>
    <h2 style={{ fontWeight: 600 }}>{children}</h2>
    {description && (
      <Text color="var(--secondary-text-color)">{description}</Text>
    )}
  </Stack>
)
