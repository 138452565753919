import { useLocation } from "react-router"
import { Location } from "history"
import qs from "qs"
import { useMemo } from "react"
import { isNil, mapValues, omitBy } from "lodash"

export interface LocationQuery<S> extends Location<S> {
  query: Record<string, string>
}

export const useLocationQuery = (): LocationQuery<unknown> => {
  const location = useLocation()

  const query = useMemo(() => {
    const data = qs.parse(location.search, {
      ignoreQueryPrefix: true
    })

    const serialized = mapValues(data, (v) => v?.toString())
    const filtered = omitBy(serialized, isNil)

    return filtered as Record<string, string>
  }, [location.search])

  return {
    ...location,
    query
  }
}
