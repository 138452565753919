import cn from "classnames"
import { Row } from "components/layout"
import { ReactNode } from "react"
import { Button } from "routes/Project/components/Button"
import { ButtonSpec } from "./buttons"
import styles from "./Dialog.module.scss"
import { Modal, ModalProps } from "./Modal"

export interface DialogWithAttributesProps<T extends Record<string, any>>
  extends ModalProps {
  title?: string
  attributes: T
}

export interface DialogProps extends ModalProps {
  title?: string
}

export interface TitleProps {
  className?: string
  message: string
}

export const Title = ({ className, message }: TitleProps) => (
  <div className={cn(styles.title, styles.element, className)}>{message}</div>
)

export const Description = ({ message }: any) => (
  <div className={cn(styles.description, styles.element)}>{message}</div>
)

export interface ElementProps {
  className?: string
  children: ReactNode
}

export const Element = ({ className, children }: ElementProps) => (
  <div className={cn(styles.element, className)}>{children}</div>
)

export interface FooterProps {
  loading?: boolean
  buttons: ButtonSpec[]
}

export const Footer = ({ buttons }: FooterProps) => (
  <div className={styles.element}>
    <Row distance={6} fill>
      {buttons.map((spec, index) => (
        <Button
          key={index}
          use={spec.style || "secondary"}
          type={spec.type || "button"}
          fill
          className={styles.button}
          onClick={() => {
            spec.action?.()
          }}
        >
          {spec.label}
        </Button>
      ))}
    </Row>
  </div>
)

export const Dialog = ({ ...props }: DialogProps) => (
  <Modal {...props} className={styles.dialog} />
)

export default Dialog
