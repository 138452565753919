import { Label } from "components/Label"
import { useField } from "formik"
import { Select, SelectProps } from "./Select"

export interface FormSelectProps
  extends Omit<SelectProps, "value" | "onChange"> {
  label?: string
  name: string
}

export const FormSelect = ({ name, label, ...props }: FormSelectProps) => {
  const [input, , helper] = useField(name)

  return (
    <Label message={label}>
      <Select
        {...props}
        value={input.value}
        onChange={(value) => helper.setValue(value)}
      />
    </Label>
  )
}
