import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Bar
} from "recharts"

import { Split, Stack } from "components/layout"
import Modal, { ModalProps } from "components/Modal"
import { useProject } from "providers/Project"

import { formatFriendlyDateTime } from "utils/date"
import { useGetMetricQuery } from "graphql/types"
import { parseISO } from "date-fns/esm"

export const MetricChart = ({ data = [] }: any) => {
  const items = data
    .map((item: any) => {
      const date = parseISO(item.date)

      return {
        ...item,
        date,
        label: formatFriendlyDateTime(date)
      }
    })
    .sort((prev: any, next: any) => {
      return prev.date.getTime() - next.date.getTime()
    })

  return (
    <div style={{ width: "100%", height: 300 }}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart width={500} height={300} data={items}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="label" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="value" barSize={20} fill="#bfbde7" />
          <Line
            type="monotone"
            dataKey="value"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}

export const MetricModal = ({ attributes, ...props }: ModalProps) => {
  const { projectId } = useProject()
  const { node } = attributes || {}

  const { data } = useGetMetricQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      projectId: projectId,
      metricId: node.attrs.metricId ?? ""
    }
  })

  const metric = data?.metric
  const measures = metric?.measures

  return (
    <Modal {...props} maxWidth={800}>
      {metric && (
        <Stack distance={10}>
          <Split>
            <h3>{metric.name}</h3>
            <div></div>
          </Split>

          <div>
            {measures && <MetricChart name={metric.name} data={measures} />}
          </div>
        </Stack>
      )}
    </Modal>
  )
}
