import cn from "classnames"
import { ReactNode } from "react"

import Icon from "components/Icon"

import styles from "./Controls.module.scss"

export interface ControlsContainerProps {
  className?: string
  children: ReactNode
}

export const ControlsContainer = ({
  className,
  children
}: ControlsContainerProps) => (
  <div className={cn(styles.container, className)}>
    <div className={styles.buttons}>{children}</div>
  </div>
)

export interface ControlsButtonProps {
  icon: string
  onMouseDown?: (v: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
}

export const ControlsButton = ({ icon, onMouseDown }: ControlsButtonProps) => (
  <Icon
    className={styles.button}
    name={icon}
    onMouseDownCapture={(event) => {
      event.preventDefault()
      event.stopPropagation()

      onMouseDown?.(event)
    }}
  />
)
