import { Row } from "components/layout"
import { useModal } from "components/Modal"
import {
  AnswerFragment,
  QuestionFragment,
  useRemoveAnswerMutation
} from "graphql/types"
import { useProject } from "providers/Project"
import { Button } from "routes/Project/components"

export interface OpenEndedAnswerProps {
  question: QuestionFragment
  answer: AnswerFragment
  onRemove: () => void
}

export const OpenEndedAnswer = ({
  question,
  answer,
  onRemove
}: OpenEndedAnswerProps) => {
  const [removeAnswer] = useRemoveAnswerMutation()

  const modal = useModal()
  const { clientId } = useProject()

  return (
    <Row distance={4} fill style={{ padding: "12px" }}>
      <div style={{ flex: 1 }}>{answer.value}</div>
      <Row distance={4}>
        <div>
          {!clientId && (
            <Button
              secondary
              onClick={() => {
                modal.show("editor::show-question-answers", {
                  question,
                  answer
                })
              }}
            >
              Show answers
            </Button>
          )}
        </div>

        <Button
          secondary
          onClick={async () => {
            await removeAnswer({
              variables: {
                projectId: question.project,
                questionId: question._id,
                answerId: answer._id
              }
            })

            onRemove()
          }}
        >
          Delete answer
        </Button>
      </Row>
    </Row>
  )
}
