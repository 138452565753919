import { useGetPostQuery } from "graphql/types"
import { Dictionary } from "lodash"
import { createContext, ReactNode, useContext } from "react"

import { useDiscussions } from "./useDiscussions"
import { useReactions } from "./useReactions"

export interface PostContext extends Dictionary<any> {}

const context = createContext<any>({})

export const usePostStore = () => {
  return useContext(context)
}

export const usePostStoreByReference = (ref: string) => {
  const context = usePostStore()

  if (!ref) {
    return null
  }

  const discussions = context.discussions?.[ref]
  const reactions = context.reactions?.[ref]

  return {
    id: context.id,
    discussions,
    reactions,
    createReaction: context.createReaction,
    removeReaction: context.removeReaction
  } as const
}

const Wrapper = context.Provider

interface PostStoreProps {
  projectId: string
  postId: string
  children: ReactNode
}

export const PostStore = ({ projectId, postId, children }: PostStoreProps) => {
  const { discussions, createDiscussion } = useDiscussions(postId)
  const { reactions, createReaction, removeReaction } = useReactions(postId)

  const { data } = useGetPostQuery({
    variables: {
      projectId,
      postId
    }
  })

  const post = data?.post ?? null

  const value = {
    id: postId,
    post,
    discussions,
    reactions,
    createDiscussion,
    createReaction,
    removeReaction
  }

  return <Wrapper value={value}>{children}</Wrapper>
}
