import { gql } from "@apollo/client"
import { USER_FRAGMENT } from "graphql/fragments"

export const CREATE_POST = gql`
  mutation CreatePost($projectId: ID!, $input: PostInput!) {
    createPost(projectId: $projectId, input: $input) {
      _id
      title
      content
      date
      summary
      createdAt
      updatedAt
      publishedAt
      project
      metadata
      createdBy {
        ...User
      }
    }
  }

  ${USER_FRAGMENT}
`

export const UPDATE_POST = gql`
  mutation UpdatePost($projectId: ID!, $postId: ID!, $input: PostInput!) {
    updatePost(projectId: $projectId, postId: $postId, input: $input) {
      _id
      title
      content
      date
      summary
      createdAt
      updatedAt
      publishedAt
      project
      metadata
      createdBy {
        ...User
      }
    }
  }

  ${USER_FRAGMENT}
`

export const PUBLISH_POST = gql`
  mutation PublishPost($projectId: ID!, $id: ID!) {
    publishPost(projectId: $projectId, id: $id) {
      _id
      title
      date
      summary
      content
      publishedAt
      updatedAt
      project
    }
  }
`
