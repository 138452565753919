import Card from "components/Card"
import { EmptyMessage } from "components/EmptyMessage/EmptyMessage"
import { Stack } from "components/layout"
import { useGetMetricsQuery } from "graphql/types"
import { useProject } from "providers/Project"

import { MetricInfo } from "./MetricInfo"

export const Metrics = () => {
  const { projectId } = useProject()

  const { data, loading } = useGetMetricsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      projectId
    }
  })

  const metrics = data?.metrics ?? []
  const items = metrics.filter((metric) => !!metric?.measures?.length)

  return (
    <Card>
      {!loading && items.length === 0 && (
        <EmptyMessage message="New metrics will appear here" />
      )}

      {!loading && items.length > 0 && (
        <Stack distance={10}>
          {items.map((metric) => (
            <MetricInfo
              key={metric._id}
              name={metric.name}
              measures={metric.measures ?? []}
            />
          ))}
        </Stack>
      )}
    </Card>
  )
}
