import { auth } from "./firebase"
import * as hooks from "react-firebase-hooks/auth"
import { useEffect } from "react"
import { useHistory, useLocation } from "react-router"

export const useAuthState = () => {
  return hooks.useAuthState(auth)
}

export const useRedirectVerified = (path: string) => {
  const history = useHistory()

  const [user, loading] = useAuthState()

  useEffect(() => {
    if (!loading && user?.emailVerified === true) {
      history.push(path)
    }
  }, [user, loading, history, path])
}

export const useRedirectUnauthenticated = () => {
  const location = useLocation()
  const history = useHistory()

  const [user, loading] = useAuthState()

  useEffect(() => {
    if (!loading && !user && !location.search.includes("clientId")) {
      history.push("/authenticate")
    }
  }, [user, loading, history, location.search])
}

export const useRedirectUnverified = () => {
  const history = useHistory()
  const [user, loading] = useAuthState()

  useEffect(() => {
    if (!loading && user?.emailVerified === false) {
      history.push("/verify")
    }
  }, [user, loading, history])
}

export const useSignInWithEmailAndPassword = () => {
  return hooks.useSignInWithEmailAndPassword(auth)
}
