import { useEffect } from "react"

export const setCSSVariable = (variableName: string, value: string) => {
  document.body.style.setProperty(`--${variableName}`, value)
}

export const removeCSSVariable = (variableName: string) => {
  document.body.style.removeProperty(`--${variableName}`)
}

export const useCSSVariables = (variables: Record<string, string>) => {
  useEffect(() => {
    const entries = Object.entries(variables)

    entries.forEach(([key, value]) => {
      setCSSVariable(key, value)
    })

    return () => {
      entries.forEach(([key]) => {
        removeCSSVariable(key)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(variables)])
}

export const useCSSVariable = (key: string, value: string) => {
  useEffect(() => {
    setCSSVariable(key, value)

    return () => {
      removeCSSVariable(key)
    }
  }, [key, value])
}
