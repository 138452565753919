import { keyBy } from "lodash"

export const REACTIONS = [
  {
    name: "fire",
    emoji: "🔥"
  },
  {
    name: "tada",
    emoji: "🎉"
  },
  {
    name: "eyes",
    emoji: "👀"
  },
  {
    name: "+1",
    emoji: "👍"
  },
  {
    name: "confused",
    emoji: "🤔"
  }
]

export const reactionsByName = keyBy(REACTIONS, "name")
