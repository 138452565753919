import { NodeViewProps } from "@tiptap/react"
import cn from "classnames"
import Icon from "components/Icon"
import { getIcon, sizable } from "./utils"
import { Reactable } from "../Reactable"

import styles from "./FileComponent.module.scss"

interface ViewerFileComponentProps extends NodeViewProps {}

export const ViewerFileComponent = ({ node }: ViewerFileComponentProps) => {
  const { name, size, type } = node.attrs

  return (
    <Reactable node={node}>
      <a
        className={cn(styles.file, styles.download)}
        download={node.attrs.name}
        href={node.attrs.src}
        title={node.attrs.name}
      >
        <div className={styles.icon}>
          <Icon size={20} name={getIcon(type)} />
        </div>
        <div className={styles.content}>
          <div className={styles.title}>{name}</div>
          <div className={styles.size}>
            {sizable(size)}{" "}
            <span className={styles.meta}>· Click to download</span>
          </div>
        </div>
      </a>
    </Reactable>
  )
}
