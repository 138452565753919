import Icon from "components/Icon"
import { throttle } from "lodash"
import { useRef, useState } from "react"
import AvatarEditor from "react-avatar-editor"
import Dropzone from "react-dropzone"
import styles from "./PictureEditor.module.scss"

export interface SimpleImageInputProps {
  initialValue?: string
  size?: number
  onChange: (blob: Blob | null) => void | Promise<void>
}

export const SimpleImageInput = ({
  initialValue = "",
  size = 250,
  onChange
}: SimpleImageInputProps) => {
  const [image, setImage] = useState<string | File>(initialValue)

  const editor = useRef<any>()

  const sync = useRef(
    throttle(() => {
      const fn = async () => {
        try {
          const canvas = editor.current.getImageScaledToCanvas().toDataURL()

          const response = await fetch(canvas)
          const blob = await response.blob()

          onChange(blob)
        } catch (error) {
          /**
           * This catch is usually triggered when
           */
        }
      }

      fn()
    }, 300)
  )

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <Dropzone
          accept="image/jpeg, image/png"
          noClick={!!image}
          onDrop={(files) => {
            setImage(files[0])
            sync.current()
          }}
          noKeyboard
        >
          {(dropzone) => (
            <div {...dropzone.getRootProps()} className={styles.checkerboard}>
              <AvatarEditor
                className={styles.avatar}
                ref={editor}
                border={0}
                width={size}
                height={size}
                image={image}
                onImageChange={() => {
                  sync.current()
                }}
              />
              <input {...dropzone.getInputProps()} />
            </div>
          )}
        </Dropzone>
        {image && (
          <div
            className={styles.removeButton}
            onClick={() => {
              setImage("")
              onChange(null)
            }}
          >
            <Icon size={14} name="ri-delete-bin-line" />
          </div>
        )}
      </div>
    </div>
  )
}
