import { ReactNode } from "react"

import { Split, Stack } from "components/layout"
import { Title } from "components/Title"

import styles from "./SingleBoxLayout.module.scss"

export interface SingleBoxLayoutProps {
  title?: string
  maxWidth?: number
  children?: ReactNode
  header?: ReactNode
  footer?: ReactNode
}

export const SingleBoxLayout = ({
  title,
  children,
  maxWidth,
  header,
  footer
}: SingleBoxLayoutProps) => (
  <div className={styles.wrapper}>
    <Stack distance={4} className={styles.body}>
      <Split vertical="top" className={styles.header}>
        <Title>{title}</Title>
        {header && <div>{header}</div>}
      </Split>

      <div className={styles.content} style={{ maxWidth }}>
        <div>{children}</div>
      </div>

      {footer && <div className={styles.footer}>{footer}</div>}
    </Stack>
  </div>
)
