import { HashIcon } from "components/HashIcon"
import { Text } from "components/Text"
import { formatDistanceToNow, parseISO } from "date-fns"
import {
  CommentFragment,
  Role,
  ThreadFragment,
  UserFragment,
  RoleFragment
} from "graphql/types"

import styles from "./Comments.module.scss"
import { Row, Split, Stack } from "components/layout"
import { KebabMenu } from "components/Menu"
import { CommentsEditor } from "./CommentsEditor"

export interface CommentProps {
  name: string
  photo?: string
  createdAt: Date
  message: string
  onDelete: Function
  canDelete: boolean
}

const Comment = ({
  name,
  photo,
  canDelete,
  createdAt,
  message,
  onDelete
}: CommentProps) => {
  return (
    <div className={styles.comment}>
      <div className={styles.row}>
        <div className={styles.cell}>
          <HashIcon size={32} value={name} url={photo} />
        </div>
        <Stack distance={4} className={styles.message}>
          <Split>
            <Text bold>{name}</Text>

            <Row>
              {canDelete && (
                <KebabMenu
                  options={[
                    {
                      label: "Delete",
                      value: "delete"
                    }
                  ]}
                  onSelect={(value) => {
                    switch (value) {
                      case "delete":
                        return onDelete()
                    }
                  }}
                />
              )}
            </Row>
          </Split>
          <div>{message}</div>
        </Stack>
      </div>
    </div>
  )
}

export interface CommentsProps {
  role: RoleFragment
  list: CommentFragment[]
  onSubmit: (value: string) => Promise<void>
  onDelete: (id: string) => Promise<void>
}

const owned = (role: RoleFragment, comment: CommentFragment) => {
  return role.client
    ? comment.clientId === role.client._id
    : comment.memberId === role.member?._id
}

export const Comments = ({ role, onSubmit, list, onDelete }: CommentsProps) => {
  return (
    <div>
      <CommentsEditor
        onSubmit={async (value) => {
          await onSubmit(value)
        }}
      />

      <div className={styles.comments}>
        {list.map((comment) => {
          const name = comment.user?.name || comment.client?.email || "User"
          const photo = comment.user?.photo
          const createdAt = parseISO(comment.createdAt)

          return (
            <Comment
              canDelete={owned(role, comment)}
              key={comment.id}
              name={name}
              photo={photo}
              createdAt={createdAt}
              message={comment.content}
              onDelete={() => onDelete(comment.id)}
            />
          )
        })}
      </div>
    </div>
  )
}
