import { NodeViewContent, NodeViewProps, NodeViewWrapper } from "@tiptap/react"
import cn from "classnames"
import { Datepicker } from "components/Datepicker/Datepicker"
import { Row } from "components/layout"
import { formatISO, parseISO } from "date-fns"
import { forwardRef } from "react"
import { formatFriendlyDate } from "utils/date"
import { BlockButton } from "./BlockButton"

import styles from "./Title.module.scss"

interface EditorTitleComponentProps extends NodeViewProps {}

interface DateBlockButtonProps {
  value?: string
  onClick?: () => void
}

const today = new Date()

const DateBlockButton = forwardRef<HTMLDivElement, DateBlockButtonProps>(
  ({ value, onClick }, ref) => (
    <BlockButton
      ref={ref}
      title="Date"
      value={formatFriendlyDate(value ? parseISO(value) : today)}
      onClick={onClick}
    />
  )
)

export const EditorTitleComponent = ({
  node,
  selected,
  updateAttributes
}: EditorTitleComponentProps) => {
  const empty = node.isTextblock && node.textContent === ""

  return (
    <NodeViewWrapper
      className={cn(
        styles.container,
        styles.editable,
        selected && styles.selected
      )}
    >
      {process.env.REACT_APP_ENABLE_TITLE_EXTENSION && (
        <Row distance={4} contentEditable={false} className={styles.blocks}>
          <BlockButton title="Author" value="Author Name" />
          <div>
            <Datepicker
              value={node.attrs.date || formatISO(today)}
              maxDate={today}
              onChange={(value) => {
                if (value instanceof Date) {
                  updateAttributes({
                    date: formatISO(value)
                  })
                }
              }}
              customInput={<DateBlockButton />}
            />
          </div>
        </Row>
      )}

      <NodeViewContent className={cn(styles.content, empty && styles.empty)} />
    </NodeViewWrapper>
  )
}
