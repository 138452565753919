import { PostFragment } from "graphql/types"
import cn from "classnames"

import { Row, Split, Stack } from "components/layout"
import { KebabMenu } from "components/Menu"
import { MicroTag } from "components/MicroTag/MicroTag"
import { Tag, TagColors } from "components/Tag"
import { SimpleTooltip, Tooltip } from "components/Tooltip"
import { useProject } from "providers/Project"
import { plural } from "utils/string"

import styles from "./Post.module.scss"

const options = [
  {
    label: "Remove",
    value: "remove"
  }
]

export interface PostProps {
  data: PostFragment
  onClick: () => void
  onRemove: () => void
}

const message = (quantity: number, name: string) => {
  return quantity + " " + plural(name, quantity)
}

export const Post = ({ data, onClick, onRemove }: PostProps) => {
  const { authenticated, self, preview } = useProject()

  const assets = data?.resources.map((resource) => resource.asset)
  const images = assets.filter((v: any) => v.mimeType.startsWith("image"))

  const views = data.views
  const draft = !data.publishedAt

  const canRemovePost =
    authenticated && !preview && data.createdBy._id === self._id

  return (
    <div className={cn(styles.post, draft && styles.draft)} onClick={onClick}>
      <Row distance={0} vertical="top">
        <Stack distance={10} className={styles.content}>
          <Split vertical="top">
            <div className={styles.title}>{data.title}</div>

            <Row distance={2}>
              {draft && <Tag text="DRAFT" color={TagColors.orange} />}

              {canRemovePost && (
                <KebabMenu
                  options={options}
                  onSelect={async (option) => {
                    if (option === "remove") {
                      return onRemove()
                    }
                  }}
                />
              )}
            </Row>
          </Split>

          {data.summary && <div>{data.summary}</div>}

          <div className={styles.footer}>
            <Split vertical="bottom">
              <Row distance={2}>
                <div
                  className={styles.asset}
                  style={{
                    background: data.createdBy.photo
                      ? `url("${data.createdBy.photo}") center center / cover no-repeat`
                      : "#fff"
                  }}
                />

                <div>{data.createdBy.name}</div>
              </Row>

              <Row distance={4}>
                {views > 0 && (
                  <SimpleTooltip
                    placement="top"
                    message={message(views, "view")}
                  >
                    <MicroTag icon="ri-eye-line" text={views.toString()} />
                  </SimpleTooltip>
                )}

                <div>
                  <Tooltip
                    placement="top"
                    message={message(assets.length, "asset")}
                  >
                    <Row distance={2}>
                      {images.map((asset) => (
                        <div
                          className={styles.asset}
                          key={asset._id}
                          style={{
                            background: asset.url
                              ? `url("${asset.url}") center center / cover no-repeat`
                              : "#fff"
                          }}
                        />
                      ))}
                    </Row>
                  </Tooltip>
                </div>
              </Row>
            </Split>
          </div>
        </Stack>
      </Row>
    </div>
  )
}
