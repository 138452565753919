import { Form, Formik } from "formik"

import Button from "components/Button"
import { FormInput } from "components/Input"
import { Row, Stack } from "components/layout"

import { FormImage } from "./FormImage"

export interface ProfileFormValues {
  email: string
  name: string
  photo: string
}

export interface ProfileFormProps {
  initialValues: ProfileFormValues
  onSubmit: (values: ProfileFormValues) => Promise<void>
}

export const ProfileForm = ({ initialValues, onSubmit }: ProfileFormProps) => {
  return (
    <Formik<ProfileFormValues>
      initialValues={initialValues}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {(props) => (
        <Form autoComplete="off">
          <Row distance={10} vertical="top">
            <FormImage name="photo" />

            <Stack distance={10} style={{ width: "100%", flex: 1 }}>
              <FormInput
                label="Email"
                autoComplete="off"
                disabled={true}
                name="email"
              />

              <Row fill distance={4} vertical="top">
                <FormInput label="Name" autoComplete="off" name="name" />
              </Row>

              <Row horizontal="right">
                <Button type="submit" size="large" loading={props.isSubmitting}>
                  Update
                </Button>
              </Row>
            </Stack>
          </Row>
        </Form>
      )}
    </Formik>
  )
}
