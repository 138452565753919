import { Node, mergeAttributes } from "@tiptap/core"
import { ReactNodeViewRenderer } from "@tiptap/react"

import { EditorMetricsComponent } from "./EditorMetricsComponent"
import { ViewerMetricsComponent } from "./ViewerMetricsComponent"

export interface MetricsOptions {
  HTMLAttributes: Record<string, any>
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    metrics: {
      setMetrics: () => ReturnType
    }
  }
}

export const Metrics = Node.create<MetricsOptions>({
  name: "metric",

  inline: false,

  group: "block",
  atom: true,

  addAttributes() {
    return {
      name: {
        default: null
      },

      value: {
        default: null
      },

      metricId: {
        default: null
      },

      measureId: {
        default: null
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: "metric"
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "metric",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)
    ]
  },

  addNodeView() {
    return ReactNodeViewRenderer(
      this.editor.isEditable ? EditorMetricsComponent : ViewerMetricsComponent
    )
  },

  addCommands() {
    return {
      setMetrics: () => {
        return ({ commands }) => {
          return commands.insertContent({
            type: this.name
          })
        }
      }
    }
  }
})
