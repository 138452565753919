import byteSize from "byte-size"
import { format } from "date-fns"

import { Split } from "components/layout"
import { AssetIcon } from "./AssetIcon"

import styles from "./AssetItem.module.scss"

export interface AssetItemProps {
  name: string
  url: string
  createdAt: string
  mime: string
  extension: string
  size: number
}

const prettifySize = (bytes: number) => {
  const { value, unit } = byteSize(bytes)
  return `${value} ${unit.toLowerCase()}`
}

export const AssetItem = ({
  name,
  url,
  mime,
  extension,
  createdAt,
  size
}: AssetItemProps) => {
  const prettySizeString = prettifySize(size)
  const prettyDate = format(new Date(createdAt), "dd/MM/yyyy")

  return (
    <div className={styles.container}>
      <a className={styles.link} download={name} href={url} title={name}>
        <div className={styles.asset}>
          <AssetIcon url={url} extension={extension} mime={mime} />

          <div className={styles.details}>
            <div className={styles.name}>{name}</div>
            <Split className={styles.footer}>
              <div className={styles.size}>
                {prettySizeString}{" "}
                <span className={styles.hint}>- Click to download</span>
              </div>
              <div className={styles.date}>{prettyDate}</div>
            </Split>
          </div>
        </div>
      </a>

      {/* <div className={styles.menu}>
        <KebabMenu
          options={[
            {
              label: "Pin",
              value: "pin"
            },
            {
              label: "Hide",
              value: "hide"
            }
          ]}
          onSelect={() => {}}
        />
      </div> */}
    </div>
  )
}
