import { useMutation } from "@apollo/client"
import { Form, Formik } from "formik"
import { useState } from "react"

import Button from "components/Button"
import { FormInput } from "components/Input"
import { Stack } from "components/layout"
import Card from "components/Card"
import { PublicPageLayout } from "components/PublicPageLayout"

import { formSchema } from "./schema"
import { RESET_PASSWORD } from "./operationts"
import { useHistory, useRouteMatch } from "react-router"
import toast from "react-hot-toast"
import { StringComplexity } from "components/StringComplexity"
import { ErrorMessage } from "components/ErrorMessage"
import { Text } from "components/Text"

interface ResetPasswordFormValues {
  password: string
  password2: string
}

const ResetToken = () => {
  const {
    params: { token }
  } = useRouteMatch<{ token: string }>()

  const history = useHistory()

  const [error, setError] = useState("")
  const [disabled, setDisabled] = useState(true)
  const [success, setSuccess] = useState(false)

  const [resetPassword] = useMutation(RESET_PASSWORD)

  return (
    <PublicPageLayout maxWidth={400}>
      <Card>
        <Formik<ResetPasswordFormValues>
          initialValues={{
            password: "",
            password2: ""
          }}
          validateOnChange={false}
          validationSchema={formSchema}
          onSubmit={async (values) => {
            const { password, password2 } = values

            if (password !== password2) {
              setError("Passwords do not match")
              return
            }

            try {
              const response = await resetPassword({
                variables: {
                  password,
                  token
                }
              })

              if (response.data?.resetPassword) {
                setSuccess(true)
                toast("⭐️ Success! Your password has been reset!")

                setTimeout(() => {
                  history.push("/")
                }, 2000)
              } else {
                setError("Oops! Something went wrong!")
              }
            } catch (error) {
              setError(error?.message)
            }
          }}
        >
          {(props) => (
            <Form>
              <Stack distance={8}>
                <h2>Reset password</h2>

                {success ? (
                  <Text>Password has been reset successfully. </Text>
                ) : (
                  <>
                    <Text>Enter new password for an associated account.</Text>

                    <FormInput
                      autoComplete="off"
                      label="New password"
                      name="password"
                      type="password"
                    />

                    <FormInput
                      autoComplete="off"
                      label="Confirm password"
                      name="password2"
                      type="password"
                    />

                    <StringComplexity
                      style={{ textAlign: "center" }}
                      value={props.values.password}
                      onUpdate={(complex) => setDisabled(!complex)}
                    />

                    <Button
                      type="submit"
                      size="large"
                      disabled={disabled}
                      loading={props.isSubmitting}
                    >
                      Reset password
                    </Button>

                    <ErrorMessage>{error}</ErrorMessage>
                  </>
                )}
              </Stack>
            </Form>
          )}
        </Formik>
      </Card>
    </PublicPageLayout>
  )
}

export default ResetToken
