import Icon from "components/Icon"
import { Stack } from "components/layout"
import { ModalManager, useModal } from "components/Modal"
import { SimpleTooltip } from "components/Tooltip"
import { useHistory, useLocation } from "react-router-dom"
import { AuthenticateModal } from "routes/Authenticate/AuthenticateModal"
import styles from "./Navigation.module.scss"
import { Personal } from "./Personal"

const openSupportChat = () => {
  // @ts-ignore
  window.$crisp?.push(["do", "chat:show"])
  // @ts-ignore
  window.$crisp?.push(["do", "chat:open"])
}

export const Navigation = () => {
  const history = useHistory()
  const location = useLocation()
  const modal = useModal()

  const isClient = location.search.includes("clientId=")

  return (
    <>
      <div className={styles.navigation}>
        <Stack distance={8} className={styles.column}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 37 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.svg}
            onClick={() => {
              if (isClient) {
                modal.show("authenticate")
              } else {
                history.push("/")
              }
            }}
          >
            <path
              className={styles.logo}
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.6952 0C7.6754 0 6.0381 1.63736 6.0381 3.65714V24.3123C5.9258 26.558 5.3069 28.3753 4.1813 29.7643C3.136 31.0515 1.7739 31.7968 0.0951996 32H6.0381H7.8666H33.3238C35.3436 32 36.9809 30.3626 36.9809 28.3429V3.65714C36.9809 1.63736 35.3436 0 33.3238 0H9.6952Z"
            />
          </svg>
        </Stack>
        <Stack distance={8} className={styles.column}>
          <Stack distance={4}>
            <SimpleTooltip placement="right" message="Help Center">
              <div className={styles.button} onClick={openSupportChat}>
                <Icon size={20} name="ri-information-line" />
              </div>
            </SimpleTooltip>
          </Stack>
          <Personal />
        </Stack>
      </div>

      <ModalManager name="authenticate" component={AuthenticateModal} />
    </>
  )
}
