import { Node, mergeAttributes } from "@tiptap/core"
import { ReactNodeViewRenderer } from "@tiptap/react"

import { ImageComponent } from "./ImageComponent"
import { ReadonlyImageComponent } from "./ReadonlyImageComponent"

export interface ImageOptions {
  inline: boolean
  HTMLAttributes: Record<string, any>
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    image: {
      setImage: (options: {
        assetId?: string
        src: string
        size?: "full" | "third" | "half"
      }) => ReturnType
    }
  }
}

export const inputRegex = /!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\)/

export const Image = Node.create<ImageOptions>({
  name: "image",
  inline: false,
  group: "block",
  draggable: true,

  addAttributes() {
    return {
      assetId: {
        default: null,
        parseHTML: (element) => {
          return {
            src: element.getAttribute("data-asset-id")
          }
        },
        renderHTML: (attributes) => {
          return {
            "data-asset-id": attributes.assetId
          }
        }
      },

      width: {
        default: null
      },

      height: {
        default: null
      },

      scale: {
        default: 1
      },

      src: {
        default: null
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: "image"
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "image",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)
    ]
  },

  addCommands() {
    return {
      setImage:
        (options) =>
        ({ commands, state }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options
          })
        }
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(
      this.editor.isEditable ? ImageComponent : ReadonlyImageComponent
    )
  }
})
