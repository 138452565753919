import { EditorNode } from "../EditorNode"
import { NodeViewContent, NodeViewProps } from "@tiptap/react"

export const EditorParagraphComponent = (props: NodeViewProps) => {
  const { node } = props

  return (
    <EditorNode>
      <NodeViewContent as="p" style={{ textAlign: node.attrs.textAlign }} />
    </EditorNode>
  )
}
