import { createRef, useEffect, useState } from "react"

import { TextReader } from "routes/Editor/TextReader"
import { ModalManager } from "components/Modal"
import { useIDs } from "routes/Editor/Editor/EditorStore"

import { FeedbackManager } from "./interactivity/FeedbackManager"
// import { PostViews } from "./PostViews/PostViews"
import { PostHero } from "./PostHero"
// import { PostComments } from "./PostComments"
import { PublishModal } from "./modals/PublishPost"
import { PublishBanner } from "./PublishBanner"
import { PostStore, usePostStore } from "./PostStore"
import { MetricModal } from "./modals/ViewMetric"

import styles from "./Post.module.scss"
import { Feedback } from "./Feedback"

export const useWidthObserver = function <T extends HTMLElement>(
  callback: (rect: DOMRectReadOnly) => void
) {
  const ref = createRef<T>()

  useEffect(() => {
    let observer = new ResizeObserver((entries) => {
      // Only care about the first element, we expect one element ot be watched
      // const { width, height } = entries[0].contentRect
      // console.log(width, height)
      if (entries.length > 0) {
        callback(entries[0].contentRect)
      }
    })

    const element = ref.current

    if (element) {
      observer.observe(element)
    }

    return () => {
      if (element) {
        observer.unobserve(element)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref])

  return ref
}

// const Container = ({ children }: any) => {
//   const ref = createRef<HTMLDivElement>()

//   return (
//     <div ref={ref} className={styles.container}>
//       <div className={styles.sectionLeftSide}></div>
//       <div className={styles.content}></div>
//       <div className={styles.sectionRightSide}></div>
//     </div>
//   )
// }

const Post = () => {
  // const { self } = useSelf()
  const { post } = usePostStore()

  const [isReady, setReady] = useState(false)

  const isDraft = !post?.publishedAt
  // const isAuthor = self?._id === post?.createdBy?._id

  const container = useWidthObserver<HTMLDivElement>((rect) => {
    console.log("Container", rect.width)
  })

  const content = useWidthObserver<HTMLDivElement>((rect) => {
    console.log("Content", rect.width)
  })

  return post ? (
    <div className={styles.wrapper}>
      <div ref={container} className={styles.container}>
        <PostHero post={post} />

        <div ref={content} className={styles.content}>
          {/* {isAuthor && <PostViews postId={post._id} />} */}
          {isDraft && <PublishBanner post={post} />}

          <div className={styles.text}>
            <TextReader
              value={post.content}
              onCreate={() => {
                setTimeout(() => {
                  setReady(true)
                }, 300)
              }}
            />
          </div>

          {isReady && !isDraft && <FeedbackManager />}
        </div>

        {isDraft ? null : <Feedback />}
      </div>
    </div>
  ) : null
}

const PostRoute = () => {
  const { postId, projectId } = useIDs()

  return (
    <PostStore projectId={projectId} postId={postId}>
      <Post />

      <ModalManager component={PublishModal} name="publish" />
      <ModalManager component={MetricModal} name="reader::metric" />
    </PostStore>
  )
}

export default PostRoute
