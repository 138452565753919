import { ApolloClient, from, InMemoryCache } from "@apollo/client"
import { RetryLink } from "@apollo/client/link/retry"
import { auth } from "./auth"
import { errors } from "./errors"
import { http } from "./http"

const retry = new RetryLink({
  attempts: {
    max: 3
  }
})

const link = from([errors, retry, auth, http])

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    typePolicies: {
      ReactionGroup: {
        fields: {
          reactions: {
            merge(existing = [], incoming: any[]) {
              return incoming
            }
          }
        }
      }
    }
  })
})
