import { Editor } from "@tiptap/core"
import { EditorState } from "prosemirror-state"
import { EditorView } from "prosemirror-view"

export type ShouldShowFunction = (props: {
  editor: Editor
  view: EditorView
  state: EditorState
  oldState?: EditorState
}) => boolean

export const shouldShow: ShouldShowFunction = ({ editor, state }) => {
  const { selection, schema } = state
  const { $anchor, empty } = selection

  const { paragraph } = schema.nodes

  const isParagraph = $anchor.parent.type === paragraph
  const isRootDepth = $anchor.depth === 1

  const isEmptyTextBlock =
    $anchor.parent.isTextblock && !$anchor.parent.textContent
  // !$anchor.parent.type.spec.code &&

  /**
   * TODO: There is a bug when file is inserted - instead of focusing on the next paragraph -
   * editor is focusing on "doc" (with depth 0). Not sure ow to fix it.
   *
   * Implications: Plus button does not show after inserting file (sometimes).
   */

  const shouldShow: boolean =
    !editor.isFocused ||
    !empty ||
    !isParagraph ||
    !isRootDepth ||
    !isEmptyTextBlock

  console.groupCollapsed(`Menu: ${!shouldShow}`)
  console.log("Should show flag", shouldShow)
  console.log("Editor is focused", editor.isFocused)
  console.log("Selection is empty", empty)
  console.log("Selection is on paragraph", isParagraph, $anchor.parent.type)
  console.log("Selection is empty text", isEmptyTextBlock, $anchor.parent)
  console.log("Selection depth is", $anchor.depth)
  console.groupEnd()

  if (shouldShow) {
    return false
  }

  return true
}
