import { gql } from "@apollo/client"
import { USER_FRAGMENT } from "graphql/fragments"

export const REMOVE_CLIENT = gql`
  mutation RemoveClient($projectId: ID!, $id: ID!) {
    removeClient(projectId: $projectId, id: $id) {
      _id
    }
  }
`

export const GET_CLIENTS = gql`
  query GetClients($id: ID!) {
    project(id: $id) {
      _id

      clients {
        _id
        email

        user {
          ...User
        }
      }
    }
  }

  ${USER_FRAGMENT}
`
