export const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"

export const abbreviate = (value: string) => {
  return value
    .split(" ")
    .map((partial) => partial.trim().charAt(0) ?? "")
    .filter(Boolean)
    .join("")
    .substr(0, 2)
    .toUpperCase()
}

export const plural = (value: string, count: number) => {
  return value + (count === 1 ? "" : "s")
}

export const numberToLetters = (value: number) => {
  let result = ""
  let temp

  value += 1

  while (value > 0) {
    temp = (value - 1) % 26
    result = String.fromCharCode(65 + temp) + result
    value = ((value - temp) / 26) | 0
  }

  return result
}

/**
  Third-grade students = 150 words per minute (wpm)
  Eighth grade students = 250 wpm
  Average college student = 450 wpm
  Average "high-level exec" = 575 wpm
  Average adult = 300 wpm
 */
const WORDS_PER_MINUTE = 255
/**
 *
 * @param value - text of an article
 * @param min - minimum duration (by default - minimum duration is 1 minute)
 * @returns
 */
export const durationOfReading = (value: string, min: number = 1) => {
  const words = value.trim().split(/\s+/).length
  const time = Math.ceil(words / WORDS_PER_MINUTE)

  return Math.max(min, time)
}
