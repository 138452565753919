import React, { ReactNode, HTMLAttributes, forwardRef } from "react"
import cn from "classnames"

import styles from "./Split.module.scss"

interface SplitProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  vertical?: "top" | "center" | "bottom" | "baseline"
}

const Split = forwardRef<HTMLDivElement, SplitProps>(
  ({ className, vertical, children, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(styles.split, vertical && styles[vertical], className)}
      {...props}
    >
      {children}
    </div>
  )
)

export default Split
