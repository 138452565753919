import { useEditor, EditorContent } from "@tiptap/react"
import Document from "@tiptap/extension-document"
import Text from "@tiptap/extension-text"
import Bold from "@tiptap/extension-bold"
import Italic from "@tiptap/extension-italic"
import Underline from "@tiptap/extension-underline"
import TextAlign from "@tiptap/extension-text-align"
import BulletList from "@tiptap/extension-bullet-list"
import OrderedList from "@tiptap/extension-ordered-list"
import ListItem from "@tiptap/extension-list-item"
import Link from "@tiptap/extension-link"
import HorizontalRule from "@tiptap/extension-horizontal-rule"
import Heading from "@tiptap/extension-heading"

import { File } from "./extensions/File"
import { Image } from "./extensions/Image"
import { Paragraph } from "./extensions/Paragraph"
import { Question } from "./extensions/Question"
import { Embed } from "./extensions/Embed"
import { Metrics } from "./extensions/Metrics"
import { Indexer } from "./extensions/Indexer"
import { Title } from "./extensions/Title"

import "./styles.scss"

export const TextReader = ({ value, onCreate }: any) => {
  const editor = useEditor({
    editable: false,
    extensions: [
      Title,
      Document,
      Heading.configure({
        levels: [1, 2]
      }),
      Paragraph,
      Text,
      Bold,
      Italic,
      Underline,
      Link,
      HorizontalRule,
      TextAlign.configure({
        types: ["paragraph"]
      }),
      Image.configure({
        inline: false
      }),
      File,
      BulletList,
      OrderedList,
      ListItem,
      Question,
      Embed,
      Metrics,
      Indexer.configure({
        field: "id",
        types: ["paragraph", "file", "image", "question", "metric", "embed"]
      })
    ],
    content: value,
    onCreate() {
      onCreate()
    }
  })

  return (
    <div>
      <EditorContent className="text-reader" editor={editor} />
    </div>
  )
}
