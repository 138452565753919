import { ReactNode } from "react"
import { Helmet } from "react-helmet"

import { useProject } from "providers/Project"
import { Header } from "./Header"

import styles from "./Layout.module.scss"

export interface ProjectLayoutProps {
  children: ReactNode
}

export const Layout = ({ children }: ProjectLayoutProps) => {
  const context = useProject()

  return (
    <div className={styles.view}>
      <Helmet>
        <title>Shareback | {context.project.name}</title>
      </Helmet>

      <div className={styles.body}>
        <Header />

        <div className={styles.bodyInner}>
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </div>
  )
}
