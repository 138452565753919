import { Editor } from "@tiptap/react"
import { useGetPostQuery } from "graphql/types"
import { useEffect } from "react"
import { useEditorStore } from "."
import { palette } from "../palette"

export const useInitializer = (
  projectId: string,
  postId: string,
  editor: Editor
) => {
  const { state, dispatch } = useEditorStore()

  useGetPostQuery({
    variables: {
      projectId,
      postId
    },

    onCompleted: ({ post }) => {
      const title = post.title || ""
      const content = post.content || ""
      const titleColor = post.metadata?.titleBackgroundColor || palette[0]

      dispatch({
        type: "initialize",
        value: {
          initialContent: content,
          initialTitle: title,
          title,
          content,
          titleColor
        }
      })

      console.log("> Content fetched from the server")
    }
  })

  useEffect(() => {
    if (state.initialContent) {
      editor.commands.setContent(state.initialContent)

      console.log("> Content pushed into editor")
    }
  }, [editor, state.initialContent])
}
