import styles from "./ProjectImage.module.scss"

export interface ProjectImageProps {
  logo: string
}

export const ProjectImage = ({ logo }: ProjectImageProps) => (
  <div className={styles.container}>
    <div
      className={styles.image}
      style={{
        color: `var(--theme-color)`,
        backgroundImage: `url(${logo})`
      }}
    ></div>
  </div>
)
