import { PostFragment, useRemovePostMutation } from "graphql/types"
import toast from "react-hot-toast"
import { useHistory } from "react-router"
import { useProject } from "providers/Project"

import { Post } from "./Post"

export interface EventPostProps {
  data: PostFragment
}

export const EventPost = ({ data }: EventPostProps) => {
  const history = useHistory()
  const { projectId } = useProject()

  const [mutate] = useRemovePostMutation()

  const click = () => {
    const postId = data._id
    const search = window.location.search

    const url = `/projects/${projectId}/posts/${postId}${search}`

    history.push(url)
  }

  const remove = async () => {
    const confirmation = window.confirm(
      "Are you sure you want to remove this shareback?"
    )

    if (!confirmation) {
      return
    }

    try {
      await mutate({
        variables: {
          projectId: data.project,
          id: data._id
        },
        update(cache, result) {
          const id = cache.identify({ __typename: "Post", id: data._id })

          if (id) {
            cache.evict({ id })
            cache.gc()
          }
        }
      })

      toast("👋 Update has been removed")
    } catch (error) {
      toast(
        "Oops! There was a problem with deleting this update, please try again"
      )
    }
  }

  return <Post data={data} onClick={click} onRemove={remove} />
}
