import cn from "classnames"
import Icon from "components/Icon"
import { abbreviate } from "utils/string"
import styles from "./HashIcon.module.scss"

export interface HashIconProps {
  value: string
  icon?: string
  color?: string
  size?: "small" | "medium" | "large" | number
  url?: string
  mask?: string
  inline?: boolean
}

const colors = ["#5e9dff", "#2d2c4b", "#feab41", "#fc6680", "#9390d9"]

const hash = (value: string) => {
  let hash = 0
  let char

  if (value.length === 0) {
    return hash
  }

  for (let i = 0; i < value.length; i++) {
    char = value.charCodeAt(i)

    hash = (hash << 5) - hash + char
    hash |= 0
  }

  return hash
}

export const getHash = (string: string) => {
  const initials = abbreviate(string)
  const number = hash(initials) % colors.length

  return [initials, number] as const
}

export const HashIcon = ({
  size = "large",
  value,
  icon,
  url,
  color,
  mask = "squircle",
  inline = false
}: HashIconProps) => {
  const [initials, index] = getHash(value)

  const backgroundColor = color || colors[index]

  return (
    <div
      className={cn(
        styles.icon,
        "mask",
        mask,
        size && styles[size],
        inline && styles.inline
      )}
      style={{
        backgroundColor: url ? undefined : backgroundColor,
        backgroundImage: url ? `url(${url})` : undefined,
        height: typeof size == "number" ? size : undefined,
        width: typeof size === "number" ? size : undefined
      }}
    >
      {!url && <div>{icon ? <Icon name={icon} color="#fff" /> : initials}</div>}
    </div>
  )
}
