import { useRouteMatch } from "react-router"

export type ProjectRouteMatchParams = {
  id: string
  projectId: string
  postId: string
}

export const useIDs = () => {
  const route = useRouteMatch<ProjectRouteMatchParams>()
  const { projectId = "", postId = "", id = "" } = route.params

  return {
    projectId: projectId || id,
    postId
  } as const
}
