import React from "react"
import ReactDOM from "react-dom"
import ReactModal from "react-modal"

import "react-modal/dist/react-modal"

import "./styles/index.scss"

import { App } from "./App"

const root = document.getElementById("root")

ReactModal.setAppElement(root)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  root
)
