import { Reference } from "@apollo/client"
import {
  useCreateReactionMutation,
  useGetReactionsQuery,
  useRemoveReactionMutation
} from "graphql/types"
import { chain } from "lodash"
import { useProject } from "providers/Project"

export const groupMap = <T>(items: T[] = [], group: string, map: any) => {
  return chain(items).groupBy(group).mapValues(map).value()
}

const groupByReference = (items: any[] = []) => {
  return groupMap(items, "reference", (group: any) => {
    return groupMap(group, "value", (subgroup: any) => {
      return {
        count: subgroup.length,
        self: subgroup.find((v: any) => v.self)?._id
      }
    })
  })
}

export const useReactions = (postId: string) => {
  const { projectId } = useProject()

  const query = useGetReactionsQuery({
    variables: {
      projectId,
      postId
    }
  })

  const [remove] = useRemoveReactionMutation({
    update(cache, { data }) {
      const id = data?.removeReaction._id

      if (!id) {
        return
      }

      cache.modify({
        id: `ReactionGroup:${postId}`,
        fields: {
          reactions(refs = [], { readField }) {
            return refs.filter((ref: Reference) => id !== readField("_id", ref))
          }
        }
      })
    }
  })

  const [create] = useCreateReactionMutation({
    /**
     * Custom update function adds ref to the list of reactions. This allows us not to refetch
     */
    update(cache, { data }) {
      const mutation = data?.createReaction

      if (!mutation) {
        return
      }

      cache.modify({
        id: `ReactionGroup:${postId}`,
        fields: {
          reactions(refs = [], { readField }) {
            const ref = cache.identify(mutation)

            const output = refs.filter(
              (ref: Reference) => mutation._id !== readField("_id", ref)
            )

            if (output.length < refs.length) {
              return refs
            }

            return [
              ...output,
              {
                __ref: ref
              }
            ]
          }
        }
      })
    }
  })

  const createReaction = async (reference: string, reaction: string) => {
    return create({
      variables: {
        projectId,
        postId,
        input: {
          reference,
          value: reaction
        }
      }
    })
  }

  const removeReaction = async (id: string) => {
    return remove({
      variables: {
        projectId,
        postId,
        id
      }
    })
  }

  const reactions = groupByReference(query.data?.reactionGroup.reactions ?? [])

  return { reactions, createReaction, removeReaction }
}
