import { AUTHORIZATION } from "api"
import {
  getIdToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  UserCredential
} from "firebase/auth"
import { auth, googleAuthProvider } from "./firebase"

const storeIdToken = (token: string = "") => {
  window.localStorage.setItem(AUTHORIZATION, token)
}

export const refreshToken = async (force?: boolean) => {
  const user = auth.currentUser

  if (!user) {
    return
  }

  try {
    const token = await user.getIdToken(force)

    storeIdToken(token)

    console.log(
      `[Auth] [refreshToken] ID Token has been ${
        force ? "refreshed" : "fetched"
      }`
    )
  } catch (error) {
    console.log(error)
  }
}

export const initialize = () => {
  auth.onIdTokenChanged(async (user) => {
    const token = await user?.getIdToken()

    if (token) {
      storeIdToken(token)
    }
  })

  window.addEventListener("focus", async () => {
    await refreshToken(true)
  })
}

const wrap = async (getCredentials: () => Promise<UserCredential>) => {
  if (auth.currentUser) {
    await auth.signOut()
  }

  const credentials = await getCredentials()

  if (credentials) {
    return await getIdToken(credentials.user)
  }

  return null
}

export const authenticateWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  return wrap(async () => signInWithEmailAndPassword(auth, email, password))
}

export const authenticateWithGoogle = async () => {
  return wrap(async () => signInWithPopup(auth, googleAuthProvider))
}
