import cn from "classnames"
import { Label } from "components/Label"
import { useField } from "formik"
import { Textarea, TextareaProps } from "./Textarea"
import styles from "./Textarea.module.scss"

export interface FormTextareaProps
  extends Omit<TextareaProps, "value" | "onChange"> {
  name: string
  label?: string
  required?: boolean
  format?: (value: string) => string
}

export const FormTextarea = ({
  label,
  name,
  className,
  format,
  required,
  ...props
}: FormTextareaProps) => {
  const [input, meta, helper] = useField(name)

  const error = meta.error && meta.touched

  return (
    <Label message={label}>
      <Textarea
        autoComplete="off"
        {...props}
        name={name}
        className={cn(className, error && styles.error)}
        value={input.value}
        onBlur={input.onBlur}
        onChange={(event) => {
          const value = event.target.value
          const $value = typeof format === "function" ? format(value) : value

          helper.setValue($value)
        }}
      />

      {error && <div className={styles.errorMessage}>{meta.error}</div>}
    </Label>
  )
}
