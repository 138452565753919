import { Row, Stack } from "components/layout"
import { forwardRef } from "react"

import styles from "./BlockButton.module.scss"

export interface BlockButtonProps {
  title: string
  value: string
  onClick?: () => void
}

export const BlockButton = forwardRef<HTMLDivElement, BlockButtonProps>(
  ({ title, value, onClick }, ref) => {
    return (
      <Row
        ref={ref}
        distance={4}
        className={styles.block}
        onClick={() => onClick?.()}
      >
        <Stack distance={1}>
          <div className={styles.name}>{title}:</div>
          <div className={styles.value}>{value}</div>
        </Stack>
      </Row>
    )
  }
)
