import { HTMLAttributes } from "react"

export interface TextProps extends HTMLAttributes<HTMLSpanElement> {
  align?: "left" | "right" | "center"
  bold?: boolean
  lineHeight?: number
  size?: number
}

export const Text = ({
  children,
  align = "left",
  bold = false,
  lineHeight,
  color,
  size,
  style = {},
  ...props
}: TextProps) => (
  <span
    style={{
      textAlign: align,
      fontWeight: bold ? 500 : undefined,
      fontSize: size,
      lineHeight,
      color,
      ...style
    }}
    {...props}
  >
    {children}
  </span>
)
