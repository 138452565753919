import cn from "classnames"
import { NodeViewWrapper, NodeViewProps } from "@tiptap/react"

import { Anchor } from "./Anchor"
import { IFrame } from "./IFrame"
import { Reactable } from "../Reactable"

import styles from "./EmbedComponent.module.scss"

export const ViewerEmbedComponent = ({ node }: NodeViewProps) => {
  const { anchor, iframe } = node.attrs

  return (
    <Reactable node={node}>
      <NodeViewWrapper
        className={cn(styles.wrapper)}
        data-node-id={node.attrs.id}
        contentEditable="false"
        suppressContentEditableWarning
      >
        {iframe && <IFrame {...iframe} />}
        {anchor && <Anchor {...anchor} />}
      </NodeViewWrapper>
    </Reactable>
  )
}
