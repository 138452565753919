import { Editor } from "@tiptap/core"
import cn from "classnames"

import { Row, Split } from "components/layout"

import { Separator } from "./Separatior"
import { Button } from "./Button"

import styles from "./EditorToolbar.module.scss"

export interface PageControlsProps {
  editor: Editor | null
}

export const EditorToolbar = ({ editor }: PageControlsProps) => {
  const selectedNodeTypeName = editor?.state.selection.$anchor.parent.type.name

  const isTitleFocused = selectedNodeTypeName === "title"
  const isHeadingFocused = selectedNodeTypeName === "heading"

  return (
    <nav className={styles.container}>
      <Split className={cn(styles.bar, isTitleFocused && styles.disabled)}>
        <Row>
          <Button
            mark="bold"
            tooltip="Bold"
            editor={editor}
            icon="ri-bold"
            onClick={() => {
              editor?.chain().focus().toggleBold().run()
            }}
          />

          <Button
            mark="italic"
            tooltip="Italic"
            editor={editor}
            icon="ri-italic"
            onClick={() => {
              editor?.chain().focus().toggleItalic().run()
            }}
          />

          <Button
            mark="underline"
            tooltip="Underline"
            editor={editor}
            icon="ri-underline"
            onClick={() => {
              editor?.chain().focus().toggleUnderline().run()
            }}
          />

          <Separator />

          <Button
            mark="heading"
            markAttributes={{ level: 1 }}
            tooltip="Heading 1"
            editor={editor}
            icon="ri-h-1"
            onClick={() => {
              editor?.chain().focus().toggleHeading({ level: 1 }).run()
            }}
          />

          <Button
            mark="heading"
            tooltip="Heading 2"
            markAttributes={{ level: 2 }}
            editor={editor}
            icon="ri-h-2"
            onClick={() => {
              editor?.chain().focus().toggleHeading({ level: 2 }).run()
            }}
          />

          <Button
            editor={editor}
            mark=""
            disabled={editor?.state.selection.empty}
            tooltip={editor?.isActive("link") ? "Unlink" : "Link"}
            icon={editor?.isActive("link") ? "ri-link-unlink-m" : "ri-link-m"}
            onClick={() => {
              if (!editor) {
                return
              }

              const isLink = editor.isActive("link")

              if (isLink) {
                editor.chain().focus().unsetLink().run()

                return
              }

              const previousUrl = editor.getAttributes("link").href
              const url = window.prompt("URL", previousUrl)

              // cancelled
              if (url === null) {
                return
              }

              // empty
              if (url === "") {
                editor.chain().focus().extendMarkRange("link").unsetLink().run()

                return
              }

              // update link
              editor
                .chain()
                .focus()
                .extendMarkRange("link")
                .setLink({ href: url })
                .run()
            }}
          />

          <Separator />

          <Button
            mark={{ textAlign: "left" }}
            tooltip="Align left"
            editor={editor}
            disabled={isHeadingFocused}
            icon="ri-align-left"
            onClick={() => {
              editor?.chain().focus().setTextAlign("left").run()
            }}
          />

          <Button
            mark={{ textAlign: "center" }}
            tooltip="Align center"
            editor={editor}
            disabled={isHeadingFocused}
            icon="ri-align-center"
            onClick={() => {
              editor?.chain().focus().setTextAlign("center").run()
            }}
          />

          <Button
            mark={{ textAlign: "right" }}
            tooltip="Align right"
            disabled={isHeadingFocused}
            editor={editor}
            icon="ri-align-right"
            onClick={() => {
              editor?.chain().focus().setTextAlign("right").run()
            }}
          />

          <Separator />

          <Button
            mark="bulletList"
            tooltip="Bullet list"
            editor={editor}
            icon="ri-list-unordered"
            onClick={() => {
              editor?.chain().focus().toggleBulletList().focus().run()
            }}
          />
          <Button
            editor={editor}
            tooltip="Ordered list"
            mark="orderedList"
            icon="ri-list-ordered"
            onClick={() => {
              editor?.chain().focus().toggleOrderedList().focus().run()
            }}
          />
        </Row>
      </Split>
    </nav>
  )
}
