import styles from "./EmptyMessage.module.scss"

export interface EmptyMessageProps {
  message: string
}

export const EmptyMessage = ({ message }: EmptyMessageProps) => (
  <div className={styles.container}>
    <span>{message}</span>
  </div>
)
