import { useHistory } from "react-router"
import { Editor as IEditor } from "@tiptap/react"

import { ModalManager } from "components/Modal"

import { EditFile } from "./modals/EditFile"
import { EditMetrics } from "./modals/EditMetrics"
import { EditorStore, useIDs } from "./EditorStore"
import { EditQuestion } from "./modals/EditQuestion"
import { PageHeader } from "./PageHeader"
import { PageWrapper } from "./PageWrapper"
import { useTextEditor } from "../TextEditor"

import styles from "./Editor.module.scss"

export const Editor = () => {
  const editor = useTextEditor()

  return (
    <>
      {editor ? (
        <EditorStore editor={editor}>
          <EditorBody editor={editor} />
        </EditorStore>
      ) : null}

      <ModalManager component={EditFile} name="editor::edit-file" />
      <ModalManager component={EditQuestion} name="editor::edit-question" />
      <ModalManager component={EditMetrics} name="editor::edit-metric" />
    </>
  )
}

export interface PageBodyProps {
  editor: IEditor
}

const EditorBody = ({ editor }: PageBodyProps) => {
  const { projectId, postId } = useIDs()
  const history = useHistory()

  return (
    <div className={styles.container}>
      <PageHeader
        onNext={async () => {
          history.push(`/projects/${projectId}/posts/${postId}`)
        }}
      />
      <PageWrapper editor={editor} />
    </div>
  )
}
