const config = {
  graphqlUrl: "https://gateway-b5ppydp2xa-nw.a.run.app",
  graphqlSubscriptionUrl: "ws://gateway-b5ppydp2xa-nw.a.run.app/subscriptions",
  apiUrl: "https://api-b5ppydp2xa-nw.a.run.app",

  firebase: {
    apiKey: "AIzaSyAXa3mx0ztqYjWWdDTYUxXgycmdx5S8T24",
    authDomain: "production-315821.firebaseapp.com"
  }
}

export default config
