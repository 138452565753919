import { Link } from "react-router-dom"

import { Logo } from "./Logo"

import styles from "./Logo.module.scss"

export const LogoLink = () => (
  <Link to="/" className={styles.logo}>
    <Logo position="relative" height={20} />
  </Link>
)
