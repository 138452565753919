import { useState } from "react"
import { useEffect } from "react"

import { FeedbackMenu } from "./FeedbackMenu"

import { usePostStore } from "../PostStore"

/**
 * This component does multiple things:
 * - Fetches data for discussions and reactions
 * - Caches all HTML nodes that have node IDs (aka can have reactions and discussions attached to them)
 * - Creates "rulers" - absolutely positioned lines which will contain discussion and reaction nodes
 */
export const FeedbackManager = () => {
  const { createDiscussion, createReaction } = usePostStore()
  const [targets, setTargets] = useState<HTMLElement[]>([])

  useEffect(() => {
    const elements = Array.from(document.querySelectorAll("[data-node-id]"))

    setTargets(elements as HTMLElement[])
  }, [])

  return (
    <div>
      {targets.map((target, index) => (
        <FeedbackMenu
          key={index}
          reference={target}
          onReaction={async (id: string, reaction: any) => {
            await createReaction(id, reaction.name)
          }}
          onDiscussion={async (id: string, message: string) => {
            await createDiscussion(id, message)
          }}
        />
      ))}
    </div>
  )
}
