import { Label } from "components/Label"
import { formatISO, parseISO } from "date-fns"
import { useField } from "formik"
import { ReactDatePickerProps } from "react-datepicker"
import { Datepicker } from "./Datepicker"

export interface FormDatepickerProps
  extends Omit<ReactDatePickerProps, "selected" | "onChange"> {
  name: string
  label?: string
}

export const FormDatepickerISO = ({
  label,
  name,
  ...props
}: FormDatepickerProps) => {
  const [input, , helper] = useField(name)

  return (
    <Label message={label}>
      <Datepicker
        selected={parseISO(input.value)}
        onChange={(date) => {
          if (date instanceof Date) {
            helper.setValue(formatISO(date))
          }
        }}
        {...props}
      />
    </Label>
  )
}
