import {
  createEmptyParagraphAfterNode,
  createAssetWithMessages
} from "routes/Editor/utils"

const getSuggestionItems = ({ query }) => {
  return [
    {
      title: "Image",
      command: async ({ editor, range }) => {
        await createAssetWithMessages("image/*", (asset) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .setImage({
              assetId: asset._id,
              src: asset.url
            })
            .run()

          createEmptyParagraphAfterNode(editor)
        })
      }
    },
    {
      title: "File",
      command: async ({ editor, range }) => {
        await createAssetWithMessages("*/*", (asset, file) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .setFileInfo({
              assetId: asset._id,
              src: asset.url,
              name: file.name,
              type: file.type,
              size: file.size
            })
            .run()

          createEmptyParagraphAfterNode(editor)
        })
      }
    },
    {
      title: "Question",
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).setQuestion().run()
        createEmptyParagraphAfterNode(editor)
      }
    },
    {
      title: "Metric",
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).setMetrics().run()
        createEmptyParagraphAfterNode(editor)
      }
    },
    {
      title: "Embed",
      command: ({ editor, range }) => {
        editor.chain().deleteRange(range).setEmbed().run()
        createEmptyParagraphAfterNode(editor)
      }
    }
  ]
    .filter((item) => item.title.toLowerCase().startsWith(query.toLowerCase()))
    .slice(0, 10)
}

export default getSuggestionItems
