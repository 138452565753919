import cn from "classnames"
import { forwardRef } from "react"
import styles from "./MilestoneEmoji.module.scss"

export interface MilestoneEmojiProps {
  emoji: string
}

export const MilestoneEmoji = forwardRef<HTMLDivElement, MilestoneEmojiProps>(
  ({ emoji }, ref) => (
    <div ref={ref} className={cn(styles.emoji, "mask", "squircle")}>
      {emoji}
    </div>
  )
)
