import axios from "axios"
import qs from "qs"
import config from "config"

export const baseURL = config.apiUrl
export const AUTHORIZATION = "x-authorization-v2"

const client = axios.create({
  baseURL
})

client.interceptors.request.use((request) => {
  const token = localStorage.getItem(AUTHORIZATION)

  const { clientId } = qs.parse(window.location.search, {
    ignoreQueryPrefix: true
  })

  if (token) {
    request.headers["authorization"] = `Bearer ${token}`
  }

  if (clientId) {
    request.headers["x-access-token"] = clientId.toString()
  }

  return request
})

export const createAccount = async (payload: any) => {
  const { data } = await client.post(`/auth/signup`, payload)

  if (data.token) {
    localStorage.setItem(AUTHORIZATION, data.token)
  }

  return data
}

export const getMeta = async (url: string) => {
  const { data } = await client.get(`/links?url=${url}`)

  return data
}

export const uploadFile = async (file: File | Blob) => {
  var form = new FormData()

  form.append("file", file)

  const { data } = await client.post(`/assets`, form, {
    headers: {
      "Content-Type": "multipart/form-data"
    },
    onUploadProgress: (progress) => {
      const { total, loaded } = progress
      const totalSizeInMB = total / 1000000
      const loadedSizeInMB = loaded / 1000000
      const uploadPercentage = (loadedSizeInMB / totalSizeInMB) * 100

      console.log("total size in MB ==> ", totalSizeInMB)
      console.log("uploaded size in MB ==> ", loadedSizeInMB)
      console.log("%", uploadPercentage)
    }
  })

  return data
}
