import { Stack } from "components/layout"
import Centered from "components/layout/Centered"
import { Text } from "components/Text"
import { logout } from "components/Window/Personal"
import {
  auth,
  useAuthState,
  useRedirectUnauthenticated,
  useRedirectVerified
} from "features/auth"
import { useResendVerificationEmailMutation } from "graphql/types"
import { useEffect } from "react"
import toast from "react-hot-toast"
import { useHistory } from "react-router"
import { Button } from "routes/Project/components"

export const VerifyEmail = () => {
  const history = useHistory()

  const [user] = useAuthState()
  const [resend, mutation] = useResendVerificationEmailMutation()

  useRedirectUnauthenticated()
  useRedirectVerified("/")

  useEffect(() => {
    const handler = async () => {
      await auth.currentUser?.reload()

      if (auth.currentUser?.emailVerified) {
        history.push("/")
      }
    }

    window.addEventListener("focus", handler)

    return () => {
      window.removeEventListener("focus", handler)
    }
  }, [])

  return (
    <Centered>
      <Stack distance={10} style={{ maxWidth: 320 }}>
        <h3 style={{ textAlign: "center" }}>Almost done</h3>
        <Text lineHeight={1.5} align="center">
          We've sent an email to <b>{user?.email}</b>. Open it up to activate
          your account.
        </Text>

        <Stack distance={6}>
          <Button
            loading={mutation.loading}
            onClick={async () => {
              await resend()

              toast("Please check your email inbox")
            }}
          >
            Resend verification link
          </Button>

          <Button
            secondary
            onClick={async () => {
              await logout()
            }}
          >
            Log out
          </Button>
        </Stack>
      </Stack>
    </Centered>
  )
}
