const labels = ["KB", "MB", "GB"]

export const sizable = (value: number) => {
  let size = value / 1024
  let i = 0

  while (size > 1024 && i < labels.length) {
    //
    size = size / 1024
    i++
  }

  return `${size.toFixed(2)} ${labels[i]}`
}

export const getIcon = (type: string) => {
  const [group, extension] = type.split("/")

  if (group === "image") {
    return "ri-image-line"
  }

  if (group === "application") {
    switch (extension) {
      case "pdf":
        return "ri-file-pdf-line"
      case "json":
        return "ri-file-code-line"
      case "zip":
        return "ri-file-zip-line"
    }
  }

  return "ri-file-text-line"
}
