import logo from "../../assets/logo.png"

export interface LogoProps {
  position?: "relative" | "absolute"
  height?: number
}

export const Logo = ({ position = "relative", height = 20 }: LogoProps) => (
  <img
    src={logo}
    alt=""
    style={{
      display: "inline-block",
      position,
      height
    }}
  />
)
