import { Extension } from "@tiptap/core"
import Suggestion, { SuggestionOptions } from "@tiptap/suggestion"

interface CommmandsOptions {
  suggestion: Partial<SuggestionOptions>
}

export const Commands = Extension.create<CommmandsOptions>({
  name: "mention",

  defaultOptions: {
    suggestion: {
      char: "/",
      startOfLine: true,
      command: ({ editor, range, props }) => {
        props.command({ editor, range, props })
      }
    }
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion
      })
    ]
  }
})

export default Commands
