import { useMutation } from "@apollo/client"
import toast from "react-hot-toast"
import { useRouteMatch } from "react-router"
import { useLocationQuery } from "hooks/useLocationQuery"
import { PUBLISH_POST } from "./operations"
import { useUpdatePostMutation } from "graphql/types"

export const useSave = () => {
  const [update] = useUpdatePostMutation()
  const location = useLocationQuery()

  const route = useRouteMatch<{ id: string }>()

  const projectId = route.params.id
  const postId = location.query.id

  const save = async (title: string, content: any, metadata: any) => {
    try {
      const { data } = await update({
        variables: {
          projectId,
          postId,
          input: {
            title,
            content,
            metadata
          }
        }
      })
      return data?.updatePost
    } catch (error) {
      console.log(error)
    }
  }

  return [save] as const
}

export const usePublish = () => {
  const [mutate] = useMutation(PUBLISH_POST)
  const route = useRouteMatch<{ projectId: string }>()
  const location = useLocationQuery()

  const projectId = route.params.projectId
  const postId = location.query.id

  const publish = async (id?: string) => {
    try {
      const { data } = await mutate({
        variables: {
          projectId,
          id: id || postId
        }
      })

      toast("🎉 Shareback has been published")

      return data?.publishPost
    } catch (error) {
      toast("Oops! Something went wrong, please try again")
    }
  }

  return [publish] as const
}
