import React, { ReactNode } from "react"
import cn from "classnames"

import useScreenFit from "./useScreenFit"

import styles from "./Menu.module.scss"

interface MenuProps {
  position?: "left" | "right"
  children: ReactNode
  className?: any
  style?: any
}

const Menu = ({ className, position, children, style = {} }: MenuProps) => {
  const [ref, refStyle] = useScreenFit<HTMLDivElement>()

  return (
    <div
      ref={ref}
      className={cn(styles.element, className, position && styles[position])}
      style={{ ...style, ...refStyle }}
      onClick={(event) => {
        event.stopPropagation()
      }}
    >
      {children}
    </div>
  )
}

export default Menu
