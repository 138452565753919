import { Milestone } from "components/Milestone/Milestone"
import { formatFriendlyDate } from "utils/date"

interface ProjectStartMilestoneProps {
  name: string
  date: Date
}

export const ProjectStartMilestone = ({
  name,
  date
}: ProjectStartMilestoneProps) => (
  <Milestone editable={false} emoji="🎉" date={formatFriendlyDate(date)}>
    Project <b>{name}</b> began!
  </Milestone>
)
