import { useMutation } from "@apollo/client"

import { useProject } from "providers/Project"

import { CREATE_DISCUSSION } from "../PostStore/operations"
import { Thread } from "./Thread"

export interface ThreadContainerProps {
  postId: string
  reference: string
  discussions: any[]
}

export const ThreadContainer = ({
  postId,
  reference,
  discussions
}: ThreadContainerProps) => {
  const { projectId } = useProject()
  const [createDiscussion] = useMutation(CREATE_DISCUSSION)
  /**
   * Generally there should be one discussion per node. But at the moment there are no guards to guarantee this when multiple
   * people creating comments at the same time
   */
  const messages = discussions
    .map((discussion: any) => discussion.thread)
    .flat()

  return (
    <Thread
      messages={messages}
      onMessage={async (message) => {
        await createDiscussion({
          variables: {
            projectId,
            postId,
            input: {
              message,
              reference
            }
          }
        })
      }}
    />
  )
}
