import { Text } from "components/Text"
import { MetricChart } from "routes/Post/modals/ViewMetric"

import styles from "./MetricInfo.module.scss"

export interface MetricInfoProps {
  name: string
  measures: any[]
}

export const MetricInfo = ({ name, measures = [] }: MetricInfoProps) => (
  <div className={styles.component}>
    <div className={styles.title}>
      <Text bold>{name}</Text>
    </div>
    <div className={styles.content}>
      <MetricChart name={name} data={measures} />
    </div>
  </div>
)
