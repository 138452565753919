import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer
} from "react"
import { ModalProps } from "./Modal"

type Attributes = Record<string, any>
type Callbacks = Record<string, Function>

type State = {
  modal: string
  attributes: Attributes
  callbacks: Callbacks
}

type Action =
  | {
      type: "MOUNT"
      modal: string
      attributes: Attributes
      callbacks: Callbacks
    }
  | { type: "UNMOUNT" }

type ContextValue = {
  state: State
  show: (name: string, attributes?: Attributes, callbacks?: Callbacks) => void
  hide: () => void
}

const initialState = {
  modal: "",
  attributes: {},
  callbacks: {}
}

const initialContext: ContextValue = {
  state: initialState,
  show: () => null,
  hide: () => null
}

const context = createContext<ContextValue>(initialContext)

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "MOUNT":
      return {
        ...state,
        modal: action.modal,
        attributes: action.attributes || {},
        callbacks: action.callbacks || {}
      }

    case "UNMOUNT":
      return {
        ...state,
        modal: "",
        attributes: {},
        callbacks: {}
      }
  }
}

export const useModal = () => {
  return useContext(context)
}

interface ModalManagerProps extends Omit<ModalProps, "children" | "isOpen"> {
  name: string
  component: any
}

export const ModalManager = ({
  name,
  component,
  onRequestClose,
  onAfterClose,
  onAfterOpen,
  ...props
}: ModalManagerProps) => {
  const { hide, state } = useModal()
  const visible = state.modal === name

  const Component = component as React.FC<ModalProps>

  useEffect(() => {
    console.log(`Registered modal ${name}`)

    return () => {
      console.log(`Unregistered modal ${name}`)
    }
  }, [name])

  return visible ? (
    <Component
      {...props}
      attributes={state.attributes || {}}
      isOpen={visible}
      onAfterOpen={() => {
        state.callbacks?.onAfterOpen?.()
        onAfterOpen?.()
      }}
      onAfterClose={() => {
        state.callbacks?.onAfterClose?.()
        onAfterClose?.()
      }}
      onRequestClose={(event) => {
        onRequestClose?.(event)
        hide()
      }}
    />
  ) : null
}

export const ModalProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const show = useCallback(
    (modal: string, attributes: Attributes = {}, callbacks: Callbacks = {}) => {
      dispatch({
        type: "MOUNT",
        modal,
        attributes,
        callbacks
      })
    },
    []
  )

  const hide = useCallback(() => {
    dispatch({
      type: "UNMOUNT"
    })
  }, [])

  return (
    <context.Provider value={{ state, show, hide }}>
      {children}
    </context.Provider>
  )
}
