import { gql } from "@apollo/client"
import { USER_FRAGMENT } from "graphql/fragments"

export const REACTION_FRAGMENT = gql`
  fragment Reaction on Reaction {
    _id
    client
    post
    project
    reference
    self
    user
    value
  }
`

export const GET_POST = gql`
  query GetPost($projectId: ID!, $postId: ID!) {
    post(projectId: $projectId, id: $postId) {
      _id
      title
      content
      project
      summary
      date
      createdAt
      updatedAt
      publishedAt
      metadata
      draft
      createdBy {
        ...User
      }
    }
  }

  ${USER_FRAGMENT}
`

export const GET_DISCUSSIONS = gql`
  query GetDiscussions($projectId: ID!, $postId: ID!) {
    discussions(projectId: $projectId, postId: $postId) {
      _id
      reference
      thread {
        _id
        user {
          ...User
        }
        client {
          _id
          email
        }
        message
        createdAt
      }
    }
  }

  ${USER_FRAGMENT}
`

export const CREATE_DISCUSSION = gql`
  mutation CreateDiscussion(
    $projectId: ID!
    $postId: ID!
    $input: DiscussionInput!
  ) {
    createDiscussion(projectId: $projectId, postId: $postId, input: $input) {
      _id
      reference
      thread {
        _id
        user {
          ...User
        }
        client {
          _id
          email
        }
        message
        createdAt
      }
    }
  }

  ${USER_FRAGMENT}
`

export const GET_REACTIONS = gql`
  query GetReactions($projectId: ID!, $postId: ID!) {
    reactionGroup(projectId: $projectId, postId: $postId) {
      _id
      reactions {
        ...Reaction
      }
    }
  }

  ${REACTION_FRAGMENT}
`

export const CREATE_REACTION = gql`
  mutation CreateReaction(
    $projectId: ID!
    $postId: ID!
    $input: ReactionInput!
  ) {
    createReaction(projectId: $projectId, postId: $postId, input: $input) {
      ...Reaction
    }
  }

  ${REACTION_FRAGMENT}
`

export const REMOVE_REACTION = gql`
  mutation RemoveReaction($projectId: ID!, $postId: ID!, $id: ID!) {
    removeReaction(projectId: $projectId, postId: $postId, id: $id) {
      _id
    }
  }
`
