import { Form, Formik } from "formik"
import { useState } from "react"

import { FormInput } from "components/Input"
import { Stack } from "components/layout"
import { createAccount } from "api"
import { useHistory } from "react-router"
import { createAccountSchema } from "./schema"

import styles from "./CreateAccount.module.scss"
import { StringComplexity } from "components/StringComplexity"
import { Text } from "components/Text"
import { Button } from "routes/Project/components"

interface CreateAccountFormValues {
  name: string
  password: string
  email: string
}

const CreateAccount = () => {
  const history = useHistory()

  const [disabled, setDisabled] = useState(true)
  const [error, setError] = useState("")

  console.log(disabled)

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <Formik<CreateAccountFormValues>
          validationSchema={createAccountSchema}
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={{
            name: "",
            password: "",
            email: ""
          }}
          onSubmit={async (values) => {
            if (disabled) {
              return
            }

            try {
              await createAccount(values)

              setError("")
              history.push("/")
            } catch (error) {
              // @ts-ignore
              let message = error?.response?.data?.message || ""
              // Fucking typescript crap
              if (!message && error instanceof Error) {
                message = error?.message ?? error
              }

              setError(message)
            }
          }}
        >
          {(props) => (
            <Form autoComplete="off">
              <Stack distance={10}>
                <Stack distance={6}>
                  <h1 style={{ fontWeight: 800, textAlign: "center" }}>
                    Create your account
                  </h1>

                  <div style={{ textAlign: "center", fontSize: 16 }}>
                    Already have an account?{" "}
                    <a
                      href="/authenticate"
                      style={{ color: "var(--primary-text-color)" }}
                    >
                      Log in
                    </a>
                  </div>
                </Stack>
                <Stack distance={6}>
                  <FormInput
                    label="Name"
                    autoComplete="off"
                    name="name"
                    placeholder=""
                  />

                  <FormInput
                    label="Email"
                    autoComplete="off"
                    name="email"
                    type="email"
                    placeholder=""
                  />

                  <FormInput
                    label="Password"
                    description={
                      <StringComplexity
                        value={props.values.password}
                        onUpdate={(complex) => {
                          setDisabled(!complex)
                        }}
                      />
                    }
                    autoComplete="off"
                    name="password"
                    type="password"
                    placeholder=""
                  />
                </Stack>

                <Text lineHeight={1.5}>
                  By creating an account, you agree to our{" "}
                  <b>
                    <a
                      href="https://shareback.io/terms"
                      title="Terms of Service"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms of Service
                    </a>
                  </b>{" "}
                  and have read and understood{" "}
                  <b>
                    <a
                      href="https://shareback.io/privacy-policy"
                      title="Privacy Policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </b>
                  .
                </Text>

                <Button
                  type="submit"
                  size="large"
                  disabled={props.isSubmitting}
                  loading={props.isSubmitting}
                >
                  Create account
                </Button>

                <div style={{ color: "red", textAlign: "center" }}>{error}</div>
              </Stack>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default CreateAccount
