import toast from "react-hot-toast"
import { useHistory } from "react-router"

import { FormValues, GeneralForm } from "./GeneralForm"

import { useProject } from "providers/Project/Project"
import { useIDs } from "routes/Editor/Editor/EditorStore"
import {
  useRemoveProjectMutation,
  useUpdateProjectMutation
} from "graphql/types"

export const General = () => {
  const history = useHistory()

  const { projectId } = useIDs()
  const { project } = useProject()

  const [removeProject] = useRemoveProjectMutation()
  const [updateProject] = useUpdateProjectMutation()

  const onSubmit = async (values: Partial<FormValues>) => {
    if (Object.keys(values).length === 0) {
      return
    }

    try {
      await updateProject({
        variables: {
          id: projectId,
          input: values
        }
      })

      toast("Successfully updated project settings")
    } catch (error) {
      toast("Oops! Something went wrong")
    }
  }

  const onDelete = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this project?"
    )

    if (!confirmed) {
      return
    }

    try {
      await removeProject({
        variables: {
          id: projectId
        }
      })

      history.push("/")
    } catch (error) {
      toast("Oops! Something went wrong")
    }
  }

  return (
    <div>
      <GeneralForm
        logo={project.logo ?? ""}
        initialValues={{
          name: project.name ?? "",
          description: project.description ?? "",
          themeColor: project.themeColor ?? "#222"
        }}
        onDelete={onDelete}
        onSubmit={onSubmit}
      />
    </div>
  )
}
