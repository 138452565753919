import { useHistory } from "react-router"
import { useGetProjectsQuery } from "graphql/types"
import { useState } from "react"

import Spinner from "components/Spinner"
import { ModalManager, useModal } from "components/Modal"
import { SingleBoxLayout } from "layouts/SingleBoxLayout"
import { Row, Split, Stack } from "components/layout"
import { Button } from "routes/Project/components"
import { SelectButton } from "components/SelectButton"

import { prepare } from "./preparation"
import { CreateProjectModal } from "./CreateProjectModal"
import { Project } from "./Project"

import styles from "./Projects.module.scss"

import Icon from "components/Icon"
import { NetworkStatus } from "@apollo/client"

const filterOptions = [
  {
    label: "All projects",
    value: "all"
  },
  {
    label: "Created by me",
    value: "personal"
  },
  {
    label: "Shared with me",
    value: "shared"
  }
]

const sortOptions = [
  {
    label: "Title",
    value: "name"
  },
  {
    label: "Client",
    value: "client"
  },
  {
    label: "Created",
    value: "createdAt"
  }
]

const Projects = () => {
  const history = useHistory()
  const modal = useModal()

  const { data, refetch, networkStatus } = useGetProjectsQuery({
    fetchPolicy: "cache-and-network"
  })

  const projects = data?.projects ?? []

  const [filter, setFilter] = useState("all")
  const [sort, setSort] = useState("createdAt")

  const items = prepare(projects, filter, sort)

  return (
    <>
      <SingleBoxLayout
        title="Projects"
        header={
          <div>
            <Button
              onClick={() => {
                modal.show("create-project")
              }}
            >
              Create project
            </Button>
          </div>
        }
      >
        <Stack distance={8}>
          <div className={styles.filters}>
            <Split>
              <Row distance={4}>
                <SelectButton
                  value={filter}
                  placement="bottom-start"
                  options={filterOptions}
                  onSelect={(option) => setFilter(option.value)}
                />
              </Row>
              <Row distance={4}>
                <span>Sorted by: </span>

                <SelectButton
                  value={sort}
                  options={sortOptions}
                  onSelect={(option) => setSort(option.value)}
                />
              </Row>
            </Split>
          </div>
          <div className={styles.projects}>
            <div
              className={styles.create}
              onClick={() => {
                modal.show("create-project")
              }}
            >
              <div className={styles.createIconWrapper}>
                <Icon
                  className={styles.createIcon}
                  name="ri-add-line"
                  size={30}
                />
              </div>
            </div>
            {items.map((project: any) => (
              <Project
                {...project}
                key={project._id}
                onClick={() => {
                  history.push(`/projects/${project._id}/updates`)
                }}
              />
            ))}
          </div>

          {networkStatus < NetworkStatus.loading && <Spinner />}
        </Stack>
      </SingleBoxLayout>

      <ModalManager
        component={CreateProjectModal}
        name="create-project"
        onAfterClose={() => {
          refetch()
        }}
      />
    </>
  )
}

export default Projects
