const special = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/
const number = /\d/

const isLetter = (symbol: string) => {
  return symbol.toLowerCase() !== symbol.toUpperCase()
}

const hasSpecialCharacters = (value: string) => {
  return special.test(value)
}

const hasNumber = (value: string) => {
  return number.test(value)
}

const isLowerCase = (value: string) => {
  return isLetter(value) && value !== value.toUpperCase()
}

const isUpperCase = (value: string) => {
  return isLetter(value) && value === value.toUpperCase()
}

const cases = (word: string) => {
  let lowercase = 0
  let uppercase = 0

  word.split("").forEach((letter) => {
    if (isUpperCase(letter)) {
      uppercase++
    }

    if (isLowerCase(letter)) {
      lowercase++
    }
  })

  return [lowercase > 0, uppercase > 0] as const
}

export const getStringComplexity = (value: string) => {
  const length = value.length >= 8
  const [lowercase, uppercase] = cases(value)
  const symbol = hasSpecialCharacters(value)
  const number = hasNumber(value)

  const complex = length && lowercase && uppercase && symbol && number

  return { length, lowercase, uppercase, symbol, number, complex } as const
}
