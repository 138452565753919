import { Form, Formik } from "formik"
import toast from "react-hot-toast"
import { object, string } from "yup"

import { Button } from "routes/Project/components/Button"
import { FormInput } from "components/Input"
import { Stack } from "components/layout"
import { useModal } from "components/Modal"
import { Title } from "components/Title"
import { FormColorPicker } from "components/ColorPicker"
import { FormImageImport } from "components/ImageImport"

export interface ProjectFormValues {
  name: string
  description: string
  themeColor: string
  logo: string
}

export interface CreateProjectFormProps {
  onCreate: (values: ProjectFormValues) => Promise<void> | void
}

const initialValues: ProjectFormValues = {
  name: "",
  description: "",
  themeColor: "#333",
  logo: ""
}

const validationSchema = object().shape({
  name: string()
    .trim()
    .min(1, "Name is too short")
    .max(120, "Name is too long")
    .required("Name is a required field"),
  description: string().trim().max(500, "Description is too long")
})

export const CreateProjectForm = ({ onCreate }: CreateProjectFormProps) => {
  const modal = useModal()

  return (
    <Formik<ProjectFormValues>
      validationSchema={validationSchema}
      validateOnChange={false}
      initialValues={initialValues}
      onSubmit={async (values) => {
        try {
          await onCreate(values)

          toast(`🎉 Project "${values.name}" has been created 🎉`)

          modal.hide()
        } catch (error) {
          console.log(error)
        }
      }}
    >
      {(props) => (
        <Form autoComplete="off">
          <Stack distance={12}>
            <Title
              description="You are almost there, create a project to start sharing your
                updates."
            >
              New project
            </Title>

            <Stack distance={6}>
              <FormInput placeholder="Name*" autoComplete="off" name="name" />
              <FormInput
                placeholder="Description"
                autoComplete="off"
                name="description"
              />

              <FormImageImport name="logo" />
              <FormColorPicker name="themeColor" />
            </Stack>

            <Button size="large" type="submit" loading={props.isSubmitting}>
              Create project
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  )
}
