import { useHistory } from "react-router"

import { PublicPageLayout } from "components/PublicPageLayout"
import Card from "components/Card"

import { AuthenticateForm } from "./AuthenticateForm"
import { useEffect } from "react"
import { auth } from "features/auth/firebase"

const Authenticate = () => {
  const history = useHistory()

  useEffect(() => {
    auth.signOut()
  }, [])

  return (
    <PublicPageLayout maxWidth={500}>
      <Card style={{ maxWidth: 600 }}>
        <AuthenticateForm
          onAuthenticate={() => {
            history.push("/projects")
          }}
        />
      </Card>
    </PublicPageLayout>
  )
}

export default Authenticate
