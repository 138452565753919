import { Node, mergeAttributes } from "@tiptap/core"
import { ReactNodeViewRenderer } from "@tiptap/react"

import { EditorTitleComponent } from "./EditorTitleComponent"
import { TitleComponent } from "./TitleComponent"

export interface TitleOptions {
  HTMLAttributes: Record<string, any>
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    title: {
      setTitle: () => ReturnType
    }
  }
}

export const Title = Node.create<TitleOptions>({
  name: "title",
  content: "inline*",
  defining: true,

  addAttributes() {
    return {
      authorId: {
        type: null
      },

      date: {
        type: null
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: `title`
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      `title`,
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0
    ]
  },

  addNodeView() {
    return ReactNodeViewRenderer(
      this.editor.isEditable ? EditorTitleComponent : TitleComponent
    )
  }
})
