import { Label } from "components/Label"
import { Split } from "components/layout"
import { Text } from "components/Text"
import { useField } from "formik"
import { Toggle } from "./Toggle"
import styles from "./Toggle.module.scss"

export interface FormToggleProps {
  label: string
  description: string
  name: string
}

export const FormToggle = ({ label, name, description }: FormToggleProps) => {
  const [input, , helpers] = useField(name)

  return (
    <Label message={label}>
      <Split className={styles.container}>
        <Text>{description}</Text>
        <Toggle
          value={input.value}
          onChange={(value) => helpers.setValue(value)}
        />
      </Split>
    </Label>
  )
}
