import { gql } from "@apollo/client"
import { USER_FRAGMENT } from "graphql/fragments"

export const MEMBER_FRAGMENT = gql`
  fragment Member on Member {
    _id
    role
    user {
      ...User
    }
  }

  ${USER_FRAGMENT}
`

export const GET_TEAM = gql`
  query GetTeam($id: ID!) {
    project(id: $id) {
      _id

      members {
        ...Member
      }

      invites {
        _id
        email
      }
    }
  }

  ${MEMBER_FRAGMENT}
`

export const REMOVE_MEMBER = gql`
  mutation RemoveMember($projectId: ID!, $id: ID!) {
    removeMember(projectId: $projectId, id: $id) {
      _id

      members {
        ...Member
      }
    }
  }

  ${MEMBER_FRAGMENT}
`

export const REMOVE_INVITE = gql`
  mutation RemoveInvite($projectId: ID!, $id: ID!) {
    removeInvite(projectId: $projectId, id: $id) {
      _id
    }
  }
`
