import { NodeViewProps } from "@tiptap/react"
import numeral from "numeral"
import cn from "classnames"
import { useModal } from "components/Modal"
import { useGetMeasureQuery } from "graphql/types"
import { useProject } from "providers/Project"

import { Reactable } from "../Reactable"

import styles from "./MetricsComponent.module.scss"

interface ViewerMetricsComponentProps extends NodeViewProps {}

export const ViewerMetricsComponent = ({
  node
}: ViewerMetricsComponentProps) => {
  const modal = useModal()
  const { projectId } = useProject()
  const { reference, metricId, measureId } = node.attrs

  const { data } = useGetMeasureQuery({
    variables: {
      projectId,
      metricId,
      id: measureId
    }
  })

  return data ? (
    <Reactable node={node}>
      <div
        data-reference={reference}
        className={cn(styles.component, styles.viewer)}
      >
        <div
          className={styles.metric}
          onClick={() => {
            modal.show("reader::metric", {
              node
            })
          }}
        >
          <h5 className={styles.title}>{data.metric.name}</h5>
          <div className={styles.value}>
            {numeral(data?.measure.value).format("0,0")}
          </div>
        </div>
      </div>
    </Reactable>
  ) : (
    <div />
  )
}
