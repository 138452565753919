import { Editor, FloatingMenu } from "@tiptap/react"
import { useRef } from "react"
import Tippy from "@tippyjs/react"

import Icon from "components/Icon"

import { shouldShow } from "./shouldShow"
import CommandList from "../../extensions/Suggestions/List"
import getSuggestionItems from "../../extensions/Suggestions/items"

import styles from "./EditorNewlineMenu.module.scss"

interface EditorNewlineMenuProps {
  editor: Editor
}

export const EditorNewlineMenu = ({ editor }: EditorNewlineMenuProps) => {
  const tippy = useRef<any>()

  return (
    <FloatingMenu
      editor={editor}
      tippyOptions={{ placement: "left" }}
      shouldShow={shouldShow}
    >
      <Tippy
        placement="right-start"
        trigger="click"
        interactive
        offset={[36, 0]}
        onCreate={(instance) => {
          tippy.current = instance
        }}
        content={
          <CommandList
            items={getSuggestionItems({ query: "" })}
            command={(item: any) => {
              tippy.current?.hide()

              item.command({
                editor,
                range: editor.state.selection.ranges[0]
              })
            }}
          />
        }
      >
        <button className={styles.plusButton}>
          <Icon name="ri-add-line" size={16} />
        </button>
      </Tippy>
    </FloatingMenu>
  )
}
