import { uploadFile } from "api"
import { useField } from "formik"

import { ImageImport } from "./ImageImport"

export interface FormImageImportProps {
  name: string
}

export const FormImageImport = ({ name }: FormImageImportProps) => {
  const [, , helper] = useField(name)

  return (
    <ImageImport
      onFile={async (file) => {
        if (file) {
          const { asset } = await uploadFile(file)

          helper.setValue(asset.url)
        }
      }}
    />
  )
}
