import { array, object, string } from "yup"

export const validationSchema = () => {
  return object({
    type: string(),
    message: string()
      .required("Question is a required field")
      .max(500, "Questions is too long"),
    options: array().when("type", {
      is: "multiple_choice",
      then: array()
        .min(2)
        .required("At least two choice options are required")
        .of(
          object().shape({
            message: string()
              .required("Message is required")
              .max(200, "Message is too long"),
            id: string()
          })
        )
    })
  })
}
