import { cancelButtonSpec, confirmButtonSpec } from "components/Modal"
import { endOfDay, isSameDay } from "date-fns"
import { Form, Formik } from "formik"
import { FormDatepickerISO } from "components/Datepicker/FormDatepicker"
import { FormInput } from "components/Input"
import { FormStack } from "components/layout"
import { FormTextarea } from "components/Textarea"
import { FormToggle } from "components/Toggle/FormToggle"
import { Title, Element, Footer } from "components/Modal/Dialog"
import Banner from "components/Banner"
import parseISO from "date-fns/parseISO"

export interface PublishFormValues {
  title: string
  summary: string
  notify: boolean
  date: string
}

export interface PublishModalFormProps {
  notifications: boolean
  initialValues: PublishFormValues
  onSubmit: (values: PublishFormValues) => void
  onCancel: () => void
}

const eodToday = endOfDay(new Date())

export const PublishModalForm = ({
  notifications,
  initialValues,
  onSubmit,
  onCancel
}: PublishModalFormProps) => (
  <Formik<PublishFormValues>
    initialValues={initialValues}
    onSubmit={(values) => onSubmit(values)}
  >
    {(props) => (
      <Form>
        <Title message="Publishing" />

        <Element>
          <FormStack>
            <FormInput name="title" label="Title" />
            <FormTextarea name="summary" label="Summary" minRows={2} />
            <FormDatepickerISO label="Date" name="date" maxDate={eodToday} />

            <Banner type="info" icon="ri-information-line">
              Summary appears in your project timeline and in the email
              notification sent to your project followers.
            </Banner>

            {notifications && (
              <FormToggle
                label="Notification"
                name="notify"
                description="Send an email notification to all clients"
              />
            )}

            {notifications &&
              props.values.notify &&
              !isSameDay(eodToday, parseISO(props.values.date)) && (
                <Banner type="warning" icon="ri-error-warning-line">
                  You are choosing a historical date, consider not notifying
                  clients.
                </Banner>
              )}
          </FormStack>
        </Element>

        <Footer buttons={[cancelButtonSpec(onCancel), confirmButtonSpec()]} />
      </Form>
    )}
  </Formik>
)
