import { uploadFile } from "api"
import toast from "react-hot-toast"
import { upload } from "utils/upload"
import { Editor } from "@tiptap/react"

export const createEmptyParagraphAfterNode = (editor: Editor) => {
  const paragraph = editor.schema.nodes.paragraph

  const anchor = editor.state.selection.anchor
  const next = editor.state.doc.nodeAt(anchor)

  if (next && next.type !== paragraph) {
    editor.commands.insertContentAt(anchor, {
      type: "paragraph",
      attrs: {
        textAlign: "left"
      }
    })

    editor.commands.focus(anchor)
  }
}

export const createAssetWithMessages = async (
  accept: string,
  callback: (asset: any, file: File) => void
) => {
  const file = await upload(accept || "*/*")

  if (file) {
    const toastId = toast.loading("Importing image...")

    try {
      const { asset } = await uploadFile(file)

      callback(asset, file)
    } catch (error) {
      toast(
        `Error while uploading file, please make sure that the file is not larger than 5MB. ${error.message}`
      )
    }

    toast.dismiss(toastId)
  }
}
