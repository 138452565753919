import { Dialog, ModalProps, useModal } from "components/Modal"
import { useInviteGuestMutation } from "graphql/types"
import toast from "react-hot-toast"
import { useIDs } from "routes/Editor/Editor/EditorStore"
import { InviteClientForm, InviteClientFormValues } from "./InviteClientForm"

export const InviteClientModal = (props: ModalProps) => {
  const modal = useModal()

  const { projectId } = useIDs()
  const [inviteGuest] = useInviteGuestMutation()

  const onSubmit = async (input: InviteClientFormValues) => {
    try {
      await inviteGuest({
        variables: {
          projectId,
          input
        }
      })

      modal.hide()
      toast(`${input.email} has been invited to the project.`)
    } catch (error) {
      const message = error?.message ?? "Oops! Something went wrong!"

      toast(message)
    }
  }

  const onCancel = () => {
    modal.hide()
  }

  return (
    <Dialog {...props}>
      <InviteClientForm onSubmit={onSubmit} onCancel={onCancel} />
    </Dialog>
  )
}
