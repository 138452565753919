import cn from "classnames"
import { forwardRef, ReactNode } from "react"
import styles from "./Simple.module.scss"

export interface SimpleTooltipProps {
  children: ReactNode
  placement?: "left" | "right" | "top" | "bottom"
  message: ReactNode | string
}
/**
 * This is the leanest implementation of the tooltip possible.
 *
 * We use Tippy a lot in the project and, for example, in the situations where we need to have
 * a dropdown + tooltip, Tippy was not working.
 */
export const SimpleTooltip = forwardRef<HTMLDivElement, SimpleTooltipProps>(
  ({ children, placement = "bottom", message }, ref) => (
    <div className={styles.component} ref={ref}>
      {children}
      {message && (
        <div className={cn(styles.tooltip, styles[placement])}>{message}</div>
      )}
    </div>
  )
)
