import { Form, Formik } from "formik"

import { Button } from "routes/Project/components/Button"
import { FormInput } from "components/Input"
import { Row, Stack } from "components/layout"

import { FormColorPicker } from "components/ColorPicker"

import { Label } from "components/Label"
import { difference } from "utils/object"
import { FormImage } from "./FormImage"

export interface FormValues {
  logo?: string | null
  description: string
  themeColor: string
  name: string
}

export interface GeneralFormProps {
  logo: string
  initialValues: FormValues
  onSubmit: (values: Partial<FormValues>) => void | Promise<void>
  onDelete: () => void | Promise<void>
}

export const GeneralForm = ({
  logo,
  initialValues,
  onSubmit,
  onDelete
}: GeneralFormProps) => (
  <Formik<FormValues>
    initialValues={initialValues}
    validateOnBlur={false}
    onSubmit={async (values) => onSubmit(difference(values, initialValues))}
  >
    {(props) => (
      <Form autoComplete="off">
        <Stack distance={6}>
          <FormImage
            url={logo}
            onChange={(url) => {
              onSubmit({
                logo: url
              })
            }}
          />

          <FormInput label="Name" autoComplete="off" name="name" />

          <FormInput
            label="Description"
            autoComplete="off"
            name="description"
          />

          <Label message="Theme color">
            <FormColorPicker name="themeColor" />
          </Label>

          <Row distance={4} horizontal="right">
            <Button secondary onClick={async () => onDelete()}>
              Delete project
            </Button>

            <Button type="submit" loading={props.isSubmitting}>
              Update
            </Button>
          </Row>
        </Stack>
      </Form>
    )}
  </Formik>
)
