import { Extension } from "@tiptap/core"
import { Node } from "prosemirror-model"
import { Plugin, PluginKey } from "prosemirror-state"
import { Decoration, DecorationSet } from "prosemirror-view"

export interface SelectedOptions {
  min: number
}

const hasTextChild = (node: Node<any>) => {
  let hasText = false

  node.forEach((node) => {
    if (!hasText && node.isText) {
      hasText = true
    }
  })

  return hasText
}

const emptyClass = (isEditable: boolean) => {
  return new Plugin({
    key: new PluginKey("node-empty-class"),
    props: {
      decorations: function ({ doc, selection }) {
        const { anchor } = selection
        const decorations: Decoration[] = []

        if (!isEditable) {
          return
        }

        doc.descendants((node, pos) => {
          const hasAnchor = anchor >= pos && anchor <= pos + node.nodeSize
          const isEmpty = !node.isLeaf && !node.childCount

          if (hasAnchor && isEmpty) {
            const decoration = Decoration.node(pos, pos + node.nodeSize, {
              class: "empty"
            })

            decorations.push(decoration)
          }

          return false
        })

        return DecorationSet.create(doc, decorations)
      }
    }
  })
}

const selectedClass = (isEditable: boolean, min: number) => {
  return new Plugin({
    key: new PluginKey("node-selection-class"),
    props: {
      decorations({ doc, selection }) {
        const { from, to } = selection
        const decorations: Decoration[] = []

        if (!isEditable) {
          return DecorationSet.create(doc, [])
        }

        doc.nodesBetween(from, to, (node, position) => {
          if (node.isBlock) {
            const isDeepest = hasTextChild(node)

            if (isDeepest) {
              decorations.push(
                Decoration.node(position, position + node.nodeSize, {
                  class: "selected"
                })
              )
            }
          }
        })

        return DecorationSet.create(
          doc,
          decorations.length >= min ? decorations : []
        )
      }
    }
  })
}

export const Selected = Extension.create<SelectedOptions>({
  name: "selectedClasses",

  defaultOptions: {
    min: 2
  },

  addProseMirrorPlugins() {
    const min = this.options.min
    const isEditable = this.editor.isEditable

    return [selectedClass(isEditable, min), emptyClass(isEditable)]
  }
})
