const config = {
  graphqlUrl: "http://localhost:8080",
  graphqlSubscriptionUrl: "ws://localhost:8080/subscriptions",
  apiUrl: "http://localhost:4000",

  firebase: {
    apiKey: "AIzaSyC5y4W69C_BGnZ1DJltvQ_CfPK6394ELzg",
    authDomain: "development-321214.firebaseapp.com"
  }
}

export default config
