import { reactionsByName } from "utils/feedback"

import { Reaction } from "./Reaction"

import styles from "./ReactionList.module.scss"

export type Reactions = Record<
  string,
  {
    self: string
    count: number
  }
>

export interface ReactionListProps {
  reference: string
  reactions: Reactions
  onRemove: (id: string) => void
  onAdd: (name: string) => void
}

export const ReactionList = ({
  reference,
  reactions,
  onRemove,
  onAdd
}: ReactionListProps) => {
  const entries = Object.entries<{ count: number; self: string }>(reactions)

  return (
    <div className={styles.reactions}>
      {entries.map(([name, info]) => (
        <Reaction
          active={Boolean(info.self)}
          key={name}
          reference={reference}
          name={name}
          count={info.count}
          emoji={reactionsByName[name].emoji}
          onClick={() => {
            if (info.self) {
              onRemove(info.self)
            } else {
              onAdd(name)
            }
          }}
        />
      ))}
    </div>
  )
}
