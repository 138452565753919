import { Stack } from "components/layout"
import { KebabMenu } from "components/Menu"
import { ReactNode } from "react"
import styles from "./Milestone.module.scss"
import { MilestoneEmoji } from "./MilestoneEmoji"

export interface MilestoneProps {
  emoji: string
  editable?: boolean
  children: ReactNode
  date?: string
  name?: string
  onRemove?: () => void
  onEdit?: () => void
}

const options = [
  {
    value: "edit",
    label: "Edit"
  },
  {
    value: "remove",
    label: "Remove"
  }
]

export const Milestone = ({
  editable = true,
  emoji = "🎉",
  children,
  date,
  name,
  onRemove,
  onEdit
}: MilestoneProps) => {
  const hasDateOrName = Boolean(date || name)

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <MilestoneEmoji emoji={emoji} />

        {editable && (
          <div className={styles.menu}>
            <KebabMenu
              options={options}
              onSelect={(value) => {
                switch (value) {
                  case "remove":
                    return onRemove?.()
                  case "edit":
                    return onEdit?.()
                }
              }}
            />
          </div>
        )}
      </div>

      <div className={styles.message}>
        <Stack distance={1}>
          <div>{children}</div>

          {hasDateOrName && (
            <div className={styles.date}>
              {date} {Boolean(date && name) ? "-" : ""} {name}
            </div>
          )}
        </Stack>
      </div>
    </div>
  )
}
