import Modal, { ModalProps } from "components/Modal"
import { useCreateProjectMutation } from "graphql/types"

import { CreateProjectForm } from "./CreateProjectForm"

import styles from "./CreateProjectModal.module.scss"

export const CreateProjectModal = (props: ModalProps) => {
  const [createProject] = useCreateProjectMutation()

  return (
    <Modal {...props} className={styles.modal}>
      <CreateProjectForm
        onCreate={async (values) => {
          await createProject({
            variables: {
              input: values
            }
          })
        }}
      />
    </Modal>
  )
}
