import { FormInput } from "components/Input"
import { FormStack, Row } from "components/layout"
import { FormTextarea } from "components/Textarea"
import { Formik, Form } from "formik"
import { useState } from "react"
import {
  cancelButtonSpec,
  confirmButtonSpec,
  Element,
  Footer,
  Title
} from "components/Modal"
import { SimpleImageInput } from "components/PictureEditor/SimpleImageInput"

export interface EditTeammateValues {
  firstName: string
  lastName: string
  description: string
  position: string
  photoUrl: string
}

export interface EditTeammateSubmitValues extends EditTeammateValues {
  image: Nullable<Blob | File>
}

export interface EditTeammateFormProps {
  initialValues: EditTeammateValues
  onCancel: () => void
  onSubmit: (values: EditTeammateSubmitValues) => void | Promise<void>
}

export const EditTeammateForm = ({
  onCancel,
  onSubmit,
  initialValues
}: EditTeammateFormProps) => {
  const [image, setImage] = useState<Nullable<Blob>>(null)

  return (
    <Formik<EditTeammateValues>
      initialValues={initialValues}
      onSubmit={(values) => {
        onSubmit({
          ...values,
          image
        })
      }}
    >
      {(props) => (
        <Form>
          <Title message="Team member profile" />

          <Element>
            <FormStack>
              <SimpleImageInput
                initialValue={initialValues.photoUrl ?? ""}
                size={200}
                onChange={(value) => {
                  props.setFieldValue("photoUrl", "")
                  setImage(value)
                }}
              />

              <Row distance={10} fill>
                <FormInput name="firstName" label="First name" />
                <FormInput name="lastName" label="Last name" />
              </Row>

              <FormInput name="position" label="Position" />

              <FormTextarea
                maxRows={6}
                minRows={3}
                maxLength={300}
                name="description"
                label="Bio"
              />
            </FormStack>
          </Element>

          <Footer buttons={[cancelButtonSpec(onCancel), confirmButtonSpec()]} />
        </Form>
      )}
    </Formik>
  )
}
