import cn from "classnames"

import styles from "./Logo.module.scss"

export interface LogoProps {
  url?: string
  name: string
}

export const Logo = ({ url, name }: LogoProps) => {
  const dataName = url ? "" : name.substr(0, 1).toUpperCase()
  const backgroundImage = `url("${url}")`

  return (
    <div className={cn(styles.wrapper)}>
      <div
        className={cn(styles.image, "mask", "squircle")}
        data-name={dataName}
        style={{ backgroundImage }}
      />
    </div>
  )
}
