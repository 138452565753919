import Banner from "components/Banner"
import { useModal } from "components/Modal"

import styles from "./PublishBanner.module.scss"

export const PublishBanner = ({ post }: any) => {
  const modal = useModal()

  return (
    <div className={styles.publishBanner}>
      <Banner
        type="info"
        icon="ri-information-line"
        style={{ borderRadius: 16 }}
        action={{
          label: "Publish",
          on: () => {
            modal.show("publish", {
              post
            })
          }
        }}
      >
        This post is not visible to clients yet. If you would like to make it
        visible - press the "Publish" button.
      </Banner>
    </div>
  )
}
