import cn from "classnames"

import { Stack } from "components/layout"
import { KebabMenu } from "components/Menu"
import { Text } from "components/Text"
import styles from "./Item.module.scss"

export interface ItemProps {
  name: string
  photoUrl: string
  position: string
  description: string
  canEdit: boolean

  onView: () => void
  onEdit: () => void
  onRemove: () => void
}

const options = [
  {
    label: "Edit",
    value: "edit"
  },
  {
    label: "Remove",
    value: "remove"
  }
]

export const Item = ({
  name,
  photoUrl,
  description,
  position,
  onEdit,
  onRemove,
  onView,
  canEdit
}: ItemProps) => (
  <div className={styles.container} onClick={() => onView()}>
    <div className={styles.body}>
      <div style={{ position: "relative" }}>
        <div
          className={cn(styles.icon, "mask", "squircle")}
          style={{
            backgroundImage: photoUrl ? `url(${photoUrl})` : undefined
          }}
        />
        {canEdit && (
          <div className={styles.menu}>
            <KebabMenu
              options={options}
              onSelect={(option) => {
                if (option === "edit") {
                  return onEdit()
                }

                if (option === "remove") {
                  return onRemove()
                }
              }}
            />
          </div>
        )}
      </div>

      <div className={styles.details}>
        <Stack distance={4}>
          <Stack distance={1}>
            <Text align="center" className={styles.name} bold>
              {name}
            </Text>

            <Text align="center" bold color="var(--secondary-text-color)">
              {position}
            </Text>
          </Stack>

          {description ? (
            <Text
              align="center"
              className={styles.description}
              lineHeight={1.5}
              color="var(--secondary-text-color)"
            >
              {description}
            </Text>
          ) : null}
        </Stack>
      </div>
    </div>
  </div>
)
