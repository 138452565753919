import { ReactNode } from "react"
import cn from "classnames"

import styles from "./Card.module.scss"
import { Split, Stack } from "components/layout"

export interface CardProps {
  title?: ReactNode
  titleAlign?: "left" | "center" | "right"
  toolbar?: ReactNode
  children: ReactNode
  style?: Record<string, unknown>
  contentPadding?: number
}

const Card = ({
  children,
  title,
  titleAlign = "left",
  toolbar,
  style
}: CardProps) => (
  <Stack distance={4}>
    {(title || toolbar) && (
      <Split>
        <h2 className={styles.title} style={{ textAlign: titleAlign }}>
          {title}
        </h2>
        <div>{toolbar}</div>
      </Split>
    )}

    <div className={cn(styles.card)} style={style}>
      <div>{children}</div>
    </div>
  </Stack>
)

export default Card
