import { SingleBoxLayout } from "layouts/SingleBoxLayout"
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch
} from "react-router"
import { Clients } from "./Clients"
import { General } from "./General"
import { Tabs } from "./Tabs"
import Team from "./Team"

const tabs = [
  { label: "General", value: "general" },
  { label: "Team", value: "team" },
  { label: "Clients", value: "clients" }
]

const Settings = () => {
  const history = useHistory()
  const route = useRouteMatch<{ id: string; page: string }>()

  const id = route.params.id
  const page = route.params.page?.toLowerCase() ?? "general"

  return (
    <SingleBoxLayout title="Settings" maxWidth={600}>
      <Tabs
        options={tabs}
        value={page}
        onChange={(target) => {
          history.push(`/projects/${id}/settings/${target}`)
        }}
      />

      <Switch>
        <Route exact path="/projects/:id/settings/general">
          <General />
        </Route>

        <Route exact path="/projects/:id/settings/clients">
          <Clients />
        </Route>

        <Route path="/projects/:id/settings/team">
          <Team />
        </Route>

        <Redirect
          path="/projects/:id/settings"
          to="/projects/:id/settings/general"
        />
      </Switch>
    </SingleBoxLayout>
  )
}

export default Settings
