import { useField } from "formik"
import cn from "classnames"
import { forwardRef, ReactNode } from "react"
import { Label } from "components/Label"

import Input, { InputProps } from "./Input"

import styles from "./Input.module.scss"

interface FormInputProps extends Omit<InputProps, "value" | "onChange"> {
  name: string
  label?: string
  description?: ReactNode
  required?: boolean
  format?: (value: string) => string
}

const FormInput = forwardRef<HTMLDivElement, FormInputProps>(
  (
    { label, name, description, className, format, required, ...props },
    ref
  ) => {
    const [field, meta, helper] = useField(name)
    const hasError = meta.error && meta.touched

    return (
      <Label message={label} ref={ref}>
        {description}
        <Input
          fill
          autoComplete="off"
          {...props}
          name={name}
          className={cn(className, hasError && styles.error)}
          value={field.value}
          onBlur={field.onBlur}
          onChange={(event) => {
            const value = event.target.value
            const $value = typeof format === "function" ? format(value) : value

            helper.setValue($value)
          }}
        />
        {hasError && <div className={styles.errorMessage}>{meta.error}</div>}
      </Label>
    )
  }
)

export default FormInput
