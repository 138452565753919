import { Stack } from "components/layout"
import { forwardRef, ReactNode } from "react"
import styles from "./Label.module.scss"

interface FormInputProps {
  message: string | null | undefined
  required?: boolean
  children: ReactNode
}

export const Label = forwardRef<HTMLDivElement, FormInputProps>(
  ({ message, required, children }, ref) => (
    <Stack distance={2} className={styles.component} ref={ref}>
      {message && (
        <div className={styles.label}>
          {message}
          {required ? " *" : ""}
        </div>
      )}

      {children}
    </Stack>
  )
)
