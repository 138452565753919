import { auth } from "features/auth"
import { useEffect } from "react"
import { useHistory } from "react-router"

import { useAuthState } from "react-firebase-hooks/auth"

const Root = () => {
  const [user, loading] = useAuthState(auth)
  const history = useHistory()

  useEffect(() => {
    if (loading) {
      return
    }

    const path = user ? "/projects" : "/authenticate"

    history.push(path)
  }, [history, loading, user])

  return null
}

export default Root
