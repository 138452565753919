import { NodeViewProps } from "@tiptap/react"
import cn from "classnames"
import { useGetQuestionWithAnswerQuery } from "graphql/types"
import { sum, values } from "lodash"

import { useProject } from "providers/Project"

import { Reactable } from "../../Reactable"
import { ViewerQuestionComponentOption } from "./ViewerQuestionComponentOption"
import { OpenEndedAnswerEditor } from "./OpenEndedAnswerEditor"
import { QuestionTitle } from "./QuestionTitle"

import styles from "../QuestionComponent.module.scss"
import { OpenEndedAnswer } from "./OpenEndedAnswer"
import { ModalManager } from "components/Modal"
import { AnswersModal } from "./AnswersModal"

interface QuestionComponentProps extends NodeViewProps {}

const percents = (total: number, value: number) => {
  return Math.max(Math.ceil((value / total) * 1000) / 10, 0)
}

export const ViewerQuestionComponent = ({ node }: QuestionComponentProps) => {
  const { projectId } = useProject()
  const { questionId } = node.attrs

  const { data, refetch } = useGetQuestionWithAnswerQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      projectId,
      id: questionId
    }
  })

  const question = data?.question
  const answer = data?.question.answer || null

  const totalAnswers = sum(values(question?.metrics || {})) || 0

  return (
    <Reactable node={node}>
      {question && (
        <div
          className={cn(styles.container, question.answer && styles.answered)}
        >
          <QuestionTitle message={question.message} />

          {question.type === "open_ended" && answer && (
            <OpenEndedAnswer
              question={question}
              answer={answer}
              onRemove={() => {
                refetch()
              }}
            />
          )}

          {question.type === "open_ended" && !answer && (
            <OpenEndedAnswerEditor
              question={question}
              answer={answer}
              onCreate={() => {
                refetch()
              }}
            />
          )}

          {question.type === "multiple_choice" && (
            <div className={styles.answers}>
              {question.options.map((option, index) => {
                const quantity = question.metrics[option._id] || 0

                const description = quantity
                  ? `${percents(totalAnswers, quantity)}% (${quantity})`
                  : "0"

                const owned = option._id === question.answer?.value

                return (
                  <ViewerQuestionComponentOption
                    key={option._id}
                    index={index}
                    projectId={projectId}
                    questionId={questionId}
                    optionId={option._id}
                    message={option.message}
                    owned={owned}
                    quantity={description}
                    onAnswer={() => refetch()}
                  />
                )
              })}
            </div>
          )}
        </div>
      )}
      <ModalManager
        name="editor::show-question-answers"
        component={AnswersModal}
      />
    </Reactable>
  )
}
