import { Placement } from "@popperjs/core"
import Tippy from "@tippyjs/react"
import { JSXElementConstructor, ReactElement } from "react"
import styles from "./Tooltip.module.scss"

export interface TooltipProps {
  children: ReactElement<any, string | JSXElementConstructor<any>>
  placement?: Placement
  message: string | number
  enabled?: boolean
}

export const Tooltip = ({
  placement = "bottom",
  children,
  message,
  enabled = true
}: TooltipProps) => {
  if (!enabled) {
    return <>{children}</>
  }

  return (
    <Tippy
      maxWidth={240}
      placement={placement}
      interactive={false}
      hideOnClick={false}
      content={<div className={styles.tooltip}>{message}</div>}
    >
      {children}
    </Tippy>
  )
}
