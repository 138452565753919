import { Split, Stack } from "components/layout"
import { useModal } from "components/Modal"
import { Textarea } from "components/Textarea"
import {
  AnswerFragment,
  QuestionFragment,
  useCreateAnswerMutation
} from "graphql/types"
import { useProject } from "providers/Project"
import { useState } from "react"
import { Button } from "routes/Project/components"

export interface OpenEndedAnswerEditorProps {
  question: QuestionFragment
  answer: AnswerFragment | null
  onCreate: () => void
}

export const OpenEndedAnswerEditor = ({
  question,
  answer,
  onCreate
}: OpenEndedAnswerEditorProps) => {
  const modal = useModal()
  const [value, setValue] = useState("")

  const { clientId } = useProject()

  const [createAnswer] = useCreateAnswerMutation()

  return (
    <Stack distance={6} style={{ padding: "20px 12px", paddingBottom: 8 }}>
      <div>
        <Textarea
          value={value}
          minRows={2}
          maxRows={5}
          maxLength={300}
          showMaxLength
          headless
          placeholder="Type answer here..."
          onChange={(event) => {
            setValue(event.target.value)
          }}
        />
      </div>

      <Split style={{ paddingBottom: 8 }}>
        <div>
          {!clientId && (
            <Button
              secondary
              onClick={() => {
                modal.show("editor::show-question-answers", {
                  question,
                  answer
                })
              }}
            >
              Show answers
            </Button>
          )}
        </div>

        <div>
          <Button
            onClick={async () => {
              const $value = value.trim()

              if ($value.length === 0) {
                return
              }

              await createAnswer({
                variables: {
                  projectId: question.project,
                  questionId: question._id,
                  input: {
                    value: $value
                  }
                }
              })

              onCreate()
            }}
          >
            Save answer
          </Button>
        </div>
      </Split>
    </Stack>
  )
}
