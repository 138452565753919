import { Node, mergeAttributes } from "@tiptap/core"
import { ReactNodeViewRenderer } from "@tiptap/react"

import { EditorParagraphComponent } from "./EditorParagraphComponent"
import { ViewerParagraphComponent } from "./ViewerParagraphComponent"

export interface ParagraphOptions {
  HTMLAttributes: Record<string, any>
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    paragraph: {
      setParagraph: () => ReturnType
    }
  }
}

export const Paragraph = Node.create<ParagraphOptions>({
  name: "paragraph",

  priority: 1000,

  defaultOptions: {
    HTMLAttributes: {}
  },

  group: "block",

  content: "inline*",

  parseHTML() {
    return [{ tag: "p" }]
  },

  renderHTML({ HTMLAttributes, node }) {
    return [
      "p",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0
    ]
  },

  addNodeView() {
    return ReactNodeViewRenderer(
      this.editor.isEditable
        ? EditorParagraphComponent
        : ViewerParagraphComponent
    )
  },

  addCommands() {
    return {
      setParagraph:
        () =>
        ({ commands }) => {
          return commands.setNode("paragraph")
        }
    }
  },

  addKeyboardShortcuts() {
    return {
      "Mod-Alt-0": () => this.editor.commands.setParagraph()
    }
  }
})
