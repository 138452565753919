import { gql } from "@apollo/client"

export const AUTHENTICATE_LOCAL = gql`
  mutation Authenticate($email: String!, $password: String!) {
    authenticate(email: $email, password: $password)
  }
`

export const AUTHENTICATE_GOOGLE = gql`
  mutation AuthenticateGoogle($token: String!) {
    authenticateGoogle(token: $token)
  }
`
