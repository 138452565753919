import { parseISO } from "date-fns"
import { MilestoneFragment, PostFragment } from "graphql/types"
import { formatFriendlyDate } from "utils/date"
import {
  TimelineDateEvent,
  TimelineEvent,
  TimelineMilestoneEvent,
  TimelinePostEvent
} from "./types"

export const groupEventsByDate = (
  posts: PostFragment[],
  milestones: MilestoneFragment[],
  preview: boolean
): TimelineEvent[] => {
  const postEvents: TimelinePostEvent[] = posts
    /**
     * Filter out all posts that have not been published yet
     */
    .filter((post) => {
      return preview ? !!post.publishedAt : true
    })
    .map((post) => {
      const createdAt = parseISO(post.date)

      return {
        type: "post",
        value: post,
        createdAt
      }
    })

  const milestoneEvents: TimelineMilestoneEvent[] = milestones.map(
    (milestone) => {
      const createdAt = parseISO(milestone.date)

      return {
        type: "milestone",
        value: milestone,
        createdAt
      }
    }
  )

  const events = [...milestoneEvents, ...postEvents].sort((prev, next) => {
    return next.createdAt.getTime() - prev.createdAt.getTime()
  })

  let previous: string = ""
  let timelineEvens: TimelineEvent[] = []

  events.forEach((event) => {
    const date = formatFriendlyDate(event.createdAt)

    if (date !== previous) {
      const timelineDateEvent: TimelineDateEvent = {
        type: "date",
        value: date
      }

      timelineEvens.push(timelineDateEvent)
      previous = date
    }

    timelineEvens.push(event)
  })

  return timelineEvens
}
