import { Resizable, Size } from "re-resizable"
import cn from "classnames"
import { ReactNode, useRef } from "react"

import styles from "./Frame.module.scss"
import Icon from "components/Icon"
import { Row } from "components/layout"
import { Tooltip } from "components/Tooltip"

export interface FrameProps {
  defaultSize: Size
  selected: boolean
  children: ReactNode
  onResize: (value: Size) => void
  onScale: (resizable: Resizable) => void
}

export const Frame = ({
  defaultSize,
  selected,
  children,
  onResize,
  onScale
}: FrameProps) => {
  const ref = useRef<Resizable | null>(null)

  return (
    <div className={styles.wrapper}>
      <Resizable
        ref={(resizable) => {
          ref.current = resizable
        }}
        defaultSize={defaultSize}
        minWidth={200}
        minHeight={100}
        maxWidth="100%"
        className={cn(styles.resizable, selected && styles.selected)}
        onResizeStop={(event, direction, element) => {
          const widthPropValue = element.style.getPropertyValue("width")
          const width = parseInt(widthPropValue)

          const heightPropValue = element.style.getPropertyValue("height")
          const height = parseInt(heightPropValue)

          onResize({
            width,
            height
          })
        }}
      >
        {selected && (
          <div className={styles.menu}>
            <Row distance={2} edges>
              <Tooltip message="Fit original size">
                <Icon
                  className={styles.menuItem}
                  name="ri-fullscreen-line"
                  onClick={() => {
                    if (ref.current) {
                      onScale(ref.current)
                    }
                  }}
                />
              </Tooltip>

              {/* <Tooltip message="Scale">
                <Icon className={styles.menuItem} name="ri-zoom-in-line" />
              </Tooltip> */}

              {/* <Tooltip message="Pin">
                <Icon className={styles.menuItem} name="ri-pushpin-line" />
              </Tooltip> */}
            </Row>
          </div>
        )}

        {children}
      </Resizable>
    </div>
  )
}
