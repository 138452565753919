import { ReactNode, HTMLAttributes, forwardRef } from "react"
import cn from "classnames"

import styles from "./Row.module.scss"

export interface RowProps extends HTMLAttributes<HTMLElement> {
  phoneScreen?: "stack" | "stack-n-stretch"
  fill?: boolean
  inline?: boolean
  stackOnPhones?: boolean
  distance?: number
  horizontal?: "left" | "right" | "middle"
  vertical?: "top" | "center" | "bottom" | "baseline"
  edges?: boolean
  children: ReactNode
}
/**
 * Aligns items horizontally and put them in the row.
 *
 * If using for form elements, do not forget to set **vertical="top"**
 * (as far as form elements can error messages - and change hights)
 */
export const Row = forwardRef<HTMLDivElement, RowProps>(
  (
    {
      fill,
      inline,
      className,
      stackOnPhones,
      distance = 0,
      horizontal = "left",
      vertical = "center",
      edges = false,
      ...props
    },
    ref
  ) => (
    <div
      ref={ref}
      className={cn(
        styles.row,
        horizontal && styles[horizontal],
        vertical && styles[vertical],
        fill && styles.fill,
        inline && styles.inline,
        stackOnPhones && styles.stackOnPhones,
        className,
        `distance-horizontal-${distance}`,
        edges && "hasEdges"
      )}
      {...props}
    />
  )
)
