import { CommandProps, Editor } from "@tiptap/react"
import { Node, Schema } from "prosemirror-model"

export const isParagraphEmpty = <T extends Schema<any, any>>(node: Node<T>) => {
  return node && node.type.name === "paragraph" && node.content.size === 0
}

export const replaceIfInEmptyParagraph = (
  editor: Editor | CommandProps,
  type: string,
  attrs: any
) => {
  const selection = editor.state.selection

  if (selection.empty) {
    const parent = selection.$anchor.parent
    // @ts-ignore
    if (isParagraphEmpty(parent)) {
      // @ts-ignore
      return editor.commands.replaceRange(selection, type, attrs)
    } else {
      return editor.commands.insertContentAt(selection, {
        type,
        attrs
      })
    }
  }
}
