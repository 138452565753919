import { createContext, ReactNode, useContext } from "react"
import { useRouteMatch } from "react-router"

import { useLocationQuery } from "hooks/useLocationQuery"
import { useProjectQuery, ProjectFragment } from "graphql/types"
import { useCSSVariable } from "hooks/useCSSVariables"
import { useIDs } from "routes/Editor/Editor/EditorStore"

interface ProjectDataProviderProps {
  children: ReactNode
}

interface ProjectRouteParams {
  id: string
  projectId: string
}

interface ProjectContext {
  project: ProjectFragment
  role: string
  owner: boolean
  projectId: string
  clientId: string
  self: any | null
  authenticated: boolean
  preview: boolean
  permissions: Record<string, boolean>
}

const context = createContext<Partial<ProjectContext>>({})

const Provider = context.Provider

export const useProject = () => {
  return useContext(context) as ProjectContext
}

export const ProjectDataProvider = ({ children }: ProjectDataProviderProps) => {
  const location = useLocationQuery()

  const { projectId } = useIDs()

  const clientId = location.query.clientId
  const preview = location.query.preview === "true"

  const { data, error } = useProjectQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      id: projectId
    },
    onCompleted() {
      console.log(`Project ${projectId}: ✅`)
    }
  })

  const self = data?.self ?? null
  const project = data?.project ?? null
  /**
   * Uses theme of the project
   * */
  useCSSVariable("theme-color", project?.themeColor || "")

  if (error) {
    return (
      <div>
        Error while connecting to the server. Please try refreshing the page.
      </div>
    )
  }

  if (!project) {
    return null
  }

  const { role, createdBy } = project

  const authenticated = Boolean(self)
  const owner = Boolean(createdBy._id === self?._id)

  const permissions = {
    enableTeamPage: true
  }

  return (
    <Provider
      value={{
        project,
        role,
        owner,
        projectId,
        clientId,
        self,
        authenticated,
        preview,
        permissions
      }}
    >
      {children}
    </Provider>
  )
}
