import { HashIcon } from "components/HashIcon"
import { Row, Split, Stack } from "components/layout"
import { KebabMenu } from "components/Menu"

import styles from "./TeamListItem.module.scss"

export interface TeamListItemProps {
  role?: string
  user: {
    name: string
    photo?: string | null
    email: string
  }
  showControls: boolean
  onRemove: () => void
}

export const TeamListItem = ({
  role,
  user,
  showControls,
  onRemove
}: TeamListItemProps) => (
  <Row distance={8}>
    <div>
      <HashIcon value={user.name} url={user.photo ?? ""} />
    </div>

    <Split>
      <Stack distance={2}>
        <div>
          <span className={styles.title}>{user.name}</span>
        </div>
        <div className={styles.description}>{user.email}</div>
      </Stack>

      <div>
        <Row distance={10}>
          {role && <div className={styles.role}>{role}</div>}

          {showControls && (
            <KebabMenu
              options={[
                {
                  label: "Remove",
                  value: "remove"
                }
              ]}
              onSelect={(option) => {
                if (option === "change-role") {
                  return
                }

                if (option === "remove") {
                  onRemove()
                  return
                }
              }}
            />
          )}
        </Row>
      </div>
    </Split>
  </Row>
)
