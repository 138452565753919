import { ApolloLink } from "@apollo/client"
import { AUTHORIZATION } from "api"
import qs from "qs"
/**
 * Sets headers on every request
 */
export const auth = new ApolloLink((operation, forward) => {
  operation.setContext((request: any) => {
    const token = localStorage.getItem(AUTHORIZATION) || ""

    const { clientId = "" } = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    })

    const headers: Record<string, string> = {
      ...request.headers
    }

    if (token) {
      headers["authorization"] = `Bearer ${token}`
    }

    if (clientId) {
      headers["x-access-token"] = clientId.toString()
    }

    return {
      headers
    }
  })

  return forward(operation).map((data) => {
    return data
  })
})
