import { ReactNode } from "react"
import { LogoLink } from "./Logo"
import styles from "./PublicPageLayout.module.scss"

export interface PublicPageLayoutProps {
  children: ReactNode
  maxWidth?: number
}

export const PublicPageLayout = ({
  children,
  maxWidth
}: PublicPageLayoutProps) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <LogoLink />
    </div>
    <div className={styles.body} style={{ maxWidth }}>
      <div className={styles.content}>{children}</div>
    </div>
  </div>
)
