const config = {
  graphqlUrl: "https://dev.gateway.shareback.xyz",
  graphqlSubscriptionUrl: "ws://dev.gateway.shareback.xyz/subscriptions",
  apiUrl: "https://dev.api.shareback.xyz",

  firebase: {
    apiKey: "AIzaSyC5y4W69C_BGnZ1DJltvQ_CfPK6394ELzg",
    authDomain: "development-321214.firebaseapp.com"
  }
}

export default config
