import { useHistory, useLocation, useRouteMatch } from "react-router"
import Skeleton from "react-loading-skeleton"

import { Tabs } from "components/Tabs"
import { Row, Split, Stack } from "components/layout"
import { useProject } from "providers/Project"

import { Logo } from "./Logo"
import { HeroFrame } from "./HeroFrame"

import styles from "./Hero.module.scss"

export const Hero = () => {
  const location = useLocation()
  const history = useHistory()
  const route = useRouteMatch<{ page: string }>()

  const { projectId, project } = useProject()

  const tabs: any[] = [
    {
      label: "Sharebacks",
      value: "updates"
    },
    {
      label: "Assets",
      value: "assets"
    },
    {
      label: "Metrics",
      value: "metrics"
    },
    {
      label: "Team",
      value: "team"
    }
  ].filter(Boolean)

  const push = (path: string) => {
    history.push(`/projects/${projectId}/${path}${location.search}`)
  }

  return (
    <>
      <HeroFrame
        toolbar={
          <Row distance={6} stackOnPhones>
            <div />
          </Row>
        }
      >
        <Split>
          <Stack distance={6}>
            <Stack distance={1}>
              <div>
                <div className={styles.title} onClick={() => push("updates")}>
                  {project?.name ?? <Skeleton />}
                </div>
              </div>

              <div className={styles.description}>
                {project?.description ?? <Skeleton />}
              </div>
            </Stack>
          </Stack>

          <div className={styles.logoContainer}>
            <Logo url={project?.logo ?? ""} name={project?.name ?? ""} />
          </div>
        </Split>
      </HeroFrame>

      <div className={styles.menu}>
        <Tabs
          options={tabs}
          value={route.params.page}
          onChange={(value) => push(value)}
        />
      </div>
    </>
  )
}
