import { uploadFile } from "api"
import { Dialog, ModalProps, useModal } from "components/Modal"
import Spinner from "components/Spinner"
import {
  TeammateInput,
  useCreateTeammateMutation,
  useGetTeammateQuery,
  useUpdateTeammateMutation
} from "graphql/types"
import { useProject } from "providers/Project"
import { useMemo } from "react"

import {
  EditTeammateForm,
  EditTeammateSubmitValues,
  EditTeammateValues
} from "./EditTeammateForm"

const defaultInitialValues: EditTeammateValues = {
  firstName: "",
  lastName: "",
  description: "",
  position: "",
  photoUrl: ""
}

export const EditTeammate = (props: ModalProps) => {
  const { projectId } = useProject()

  const [createTeammate] = useCreateTeammateMutation()
  const [updateTeammate] = useUpdateTeammateMutation()

  const modal = useModal()

  const teammateId: string = props.attributes?._id ?? ""

  const { data, loading } = useGetTeammateQuery({
    variables: {
      projectId,
      teammateId
    },
    skip: !teammateId
  })

  const teammate = data?.teammate || null

  const initialValues: EditTeammateValues = useMemo(() => {
    if (!teammate) {
      return defaultInitialValues
    }

    const { firstName, lastName, description, position, photoUrl } = teammate

    return {
      firstName,
      lastName,
      description: description || "",
      position: position || "",
      photoUrl: photoUrl || ""
    }
  }, [teammate])

  const onSubmit = async (values: EditTeammateSubmitValues) => {
    const { image, ...rest } = values

    let input: TeammateInput = { ...rest }

    if (image) {
      const { asset } = await uploadFile(image)

      if (asset) {
        input.photoUrl = asset.url
      }
    }

    const variables = {
      projectId,
      input
    }

    if (Object.keys(variables.input).length !== 0) {
      const promise = teammateId
        ? updateTeammate({
            variables: {
              ...variables,
              teammateId
            }
          })
        : createTeammate({ variables })

      await promise
    }

    modal.hide()
  }

  return (
    <Dialog {...props}>
      {loading ? (
        <Spinner />
      ) : (
        <EditTeammateForm
          initialValues={initialValues}
          onCancel={() => {
            modal.hide()
          }}
          onSubmit={onSubmit}
        />
      )}
    </Dialog>
  )
}
