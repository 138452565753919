import { Form, Formik } from "formik"
import { useState } from "react"
import { Link } from "react-router-dom"
import { useMutation } from "@apollo/client"

import Button from "components/Button"
import { FormInput } from "components/Input"
import { Stack } from "components/layout"
import { AUTHORIZATION } from "api"

import ErrorBoundary from "components/ErrorBoundary"

import { AUTHENTICATE_GOOGLE } from "./operations"
import { authenticateFormSchema } from "./schema"
import toast from "react-hot-toast"

import styles from "./Authenticate.module.scss"
import {
  authenticateWithEmailAndPassword,
  authenticateWithGoogle
} from "features/auth"

interface AuthenticateFormValues {
  email: string
  password: string
}

export const AuthenticateForm = ({ onAuthenticate }: any) => {
  const [error, setError] = useState("")

  const [authenticateGoogle, authenticateGoogleMutation] =
    useMutation(AUTHENTICATE_GOOGLE)

  const submit = async (getToken: () => Promise<string>) => {
    localStorage.removeItem(AUTHORIZATION)

    try {
      const token = await getToken()

      if (token) {
        localStorage.setItem(AUTHORIZATION, token)

        onAuthenticate()
      } else {
        toast("Authentication failed")
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(error?.message ?? "Internal server error")
      }
      toast("Authentication failed")
    }
  }

  return (
    <Formik<AuthenticateFormValues>
      validationSchema={authenticateFormSchema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        email: "",
        password: ""
      }}
      onSubmit={async (values) =>
        submit(async () => {
          const token = await authenticateWithEmailAndPassword(
            values.email,
            values.password
          )

          return token ?? ""
        })
      }
    >
      {(props) => (
        <Form>
          <Stack distance={8}>
            <h2>Sign in</h2>

            <div>
              Don't have an account? <Link to="/register">Create one</Link>
            </div>

            <FormInput
              label="Email"
              autoComplete="off"
              format={(value) => value.toLocaleLowerCase().trim()}
              name="email"
              type="email"
            />

            <FormInput
              label="Password"
              autoComplete="off"
              name="password"
              type="password"
            />

            <Stack distance={4}>
              <Button type="submit" size="large" loading={props.isSubmitting}>
                Log in
              </Button>

              <ErrorBoundary>
                <Button
                  loading={authenticateGoogleMutation.loading}
                  icon="ri-google-fill"
                  onClick={() => {
                    submit(async () => {
                      const token = await authenticateWithGoogle()

                      const { data } = await authenticateGoogle({
                        variables: {
                          token
                        }
                      })

                      return data?.authenticateGoogle
                    })
                  }}
                >
                  Log in with Google
                </Button>
              </ErrorBoundary>

              <Link className={styles.link} to="/reset">
                Forgot password?
              </Link>
            </Stack>

            {error && <div style={{ color: "red" }}>{error}</div>}
          </Stack>
        </Form>
      )}
    </Formik>
  )
}
