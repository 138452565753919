import { Component } from "react"
import cn from "classnames"
import { Stack } from "components/layout"

import styles from "./List.module.scss"

const DEFAULT_SELECTED_INDEX = -1

class List extends Component {
  state = {
    selectedIndex: DEFAULT_SELECTED_INDEX
  }

  componentDidUpdate(oldProps) {
    if (this.props.items !== oldProps.items) {
      this.setState({
        selectedIndex: DEFAULT_SELECTED_INDEX
      })
    }
  }

  onKeyDown({ event }) {
    if (event.key === "ArrowUp") {
      this.upHandler()
      return true
    }

    if (event.key === "ArrowDown" || event.key === "Tab") {
      this.downHandler()
      return true
    }

    if (event.key === "Enter") {
      this.enterHandler()
      return true
    }

    return false
  }

  upHandler() {
    this.setState({
      selectedIndex:
        (this.state.selectedIndex + this.props.items.length - 1) %
        this.props.items.length
    })
  }

  downHandler() {
    this.setState({
      selectedIndex: (this.state.selectedIndex + 1) % this.props.items.length
    })
  }

  enterHandler() {
    const { selectedIndex } = this.state

    this.selectItem(selectedIndex)
  }

  selectItem(index) {
    const item = this.props.items[index]

    if (item) {
      this.props.command(item)
    }
  }

  render() {
    const { items } = this.props
    const { selectedIndex } = this.state

    return (
      <Stack distance={1} className={styles.items}>
        {items.map((item, index) => (
          <button
            className={cn(
              styles.item,
              index === selectedIndex && styles.selected
            )}
            key={index}
            onClick={() => this.selectItem(index)}
          >
            {item.element || item.title}
          </button>
        ))}
      </Stack>
    )
  }
}

export default List
