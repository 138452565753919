import Card from "components/Card"
import { EmptyMessage } from "components/EmptyMessage/EmptyMessage"
import { Split } from "components/layout"
import { ModalManager, useModal } from "components/Modal"
import { useGetTeammatesQuery, useRemoveTeammateMutation } from "graphql/types"
import { useProject } from "providers/Project"
import { Button } from "routes/Project/components"

import { EditTeammate } from "./EditTeammate"
import { Item } from "./Item"

import styles from "./Team.module.scss"

export const Team = () => {
  const modal = useModal()
  const { projectId, owner, preview } = useProject()

  const { data, loading, refetch } = useGetTeammatesQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      projectId
    }
  })

  const items = data?.teammates ?? []
  const canEdit = owner && !preview

  const [removeTeammate] = useRemoveTeammateMutation()

  return (
    <Card>
      {canEdit && (
        <Split>
          <div />
          <div>
            <Button secondary onClick={() => modal.show("edit-teammate")}>
              Add teammate
            </Button>
          </div>
        </Split>
      )}

      {!loading && items.length === 0 && (
        <EmptyMessage message="Team members will appear here" />
      )}

      {!loading && items.length > 0 && (
        <div className={styles.items}>
          {items.map((item) => (
            <Item
              key={item._id}
              name={`${item.firstName} ${item.lastName}`}
              photoUrl={item.photoUrl ?? ""}
              position={item.position}
              description={item.description}
              canEdit={canEdit}
              onEdit={() => {
                modal.show("edit-teammate", item)
              }}
              onView={() => {
                modal.show("view-teammate", item)
              }}
              onRemove={async () => {
                const sure = window.confirm(
                  "Are you sure you want to remove this record?"
                )

                if (sure) {
                  await removeTeammate({
                    variables: {
                      projectId,
                      teammateId: item._id
                    },
                    update(cache, { data }) {
                      const entity = data?.removeTeammate

                      if (entity) {
                        const id = cache.identify(entity)

                        if (id) {
                          cache.evict({ id })
                          cache.gc()
                        }
                      }
                    }
                  })
                }
              }}
            />
          ))}
        </div>
      )}

      <ModalManager
        component={EditTeammate}
        name="edit-teammate"
        onAfterClose={() => {
          refetch()
        }}
      />
    </Card>
  )
}
