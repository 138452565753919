import { useState, useRef, useEffect } from "react"

const useDropdown = () => {
  const [open, setOpen] = useState(false)
  const ref = useRef<any>()

  useEffect(() => {
    if (open) {
      const onClick = (event: MouseEvent) => {
        if (!ref.current?.contains(event.target)) {
          setOpen(false)
        }

        window.removeEventListener("click", onClick)
      }

      window.addEventListener("click", onClick, { capture: true })
    }
  }, [open])

  return [open, setOpen, ref] as const
}

export default useDropdown
