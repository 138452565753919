import { Form, Formik } from "formik"
import { useState } from "react"
import { useMutation } from "@apollo/client"

import Button from "components/Button"
import { FormInput } from "components/Input"
import { Stack } from "components/layout"
import Card from "components/Card"
import { PublicPageLayout } from "components/PublicPageLayout"
import { Text } from "components/Text"

import { formSchema } from "./schema"
import { RESET_PASSWORD } from "./operationts"

interface ResetPasswordFormValues {
  email: string
}

const Reset = () => {
  const [error, setError] = useState("")
  const [sentEmail, setSentEmail] = useState("")

  const [resetPassword] = useMutation(RESET_PASSWORD)

  return (
    <PublicPageLayout maxWidth={400}>
      <Card>
        <Formik<ResetPasswordFormValues>
          initialValues={{
            email: ""
          }}
          validateOnChange={false}
          validationSchema={formSchema}
          onSubmit={async (values) => {
            try {
              await resetPassword({
                variables: {
                  email: values.email
                }
              })

              setSentEmail(values.email)
            } catch (error) {
              setError(`Oops! Something went wrong! ${error?.message ?? ""}`)
            }
          }}
        >
          {(props) => (
            <Form>
              <Stack distance={8}>
                <h2>Reset password</h2>

                {sentEmail ? (
                  <Text>
                    An email with password reset link has been sent to{" "}
                    {sentEmail}.
                  </Text>
                ) : (
                  <>
                    <Text>
                      Enter the email address associated with your account, and
                      we'll send you a link to reset your password.
                    </Text>

                    <FormInput
                      autoComplete="off"
                      label="Email"
                      format={(value) => value.toLocaleLowerCase().trim()}
                      name="email"
                      type="email"
                    />

                    <Button
                      type="submit"
                      size="large"
                      loading={props.isSubmitting}
                    >
                      Reset password
                    </Button>

                    {error && <div style={{ color: "red" }}>{error}</div>}
                  </>
                )}
              </Stack>
            </Form>
          )}
        </Formik>
      </Card>
    </PublicPageLayout>
  )
}

export default Reset
