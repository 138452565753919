import { ModalProps, useModal } from "components/Modal"
import Dialog from "components/Modal/Dialog"
import { formatISO } from "date-fns"
import { useUpdatePostMutation } from "graphql/types"
import { usePublish } from "routes/Editor/Editor/operations"

import { PublishModalForm } from "./PublishModalForm"

const getFirstParagraphText = (document: any) => {
  if (!document) {
    return ""
  }

  const content = document.content ?? []

  for (let i = 0; i < content.length; i++) {
    const node = content[i]

    if (node.type === "paragraph") {
      const text = node.content?.[0]?.text

      if (typeof text === "string") {
        const string = text.trim()

        if (string.length > 0) {
          return text
        }
      }
    }
  }

  return ""
}

const now = new Date()

export interface PublishModalProps extends ModalProps {
  attributes?: {
    post?: any
    notifications?: boolean
  }
}

export const PublishModal = ({ attributes, ...props }: PublishModalProps) => {
  const modal = useModal()

  const post = attributes?.post

  const [update] = useUpdatePostMutation()
  const [publish] = usePublish()
  /**
   * Enabled sending notifications to clients on publish.
   * Also, this is a default value for "notify" checkbox.
   */
  const notifications =
    typeof attributes?.notifications === "boolean"
      ? attributes.notifications
      : true

  return (
    <Dialog {...props}>
      <PublishModalForm
        notifications={notifications}
        initialValues={{
          title: post.title,
          date: post.date || formatISO(now),
          summary: post.summary || getFirstParagraphText(post?.content || null),
          notify: notifications
        }}
        onCancel={() => {
          modal.hide()
        }}
        onSubmit={async (values) => {
          await update({
            variables: {
              projectId: post.project,
              postId: post._id,
              input: {
                title: values.title,
                summary: values.summary,
                date: values.date
              }
            }
          })

          await publish(post._id)
          modal.hide()
        }}
      />
    </Dialog>
  )
}
